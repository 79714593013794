import React, { useState, useEffect, useRef } from 'react';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { Box, Button, Collapse, Dialog, DialogContent, DialogContentText, DialogTitle, DialogActions,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, IconButton, useTheme, useMediaQuery, Grid, 
  Paper, Divider } from "@mui/material";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import WidgetWrapper from '../../../components/WidgetWrapper';
import { useReactToPrint } from 'react-to-print';


const AssessmentCards = ({
  _id,
  assessmentID,
  threatName,
  threatDescription,
  sections,
  refresh,
  setRefresh

}) => {
  const theme = useTheme();
  const [isExpanded, setIsExpanded] = useState(false);
  const navigate = useNavigate();
  const [assessmentToDelete, setAssessmentToDelete] = useState();
  const [damNameToDelete, setDamNameToDelete] = useState();
  const [open, setOpen] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const [newThreatName, setNewThreatName] = useState(threatName);
  const [newThreatDescription, setNewThreatDescription] = useState(threatDescription);
 
  const newSections = [];

  const createSections = () => {
    if (sections?.length) {
      JSON.parse(sections).forEach(section => {newSections.push(section); });
    }
  };

    createSections();
  
  const handleDeleteAssessment = async () => {
    let id = _id;
  try {
      const response = await axiosPrivate.delete(`/threatCondition/${id}`, 
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
      if (response.data.success) {
          alert(response.data.msg);
      } 
  } catch (err) {
      console.error(err);
  }
  setRefresh(!refresh);
  setAssessmentToDelete(null);
  setDamNameToDelete(null);
  };
  
  const handleClickOpen = (_id, threatName) => {
      setAssessmentToDelete(_id);
      setDamNameToDelete(threatName);
      setOpen(true);
    };
  
  const handleNo = () => {
      setOpen(false);
  };

  const handleYes = () => {
      handleDeleteAssessment();
      setOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let threatName = newThreatName;
    let threatDescription = newThreatDescription;
    let sections = JSON.stringify(newSections);
    let id = _id;
    let allAssessmentThreatConditionData = JSON.stringify({threatName, threatDescription, sections})
    try {
      const response = await axiosPrivate.patch(`/threatcondition/threatcondition/${id}`,
       JSON.stringify({ allAssessmentThreatConditionData }),
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
  
    } catch (err) {
        if (!err?.response) {
            console.log('No Server Response');
        } else {
            console.log('Action Failed')
        }
    }
    setRefresh(!refresh)
};

const handleAddSection = (e) =>{
  newSections.push(
    {
      name: '',
      procedures: ['']
    }
  )
  handleSubmit(e);
};

const handleRemoveSection = (e, index) =>{
  newSections.splice(index, 1);
  handleSubmit(e);
};

const handleAddMeasure = (e, index) => {
  // console.log(index, locale);
  // console.log(newSections[index].procedures);
  newSections[index].procedures.push("newMeasure");
  handleSubmit(e);
};

const handleRemoveMeasure = (e, index, locale) => {
  newSections[index].procedures.splice(locale, 1);
  handleSubmit(e);
};

  return (
    <Box>
        <Dialog
          open={open}
          onClose={handleNo}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
          {"Verify Delete Command"}
          </DialogTitle>
          <DialogContent>
          <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete the {damNameToDelete} assessment?
          </DialogContentText>
          </DialogContent>
          <DialogActions>
          <Button onClick={handleYes}>Yes</Button>
          <Button onClick={handleNo} autoFocus>
              No
          </Button>
          </DialogActions>
        </Dialog>
        <Grid container spacing={2}>
          <Grid item xs={12}>
          <WidgetWrapper>
          {isExpanded ? (
            <IconButton color="primary"  aria-label="open or close detection and response" onClick={() => setIsExpanded(!isExpanded)} >
              <ExpandCircleDownIcon sx={{ transform: 'rotate(270deg)'}} color='primary' fontSize="large" />
              <Typography variant='h2' sx={{ ml: '2rem' }}>{newThreatName}</Typography>
            </IconButton>
            ) : (
            <IconButton color="primary"  aria-label="open or close detection and response" onClick={() => setIsExpanded(!isExpanded)} >
              <ExpandCircleDownIcon color='primary' fontSize="large" />
              <Typography variant='h2' sx={{ ml: '2rem' }}>{newThreatName}</Typography>
            </IconButton>
            )}
            <Collapse in={isExpanded} timeout="auto" unmountOnExit sx={{ color: theme.palette.secondary[300] }}>
            <Box sx={{ display:'flex', justifyContent:'end' }}>
              <Button sx={{ marginRight: '1rem' }} variant='contained' onClick={() => {handleClickOpen(assessmentID, threatName)}}>Delete</Button>
            </Box>
            <Typography variant='h3' sx={{textAlign: 'center' }}>Introduction Section</Typography>
            <Typography>Threat Level Category</Typography>
              <textarea
                value={newThreatName || []}
                style={{ width:'100%', maxWidth:'100%' }}
                onChange={(e) => setNewThreatName(e.target.value)}
                onBlur={(e)=> handleSubmit(e)}
                >
              </textarea>
              {/* <Button 
                      variant="primary"
                      size="small"
                      onClick={() => setIsExpanded(!isExpanded)}
                  >
                      See More
                  </Button> */}
                  
                  <Divider />
                  <Typography>Description</Typography>
                  <textarea 
                    value = {newThreatDescription || []}
                    style={{ height: '150px', width:'100%', maxWidth:'100%' }} 
                    onChange={(e) => setNewThreatDescription(e.target.value)}
                    onBlur={(e)=> handleSubmit(e)}
                    >
                    
                  </textarea>
                  <Divider />

                {/** Modify to use newSections list. */}
                {newSections.map((section, index)=> (
                  <Box>
                    <Box sx={{ display:'flex', justifyContent:'space-between', mb:'1rem' }}>
                      <Button variant='contained' onClick={(e)=>handleAddSection(e)}> Add NEW SECTION </Button>
                      <Typography variant='h3' sx={{textAlign: 'center' }}>{section.name} Section</Typography>
                      <Button variant='contained' onClick={(e)=> handleRemoveSection(e, index)}>Remove This Section</Button>
                    </Box>
                    
                    <Typography>Title</Typography>
                   <textarea 
                    style={{ width:'100%', maxWidth:'100%' }}
                    onChange={(e) => newSections[index].name=(e.target.value)}
                    onBlur={(e)=> handleSubmit(e)}
                    >
                      {section.name}
                    </textarea>
                    {/* <Typography>{section.name}</Typography> */}
                    <Typography>Measures</Typography>
                    {section.procedures.map((procedure, locale)=>(
                      <Box sx={{ p:'10px', borderRadius:'10px', border:'solid 1px' }}>
                        <Button onClick={(e)=> handleRemoveMeasure(e, index, locale)}>Remove</Button>
                        <textarea 
                          style={{ height: '150px', width:'100%', ml:'1rem' }} 
                          onChange={(e) => newSections[index].procedures[locale]=(e.target.value)}
                          onBlur={(e)=> handleSubmit(e)}
                        >{procedure}</textarea>
                      </Box>
                    ))}

                    <IconButton sx={{ mb:'2rem' }} color="primary"  aria-label="add a critical asset" onClick={(e)=>handleAddMeasure(e, index)} >
                      <AddCircleIcon fontSize="large" /> Add Measure
                    </IconButton>

                    <Divider sx={{ mb:'1rem' }} />
                  </Box>
                ))}
                
            </Collapse>
        </WidgetWrapper>

          </Grid>
        </Grid>
       
    </Box>
)
};


const ThreatLevel = () => {

  const [siteThreatConditionsStatus, setSiteThreatConditionsStatus] = useState(false);
  const theme = useTheme();
  const [openInstructions, setOpenInstructions] = useState(true);
  const [allAssessmentThreatConditions, setAllAssessmentThreatConditions] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [assessmentData, setAssessmentData] = useState(null);
  const axiosPrivate = useAxiosPrivate();
  const { assessmentID } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const componentRef = useRef();
  const [conditionToDelete, setConditionToDelete] = useState();
  const marginTop = '2em';
  const marginBottom = '4em';
  const marginLeft = '1em';
  const marginRight = '1em';

  const threatConditions = [
    {
      threatName: 'Normal',
      threatDescription: 'There is no credible, specific, or impending terrorist threat against the United States. The following protective measures can be considered:',
      sections: [
        {
            name: 'Employees',
            procedures: [
              'Perform background checks (level of detail determined by the licensee) on any employees who could affect hydropower operations.',
              'Keep personnel informed of alert levels and, at regular intervals, remind all personnel to report suspicious activities, see attachment, to the appropriate law enforcement or security personnel.',
          ]
        },
        {
            name: 'Planning and Coordination',
            procedures: [
              'Regularly review and modify security plans and recovery plans (if present), and EAPs. Keep emergency contact lists up to date, coordinate with local law enforcement and security agencies and ensure that they are familiar with facility locations and operations.',
              'Review all operations plans and orders, EAPs, Recovery Plans, and Standard Operating Procedures (SOPs).',
              'Increase liaison with local police, intelligence and security agencies to monitor the threat to Project personnel and facilities. Notify local law enforcement agencies concerning measures that, if implemented, could impact on their operations in the local community.',
              'Consider the use of emergency exercises and drills to enhance overall preparedness.',
          ]
        },
        {
              name: 'Site Security',
              procedures: [
              'Maintain appropriate level of site security. Secure buildings, rooms, and storage areas not in regular use. Maintain a list of secured facilities and areas at the facility or activity level. ',
              'Provide routine surveillance of visitors, tour groups, and other public users of Project facilities and lands. ',
              'Regularly inspect all buildings, rooms, and storage areas not in regular use.'
          ]
        }
      ]
    },
    {
      threatName: 'Elevated',
      threatDescription: '“Elevated” warns of a credible terrorist threat against the United States. It is “Elevated” if the United States has no specific information about the timing or location. In addition to the previous measures, the following protective measures can be considered:',
      sections: [
        {
            name: 'Employees',
            procedures: [
              'Provide employees with as much information as possible on threat conditions, update information frequently. Permit variations in work schedules. Stress importance of vigilance. ',
              'Verify the identity of all employees and authorized personnel entering Project facilities. Inspect identification cards, security badges or other forms of personal identification. Consider implementing a detailed inspection for all entering vehicles (trunk, undercarriage, glove boxes, etc.), suitcases, briefcases, and other containers. '
          ]
        },
        {
            name: 'Planning and Coordination',
            procedures: [
              'Maintain continuous liaison with local police, intelligence and security agencies to monitor the threat to Project personnel and facilities. ',
              'Consult with local or State authorities about closing public roads and facilities that may make Project facilities more vulnerable to attacks. Keep public informed of restricted access and road closings. Notify local police if access/road closings could impact their operations in the local community.',
          ]
        },
        {
            name: 'Site Security',
            procedures: [
              'Implement 24/7 surveillance of all "critical" facilities (Security Group 1). This measure includes unarmed and/or armed guard forces, and/or law enforcement personnel. Position guard force personnel and/or security patrols at all critical areas. This measure may be augmented by law enforcement agencies, particularly in otherwise unprotected areas.',
              'Erect barriers required to control direction of traffic flow and to protect facilities vulnerable to bomb attack by parked or moving vehicles.',
              'Reduce facility access points to an absolute minimum necessary for continued operation. Close all visitor centers and restrict public access to all Project facilities.',
              'Remove all motor vehicles and heavy equipment parked within 75 feet of critical areas and other sensitive activities specified in local plans. Implement centralized parking and shuttle bus service, where required.',
              'Where practicable, remove signs that identify the facility.',
              'Conduct unannounced security spot checks (inspection of personal identification; vehicle registration; and contents of vehicles, suitcases, briefcases and other containers) at access points for Project facilities. At beginning and end of each workday and at frequent intervals, inspect interior and exterior of buildings in regular use for suspicious packages, or signs of tampering, or indications of unauthorized entry.',
              'Cancel non-essential deliveries.',
              'Cancel non-essential maintenance/construction that utilizes non-company workers.'
          ]
        }
      ]
    },
    {
      threatName: 'Imminent',
      threatDescription: '“Imminent” warns of a credible, specific, and impending terrorist threat against the United States. It is “Imminent”, if the United States believes the threat is impending or very soon. In addition to the previous measures, the following protective measures can be considered: ',
      sections: [
        {
              name: 'Employees',
              procedures: [
                'Verify identity of all employees entering facility, conduct detailed inspections of their vehicles, briefcases, boxes and any other type of containers. Consider options of alternate work sites for essential employees where feasible. ',
                'Keep personnel on duty fully informed of threat conditions, implement means to provide necessary information to employees not on duty. ',
          ]
        },
        {
              name: 'Planning and Coordination',
              procedures: [
                'Continue all essential coordination efforts from previous alert levels. Inform public that all facilities are closed. Request that local authorities close those public roads and facilities in the vicinity of Project facilities that may facilitate execution of an attack. ',
                'Contact armed forces for potential coordination efforts in event of attack.',
          ]
        },
        {
              name: 'Site Security',
              procedures: [
                'Augment law enforcement and guard forces to provide 24/7 surveillance and ensure absolute control over access to the facility. Implement frequent inspections of the exterior of buildings (to include roof areas) and parking areas. ',
                'Restrict public access to all facilities. Erect barriers, as necessary.',
                'Inventory and verify the identity of vehicles parked at a facility and move those that are not authorized.',
                'Thoroughly inspect all items (baggage, suitcases, packages, and briefcases) brought to the site for the presence of explosive or incendiary devices, or other dangerous items. Conduct unannounced security spot checks. '
          ]
        }
      ]
    }
  ];


  // **GET current assessment data. 
  const getAssessment = async () => {
    try {
        const response = await axiosPrivate.get(`/assessment/${assessmentID}`, {
            withCredentials: true
        });
        const data = await response.data;
        console.log(data);
        setAssessmentData(data);
    }
    catch (err) {
        // alert("Login Expired.");
        console.error(err);
        // navigate('/login', { state: { from: location }, replace: true });
    }
  };

    const getThreatConditionData = async () => {
      try {
        const response = await axiosPrivate.get(`/threatcondition/${assessmentID}`, {
          withCredentials: true
        });
        const data = await response.data;
        setAllAssessmentThreatConditions(data);
        // console.log(data);
      } catch (err) {
        // alert("Login Expired. access control");
        console.error(err);
        // navigate('/login', { state: { from: location }, replace: true });
      }
    }

  useEffect(() => {
    getThreatConditionData();
    getAssessment();
  }, [refresh]);  // eslint-disable-line react-hooks/exhaustive-deps
  
  const handleOpenInstructions = () => {
    setOpenInstructions(!openInstructions);
  };

  const handleUpper =(text) => {
    if (text) {
      const result = text.toUpperCase();
    return (
      result
    )
  }
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'emp-data',
    // onAfterPrint: () => alert('Print Success!')
  });

  const createDoc = () => {
    let tempList = [];
    tempList.push(<Typography variant='h2' sx={{ mt: '2rem', textAlign:'center' }}><u><b>Threat Conditions and Planning</b></u></Typography>)
      tempList.push(
        <TableContainer sx={{ mt:'1rem' }}>
          <Table aria-label='table for rapid recovery procedures'>
            <TableBody>
              <TableRow >
                <TableCell sx={{ border:'solid 1px'}}><b>Dam:</b></TableCell>
                <TableCell colSpan={3} sx={{ border:'solid 1px'}}>{assessmentData.damName}</TableCell>
              </TableRow>
              <TableRow >
                <TableCell sx={{ border:'solid 1px'}}><b>Assessment Type:</b></TableCell>
                <TableCell sx={{ border:'solid 1px'}} >{assessmentData.assessmentType}</TableCell>
                <TableCell sx={{ border:'solid 1px'}}><b>Prepared for Fiscal Year:</b></TableCell>
                <TableCell sx={{ border:'solid 1px'}}>{assessmentData.assessmentYear}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )
    for (let threat in allAssessmentThreatConditions) {
      tempList.push( <Typography variant='h2' sx={{ mt: '2rem' }} ><u><b>{allAssessmentThreatConditions[threat].threatName} Condition</b></u></Typography> );
      tempList.push(<style>{getPageMargins()}</style>);
      tempList.push( <Typography sx={{ m:'2rem' }}>{allAssessmentThreatConditions[threat].threatDescription}</Typography>);
      let section = JSON.parse(allAssessmentThreatConditions[threat].sections);
      for (let index in section) {

        tempList.push(<Typography variant='h3'sx={{ m:'1rem' }} >{section[index].name}</Typography>);
        for (let listItem in section[index].procedures) {
          tempList.push(<li>{section[index].procedures[listItem]}</li>);
        }
      }
  }
  
    return (
      tempList
    )
  };
  
  const getPageMargins = () => {
    return `@page {margin: ${marginTop} ${marginRight} ${marginBottom} ${marginLeft} !important; }`;
  }

  const newThreatCondition = [
    {
      threatName: 'New Threat Condition Name',
      threatDescription: 'New Threat Condition Description',
      sections: [
        {
              name: '',
              procedures: [
              '',
              '',
          ]
        }
      ]
    },
  ]

      {/** ADD NEW INTERNAL RESPOSE SECTION */}
const handleAddInternalResponse = async () => {
  
  let threatName = newThreatCondition[0].threatName;
  let threatDescription = newThreatCondition[0].threatDescription;
  let sections = JSON.stringify(newThreatCondition[0].sections);
  let allAssessmentThreatConditionData = JSON.stringify({threatName, threatDescription, sections})
  const response = await axiosPrivate.post('/threatcondition/new',
    JSON.stringify({ assessmentID, allAssessmentThreatConditionData }),
      {
        headers: { 'Content-Type': 'application/json' },
          withCredentials: true
      }
    );
    setRefresh(!refresh);
  };



  return (
    <Box>

{assessmentData===null ? (
        // LOADING
        <Dialog
          open={assessmentData===null}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          >
          <DialogTitle id="alert-dialog-title"> </DialogTitle>
          <DialogContent>
          <DialogContentText id="alert-dialog-description">
              <Typography variant='h2'>Loading...</Typography>
          </DialogContentText>
          </DialogContent>
        </Dialog>
      ):(
        // Check for hasThreatLevelContingencyPlanning
        (assessmentData?.hasThreatLevelContingencyPlanning ? (
          <>
            <WidgetWrapper sx={{ display:'flex', justifyContent:'center', alignItems:'center', mt:'1rem' }}>
              <Typography>Add your existing Threat Level Contingency Plan to this site's Security Plan.</Typography>
            </WidgetWrapper>
          </>
        ):(
          // Start Threat Level Planning Tool.
        <>
          <Grid container spacing={2}>
           <Grid item xs={12} >
             <Paper sx={{ backgroundImage: "none", backgroundColor: theme.palette.background.alt, borderRadius: "0.55rem", mr:'0.55rem', p:'4px'}}>
               <Grid item xs={12} sx={{ p:'0.5rem' }}>
               {openInstructions ? (
                   <IconButton color="primary"  aria-label="open or close instructions" onClick={handleOpenInstructions}>
                     <ExpandCircleDownIcon sx={{ transform: 'rotate(180deg)'}} color='primary' fontSize="large" />
                     <Typography variant='h3' sx={{ ml: '1.5rem' }}><u>Instructions</u>:</Typography>
                   </IconButton>
                     ) : (
                     <IconButton color="primary"  aria-label="open or close detection and response" onClick={handleOpenInstructions}>
                       <ExpandCircleDownIcon color='primary' fontSize="large" />
                       <Typography variant='h3' sx={{ ml: '1.5rem' }}>Instructions</Typography>
                     </IconButton>
                     )}
                   <Divider sx={{ border:0.5, mt:'6px' }} />
               </Grid>
               <Collapse in={openInstructions} timeout="auto" unmountOnExit>
                 <Grid container spacing={1}>
                   <Grid item xs={12} sx={{ ml:'2rem' }}>
                    <Typography >Here you will define your Threat Conditions for this site. Threat Conditions are predefined states of operation that increase as the risk of the threat increases. Each threat Condition has a set of cooresponding "Protective Measures or Practices". </Typography>
                    <Typography>1. Modify the examples provided on the left-hand side of the window. These examples were taken from FERC's guidance in the Revision 3A document.</Typography>
                    <Typography>2. You can define additoinal threat conditions using the "Add Threat Condition" button to define.</Typography>
                    <Typography>3. You can remove </Typography>
                    <Typography>4. Finally, review the results displayed on the right side of the window and use the "Print Threat Conditions" button </Typography>
                    <Typography>This information is a required part of the Security Plan.</Typography>
                    <Typography><u>Note:</u>If you have your own Threat Conditions defined, then add them to the Site Security Plan and skip this tool. </Typography>
                   </Grid>
                 </Grid>
               </Collapse>
             </Paper>
           </Grid>
           <Grid item xs={12}>
           <Box sx={{ display:'flex', justifyContent:'space-between'}}>
              <Button sx={{ m: '0.5rem', fontSize: '30', p: '1rem', width: '10%' }} variant='contained' onClick={handleAddInternalResponse}>ADD New Section</Button>
              <Button sx={{ m: '0.5rem', fontSize: '30', p: '1rem', width: '10%' }} variant='contained' onClick={handlePrint}>Print Threat Conditions</Button>
             </Box>
          
           </Grid>

           {/** DOCUMENT EDITOR STARTS HERE. */}
           <Grid item xs={6}>
           <Box m="1.5rem 2.5rem">
            <Box mt="20px" display="grid" gridTemplateColumns="repeat(1, minmax(0, 1fr))" 
                justifyContent="space-between" 
                rowGap="20px" 
                columnGap="1.33%" 
                // sx={{ "& > div": { gridColumn: isNonMobile ? undefined : "span 4" } }}
            >
                {allAssessmentThreatConditions?.map(
                  ({  
                    _id,
                    assessmentID,
                    threatName,
                    threatDescription,
                    sections,
                }) => (
                    <AssessmentCards
                        key={_id}
                        _id={_id}
                        assessmentID={assessmentID}
                        threatName={threatName}
                        threatDescription={threatDescription}
                        sections={sections}
                        refresh={refresh}
                        setRefresh={setRefresh}
                    />
                    )
                )}
            </Box>
        </Box>
           </Grid>
            

            {/** PRINT FEATURE STARTS HERE. */}
             <Grid item xs={6}>
               <Paper sx={{ pt:'2em', pr:'5em', pl:'5em' }} ref={componentRef}>
              
                 {createDoc()}
               </Paper>
             </Grid>

           </Grid>
      </>

      ))
    )}

    </Box>
  )
}

export default ThreatLevel