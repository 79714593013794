import React, { useRef, useState, useEffect } from 'react';
import { Box, Button, Typography, useTheme, useMediaQuery, Grid, Paper, Divider, Popper, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
    FormControl, InputLabel, Select, MenuItem, TextField, InputAdornment
 } from "@mui/material";
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import Header from "../../components/Header";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import FlexBetween from "../../components/FlexBetween";
import {
    MapContainer,
    TileLayer,
    Popup,
    Polygon,
    GeoJSON,
    Marker,
    useMapEvent,
    useMap,
    LayerGroup,
    LayersControl,
    Circle,
    Rectangle,
    FeatureGroup
  } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { Icon, useCallback } from "leaflet";
import { statesData, } from './data';
import useAuth from "../../hooks/useAuth";
import { jwtDecode } from "jwt-decode";
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from "react-router-dom";
import Info from '@mui/icons-material/Info';
import { usaStatesWithCounties } from 'typed-usa-states';
import { Search } from "@mui/icons-material";


const SelectDam = ({damData, setDamData, setProcessValue, assessmentID, setSecurityGroup}) => {

    const [allDamData, setAllDamData] = useState(null);
    const { auth } = useAuth();
    const decoded = auth?.accessToken
    ? jwtDecode(auth.accessToken)
    : undefined;
    const userInfo = decoded?.UserInfo;
    const userId = userInfo.userId;
    const theme = useTheme();
    const navigate = useNavigate();
    const valueComplete = '2';

    const axiosPrivate = useAxiosPrivate();
    const [isExpanded, setIsExpanded] = useState(false);
    const [cardContent, setCardContent] = useState();
    const [center, setCenter] = useState([]);

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(50);
    const [sort, setSort] = useState({});
    const [search, setSearch] = useState("");
    const [searchInput, setSearchInput] = useState("");
    const [isChanged, setIsChanged] = useState(false);
    const [total, setTotal] = useState();

    const [damID, setDamID] = useState();
    const [nidID, setNidID] = useState();
    const [damName, setDamName] = useState();
    const [estimatedSecurityGroup, setEstimatedSecurityGroup] = useState();

    const [zoomLevel, setZoomLevel] = useState(4);
    const [iconSize, setIconSize] = useState(30);

    const [questionIndex, setQuestionIndex] = useState(0);
    const months = ["January","February","March","April","May","June","July","August","September","October","November","December"];
    const date = new Date();
    const currentYear = date.getFullYear();
    const nextYear = currentYear + 1;
    const [assessmentYear, setAssessmentYear] = useState();
    const [assessmentType, setAssessmentType] = useState();
    const [securityGroupVerified, setSecurityGroupVerified] = useState('');
    const [securityGroupChosen, setSecurityGroupChosen] = useState('');
    const [securityGroupLocal, setSecurityGroupLocal] = useState('');

    const startDate = date;
    const msecDate = Date.parse(date);
    const msecRenew = msecDate + 31556952000;
    const renewByDate = new Date(msecRenew);
    const [dialogTitle, setDialogTitle] = useState();
    const [dialogMessage, setDialogMessage] = useState();
    const [dialogReference, setDialogReference] = useState();
    const [dialogReferenceLink, setDialogReferenceLink] = useState();

    // const [anchorEl, setAnchorEl] = React.useState(null);
    // const open = Boolean(anchorEl);
    // const id = open ? 'simple-popper' : undefined;

    const [open, setOpen] = useState(false);
    const [usState, setUSState] = useState("WASHINGTON");
    const [currentUSState, setCurrentUSState] = useState(usaStatesWithCounties[52]);
    const [currentCounty, setCurrentCounty] = useState();

    const [setupNewAssessment, setSetupNewAssessment] = useState(false);
    const [loadingScreen, setLoadingScreen] = useState(true);

    const defaultDam = [
        {
            _id:'98789065432dgtrd99768',
            damName:'Nothing here...',
        }
    ]
    

   // Move this to the Security Group assessments page. This page now creates the record. 
    const getAssessment = async () => {
        setCardContent();
        try {
            const response = await axiosPrivate.get(`/assessment/${assessmentID}`, {
                withCredentials: true
            });
            const data = await response.data;
            if (data.damID !== "") {
                const damRecord = await axiosPrivate.get(`/dams/${data.damID}`, {
                    withCredentials: true
                });
                    const damRecordData = await damRecord.data;
                    // console.log(damRecord.data);
                    if (damRecordData === null) {
                        return;
                    } else {
                        setDamData(damRecordData);
                        setCardContent(damRecordData);
                        setCenter([damRecordData.latitude, damRecordData.longitude]);
                        setDamID(damRecordData._id);
                        setNidID(damRecordData.nidID);
                    }
                }
        }
        catch (err) {
            if (!err?.response) {
                console.log('No Server Response');
            } else {
                console.log('Action Failed')
            }
        }
    };

    const getDams = async (page=0, pageSize=50, sort={}, search="", usState="Washington", currentCounty="") => {
        const response = await axiosPrivate.get(`/dams?page=${page}&pageSize=${pageSize}&sort=${sort}&search=${search}&usState=${usState}&currentCounty=${currentCounty}`, {
            withCredentials: true
        });
        const data = await response.data;
        console.log(data.dams);
        setAllDamData(data.dams);
        setTotal(data.total);
        setPage(page);
        if (data.dams.length === 0) {
            setAllDamData(defaultDam);
        } 
        if (damData === null) {
            return;
        } else {
            setCardContent(damData);
            setCenter([damData.latitude, damData.longitude]);
            setDamID(damData._id);
            setNidID(damData.nidID);
        }
    };

    // Update a specific record in the database.  This needs to be moved.  We are now creating the record in this page. 
    const handleUpdate = async () => {
        try {
            const response = await axiosPrivate.patch(`/assessment/${assessmentID}`,
            { "damID": damID, "nidID": nidID, "damName": damName, "startDate": startDate, "renewByDate": renewByDate, "assessmentType": assessmentType, "assessmentYear": assessmentYear, "estimatedSecurityGroup": estimatedSecurityGroup, "securityGroupChosen": securityGroupChosen, "securityGroupVerified": securityGroupVerified },
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            }
        );
        setSecurityGroup(securityGroupLocal);
        } catch (err) {
            if (!err?.response) {
                console.log('No Server Response');
            } else {
                console.log('Action Failed');
            }
        }
      }

    useEffect(() => {
        getDams();
        setLoadingScreen(!loadingScreen);
        // getAssessment();
    }, []);  // eslint-disable-line react-hooks/exhaustive-deps
    if (!allDamData) {
        return null;
    }; 

    
    {/** Select the dam from the table row clicked on the MUI Data Grid */}
    const handleSelectDam = () => {
        setQuestionIndex(0);
        setDamData(cardContent);
      };


      {/** Verification Questionnaire */}

    const handleInfo = (value) => {
        setDialogTitle(value.infoTitle);
        setDialogMessage(value.infoText);
        setDialogReference(value.reference);
        setDialogReferenceLink(value.link);
        setOpen(true);
        // setAnchorEl(anchorEl ? null : event.currentTarget);
        // console.log('Handle Info called');
        // console.log(value);
    }

    const handleClose = () => {
        setDialogTitle();
        setDialogMessage();
        setDialogReference();
        setDialogReferenceLink();
        setOpen(false);
    };


    const handleAssessmentType = (e) => {
        e.preventDefault();
        setAssessmentType(e.target.value);
        if (e.target.value === 'closureRequest') {
            setQuestionIndex(4);
            setSecurityGroupLocal('Security Group 1');
        } else {
            setQuestionIndex(questionIndex + 1);
        }
    }

    const handleAssessmentYear = (e) => {
        e.preventDefault();
        if (e.target.value === String(currentYear)) {
            setAssessmentYear(currentYear);
        } else if (e.target.value === String(nextYear)) {
            setAssessmentYear(nextYear);
        } else {
            setAssessmentYear('other');
        }
        setQuestionIndex(questionIndex + 1);
    }

    const handleSecurityGroup = (e) => {
        e.preventDefault();
        if (e.target.value === 'yes') {
            setSecurityGroupVerified('yes');
            setSecurityGroupLocal(estimatedSecurityGroup)
            setQuestionIndex(questionIndex + 2);
        } else {
            setSecurityGroupVerified('no');
            setQuestionIndex(questionIndex + 1);
        }
    } 
    
    const handleChooseSecurityGroup = (e) => {
        e.preventDefault();
        if (e.target.value === 'securityGroup1') {
            setSecurityGroupChosen('Security Group 1')
            setSecurityGroupLocal('Security Group 1')
        } else if (e.target.value === 'securityGroup2') {
            setSecurityGroupChosen('Security Group 2')
            setSecurityGroupLocal('Security Group 2')
        } else {
            setSecurityGroupChosen('Security Group 3')
            setSecurityGroupLocal('Security Group 3')
        }
        setQuestionIndex(questionIndex + 1);
    }
    
    // const handleQuestion4 = (e) => {
    //     e.preventDefault();
    //     setSecurityGroupChosen(e.target.value);
        
    //     setQuestionIndex(questionIndex + 1);
    // }

    const OverviewQuestions = [
        {
            id: 'oq-0',
            questionText: 'Are you completing this assessment for this year or next year.',
            info: [
                { 
                    // infoText: 
                    // reference: 
                    // link: 
                }
            ],
            buttonInfo: [
                {
                    name: currentYear,
                    value: currentYear,
                    variant: 'contained',
                    onClick: handleAssessmentYear,
                },
                {
                    name: nextYear,
                    value: nextYear,
                    variant: 'contained',
                    onClick: handleAssessmentYear,
                },
                {
                    name: 'Other',
                    value: 'other',
                    variant: 'contained',
                    onClick: handleAssessmentYear,
                }
            ]
        },
        {
            id: 'oq-1',
            questionText: 'Is this an annual assessment or is this supporting a Request for Permanent Licensed Facility Closure',
            info: [
                { 
                    infoTitle: 'Assessment Type',
                    infoText: 'Requests for permanent facility closures require the Vulnerability Assessment normally only applicable to Security Group 1.',
                    reference: 'Federal Energy Regulatory Commission Division of Dam Safety and Inspections FERC Security Program for Hydropower Projects Revision 3A, paragraph 3.3.4',
                    link: 'https://www.ferc.gov/sites/default/files/2020-04/security.pdf',
                }
            ],
            buttonInfo: [
                {
                    name: 'Annual Assessment',
                    value: 'annualAssessment',
                    variant: 'contained',
                    onClick: handleAssessmentType,
                },
                {
                    name: 'Closure Request',
                    value: 'closureRequest',
                    variant: 'contained',
                    onClick: handleAssessmentType,
                },
                {
                    name: 'Other',
                    value: 'other',
                    variant: 'contained',
                    onClick: handleAssessmentType,
                },
            ]
        },
        {
            id: 'oq-2',
            questionText: `This dam is classified as ${estimatedSecurityGroup}. Is that correct?`,
            info: [
                {
                    infoTitle: 'FERC Security Groups',
                    infoText: 'The foundation for the FERC Hydropower Security Program are the three security groups (Security Groups 1, 2, and 3) as based on potential dam hazard classification, project size, potential consequences, and installed generation capacity. Contact your <a href="https://www.ferc.gov/ferc-regional-offices" target="_blank">FERC Regional Office</a>.',
                    reference: 'Federal Energy Regulatory Commission Division of Dam Safety and Inspections FERC Security Program for Hydropower Projects Revision 3A, paragraph 3.3.4',
                    link: 'https://www.ferc.gov/sites/default/files/2020-04/security.pdf',
                }
            ],
            buttonInfo: [
                {
                    name: 'Yes',
                    value: 'yes',
                    variant: 'contained',
                    onClick: handleSecurityGroup,
                },
                {
                    name: 'No',
                    value: 'no',
                    variant: 'contained',
                    onClick: handleSecurityGroup,
                }
            ]
        },
        {
            id: 'oq-3',
            questionText: 'What Security Group is this dam?',
            info: [
                {
    
                }
            ],
            buttonInfo: [
                {
                    name: 'Security Group 1',
                    value: 'securityGroup1',
                    variant: 'contained',
                    onClick: handleChooseSecurityGroup,
                },
                {
                    name: 'Security Group 2',
                    value: 'securityGroup2',
                    variant: 'contained',
                    onClick: handleChooseSecurityGroup,
                },
                {
                    name: 'Security Group 3',
                    value: 'securityGroup3',
                    variant: 'contained',
                    onClick: handleChooseSecurityGroup,
                }
            ]
        },
        // {
        //     id: 'oq-4',
        //     questionText: 'Question #4',
        //     info: [
        //         {
    
        //         }
        //     ],
        //     buttonInfo: [
        //         {
        //             name: 'Name',
        //             value: 'value',
        //             variant: 'contained',
        //             onClick: handleQuestion4,
        //         },
        //         {
        //             name: 'Name',
        //             value: 'value',
        //             variant: 'contained',
        //             onClick: handleQuestion4,
        //         },
        //         {
        //             name: 'Name',
        //             value: 'value',
        //             variant: 'contained',
        //             onClick: handleQuestion4,
        //         },
        //     ]
        // },
      ]


    {/** Leaflet Map */}
    const SetMapView = ({ coords }) => {
    setZoomLevel(20);
    const map = useMap();
    map.setView(coords, zoomLevel);
    }

    const icon = new Icon({
        iconUrl: '../03-hydropower-512.png',
        iconSize: [iconSize, iconSize],
        iconAnchor: [10,10]
    });


    {/** MUI Data Grid for Table of All Dams. */}
    const handleRowClick = (params) => {
        if (params?.row?.damName === "Nothing here...") {
            return;
        } else {
            setDamData(null);
            setCardContent(params?.row);
            setCenter([params?.row?.latitude, params?.row?.longitude]);
            setDamID(params?.row?._id);
            setNidID(params?.row?.nidID);
            setDamName(params?.row?.damName);
            if (params?.row?.hazardPotentialClassification === "Low") {
                setEstimatedSecurityGroup('Security Group 3');
            } else if (params?.row?.hazardPotentialClassification === "Significant") {
                setEstimatedSecurityGroup('Security Group 2');
            } else if (params?.row?.hazardPotentialClassification === "High") {
                setEstimatedSecurityGroup('Security Group 1');
            } else { 
                setEstimatedSecurityGroup(damData?.hazardPotentialClassification);
            }
            window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        }
    };
    
    {/** MUI Data Grid Columns */}
      const columns = [
        {
            field: "damName",
            headerName: "Name",
            flex: 1,
            // maxWidth: 200,
            sortable: true,
            editable: false,
            sort: 'asc'
        },
        {
            field: "nidID",
            headerName: "NID ID",
            flex: 0.3,
            // maxWidth: 100,
        },
        {
            field: "ownerNames",
            headerName: "Owner",
            flex: 0.75,
            // maxWidth: 200,
        },
        {
            field: "primaryPurpose",
            headerName: "Primary Purpose",
            flex: 0.5,
            // maxWidth: 200,
        },
        {
            field: "purposes",
            headerName: "All Purposes",
            flex: 0.5,
            // maxWidth: 200,
        },
        {
            field: "riverorStreamName",
            headerName: "River or Stream",
            flex: 0.5,
            // maxWidth: 200,
        },
        {
            field: "state",
            headerName: "State",
            flex: 0.5,
            // maxWidth: 200,
        },
        {
            field: "county",
            headerName: "County",
            flex: 0.5,
            // maxWidth: 200,
        },
        {
            field: "city",
            headerName: "City",
            flex: 0.5,
            // maxWidth: 200,
        },

    ];

    const DefaultCriticalAssets = [
        {
            assetName: 'Dam Structure',
            assetType: 'dam'
        },
        {
            assetName: 'Penstocks',
            assetType: 'penstocks'
        },
        {
            assetName: 'spillwayGateControl',
            assetType: 'spillwayGateControl'
        },
        {
            assetName: 'intakeUnits',
            assetType: 'intakeUnits'
        },
        {
            assetName: 'transformers',
            assetType: 'transformers'
        },
        {
            assetName: 'Powerhouse',
            assetType: 'powerhouse'
        },
        {
            assetName: 'Turbines',
            assetType: 'turbines'
        },
        {
            assetName: 'Control Room',
            assetType: 'controlRoom'
        },
        {
            assetName: 'Server Room',
            assetType: 'serverRoom'
        },
        {
            assetName: 'Switchyard',
            assetType: 'switchyard'
        },
        {
            assetName: 'Substation',
            assetType: 'substation'
        },
        {
            assetName: 'Abutments',
            assetType: 'abutments'
        },
        {
            assetName: 'Transmission',
            assetType: 'transmission'
        },
        {
            assetName: 'Fishway Structure Penetrations',
            assetType: 'fishwayStructurePenetrations'
        },
        {
            assetName: 'Recreation Structure Penetration',
            assetType: 'recreationStructurePenetration'
        },
        {
            assetName: 'Visitor Center',
            assetType: 'visitorCenter'
        },
        {
            assetName: 'Maintenance Galleries',
            assetType: 'maintenanceGalleries'
        },

        {
            assetName: 'Domestic Water',
            assetType: 'domesticWater'
        },
        {
            assetName: 'hazMatStorage',
            assetType: 'HazMat Storage'
        },
    ];

    const threatConditions = [
        {
          threatName: 'Normal',
          threatDescription: 'There is no credible, specific, or impending terrorist threat against the United States. The following protective measures can be considered:',
          sections: [
            {
                name: 'Employees',
                procedures: [
                  'Perform background checks (level of detail determined by the licensee) on any employees who could affect hydropower operations.',
                  'Keep personnel informed of alert levels and, at regular intervals, remind all personnel to report suspicious activities, see attachment, to the appropriate law enforcement or security personnel.',
              ]
            },
            {
                name: 'Planning and Coordination',
                procedures: [
                  'Regularly review and modify security plans and recovery plans (if present), and EAPs. Keep emergency contact lists up to date, coordinate with local law enforcement and security agencies and ensure that they are familiar with facility locations and operations.',
                  'Review all operations plans and orders, EAPs, Recovery Plans, and Standard Operating Procedures (SOPs).',
                  'Increase liaison with local police, intelligence and security agencies to monitor the threat to Project personnel and facilities. Notify local law enforcement agencies concerning measures that, if implemented, could impact on their operations in the local community.',
                  'Consider the use of emergency exercises and drills to enhance overall preparedness.',
              ]
            },
            {
                  name: 'Site Security',
                  procedures: [
                  'Maintain appropriate level of site security. Secure buildings, rooms, and storage areas not in regular use. Maintain a list of secured facilities and areas at the facility or activity level. ',
                  'Provide routine surveillance of visitors, tour groups, and other public users of Project facilities and lands. ',
                  'Regularly inspect all buildings, rooms, and storage areas not in regular use.'
              ]
            }
          ]
        },
        {
          threatName: 'Elevated',
          threatDescription: '“Elevated” warns of a credible terrorist threat against the United States. It is “Elevated” if the United States has no specific information about the timing or location. In addition to the previous measures, the following protective measures can be considered:',
          sections: [
            {
                name: 'Employees',
                procedures: [
                  'Provide employees with as much information as possible on threat conditions, update information frequently. Permit variations in work schedules. Stress importance of vigilance. ',
                  'Verify the identity of all employees and authorized personnel entering Project facilities. Inspect identification cards, security badges or other forms of personal identification. Consider implementing a detailed inspection for all entering vehicles (trunk, undercarriage, glove boxes, etc.), suitcases, briefcases, and other containers. '
              ]
            },
            {
                name: 'Planning and Coordination',
                procedures: [
                  'Maintain continuous liaison with local police, intelligence and security agencies to monitor the threat to Project personnel and facilities. ',
                  'Consult with local or State authorities about closing public roads and facilities that may make Project facilities more vulnerable to attacks. Keep public informed of restricted access and road closings. Notify local police if access/road closings could impact their operations in the local community.',
              ]
            },
            {
                name: 'Site Security',
                procedures: [
                  'Implement 24/7 surveillance of all "critical" facilities (Security Group 1). This measure includes unarmed and/or armed guard forces, and/or law enforcement personnel. Position guard force personnel and/or security patrols at all critical areas. This measure may be augmented by law enforcement agencies, particularly in otherwise unprotected areas.',
                  'Erect barriers required to control direction of traffic flow and to protect facilities vulnerable to bomb attack by parked or moving vehicles.',
                  'Reduce facility access points to an absolute minimum necessary for continued operation. Close all visitor centers and restrict public access to all Project facilities.',
                  'Remove all motor vehicles and heavy equipment parked within 75 feet of critical areas and other sensitive activities specified in local plans. Implement centralized parking and shuttle bus service, where required.',
                  'Where practicable, remove signs that identify the facility.',
                  'Conduct unannounced security spot checks (inspection of personal identification; vehicle registration; and contents of vehicles, suitcases, briefcases and other containers) at access points for Project facilities. At beginning and end of each workday and at frequent intervals, inspect interior and exterior of buildings in regular use for suspicious packages, or signs of tampering, or indications of unauthorized entry.',
                  'Cancel non-essential deliveries.',
                  'Cancel non-essential maintenance/construction that utilizes non-company workers.'
              ]
            }
          ]
        },
        {
          threatName: 'Imminent',
          threatDescription: '“Imminent” warns of a credible, specific, and impending terrorist threat against the United States. It is “Imminent”, if the United States believes the threat is impending or very soon. In addition to the previous measures, the following protective measures can be considered: ',
          sections: [
            {
                  name: 'Employees',
                  procedures: [
                    'Verify identity of all employees entering facility, conduct detailed inspections of their vehicles, briefcases, boxes and any other type of containers. Consider options of alternate work sites for essential employees where feasible. ',
                    'Keep personnel on duty fully informed of threat conditions, implement means to provide necessary information to employees not on duty. ',
              ]
            },
            {
                  name: 'Planning and Coordination',
                  procedures: [
                    'Continue all essential coordination efforts from previous alert levels. Inform public that all facilities are closed. Request that local authorities close those public roads and facilities in the vicinity of Project facilities that may facilitate execution of an attack. ',
                    'Contact armed forces for potential coordination efforts in event of attack.',
              ]
            },
            {
                  name: 'Site Security',
                  procedures: [
                    'Augment law enforcement and guard forces to provide 24/7 surveillance and ensure absolute control over access to the facility. Implement frequent inspections of the exterior of buildings (to include roof areas) and parking areas. ',
                    'Restrict public access to all facilities. Erect barriers, as necessary.',
                    'Inventory and verify the identity of vehicles parked at a facility and move those that are not authorized.',
                    'Thoroughly inspect all items (baggage, suitcases, packages, and briefcases) brought to the site for the presence of explosive or incendiary devices, or other dangerous items. Conduct unannounced security spot checks. '
              ]
            }
          ]
        }
      ];

    

    const loadDefaultThreatConditions = async (assessmentID) => {
        for (let i=0; i<=threatConditions.length-1; i++) {
            let threatName = threatConditions[i].threatName;
            let threatDescription = threatConditions[i].threatDescription;
            let sections = JSON.stringify(threatConditions[i].sections);
            let allAssessmentThreatConditionData = JSON.stringify({threatName, threatDescription, sections})
            const response = await axiosPrivate.post('/threatcondition/new',
                JSON.stringify({ assessmentID, allAssessmentThreatConditionData }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );
        }
    };
  

    const internalEmergencyResponse = [
        {
            sectionTitle: 'Purpose of Internal Plan',
            sectionContent: 'This Internal Emergency Recovery Plan (IERP) is designed as a separate document which can supplement the primary Emergency Action Plan (EAP). Whereas the primary EAP is designed to facilitate early warning and evacuation of potentially affected downstream areas, this document deals with mitigation and emergency repair of affected company structures and plant facilities. A Recovery Plan should be prepared on a site-specific basis in that different facilities (i.e., dams and associated structures) will require different considerations. This is not intended to be a company-wide Continuity of Operations Plan, but rather a plan to bring a specific facility back in operation as efficiently as possible. It is intended for internal use and response only.'
        },
        {
            sectionTitle: 'Incident Command System (ICS) & Company Internal Assignments/Responsibilities ',
            sectionContent: 'The purpose of this section is to describe the emergency response structure the dam owner will operate within and briefly discuss the roles and responsibilities expected from personnel internal to the dam owner’s organization.'
        },
        {
            sectionTitle: 'Coordination with Local Authorities ',
            sectionContent: 'This section should briefly discuss what the dam owner needs to do to coordinate with local law enforcement and emergency response personnel they will work with, and any special needs they have identified. The complet3e list of applicable agencies would be included in Appendix, the EAP Flowcharts.'
        },
        {
            sectionTitle: 'Vehicles, Equipment, Materials (e.g., sandbags, concrete, rip rap) & Contractors ',
            sectionContent: 'This section should list all the vehicles, materials and equipment the dam owner would need to respond to the applicable emergency scenarios identified in Section II. A current list of contractors and support personnel that can be utilized during the emergency should also be listed in this section for easy reference.'
        },
        {
            sectionTitle: 'Response Times & Geographical Limitations ',
            sectionContent: 'Anticipated response times, call-out procedures and geographic limitations should be addressed in this section. Clearly defined directions to critical areas and other locations should be included in textural and graphical format. Staging areas and security exclusion zones should also be identified.'
        },
        {
            sectionTitle: 'Meals & Lodging ',
            sectionContent: 'Any logistical considerations for sustaining personnel detailed to temporary quarters should be identified in this section.'
        },
        {
            sectionTitle: 'Internal Maintenance of Plan ',
            sectionContent: 'This section should address how the Recovery Plan is maintained (updated). Internal employee training of the procedures and information contained within the Plan should also be defined.'
        },
        {
            sectionTitle: 'Appendix A: List of Company Response Personnel (internal call-out list of phone numbers)',
            sectionContent: ''
        },
        {
            sectionTitle: 'Appendix B: List of Contractors/Consultants',
            sectionContent: '(addresses and phone numbers)'
        },
        {
            sectionTitle: 'Appendix C: List of Equipment Suppliers',
            sectionContent: '(addresses and phone numbers)'
        },
        {
            sectionTitle: 'Appendix D: Local Restaurants & Motels (addresses and phone numbers)',
            sectionContent: '(phone numbers of key contacts)'
        },
        {
            sectionTitle: 'Appendix E: Other Utilities/Mutual Aid',
            sectionContent: '(phone numbers of key contacts)'
        },
        {
            sectionTitle: 'Appendix F: Federal/Governmental Assistance',
            sectionContent: '(phone numbers of key contacts)'
        },
        {
            sectionTitle: 'Appendix G: Engineering Key Drawing List',
            sectionContent: '(drawings are located in two secure, non- inundated areas near the facility)'
        },
    
    ];

    const loadDefaultInternalResponse = async (assessmentID) => {
        for (let i=0; i<=internalEmergencyResponse.length-1; i++) {
            let sectionTitle = internalEmergencyResponse[i].sectionTitle;
            let sectionContent = internalEmergencyResponse[i].sectionContent;
            let allAssessmentInternalResponseData = JSON.stringify({sectionTitle, sectionContent})
            const response = await axiosPrivate.post('/internalresponse/new',
                JSON.stringify({ assessmentID, allAssessmentInternalResponseData }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );
        }
    };


    const keyControl = [
        {
            sectionTitle: 'Purpose of Key Control Plan',
            sectionContent: 'To establish a systematic approach to key management, ensuring security and access control within the power generating plant.'
        },
        {
            sectionTitle: 'Key Management Responsibilities',
            sectionContent: 'The key custodian Designated personnel (e.g., Facilities Manager) will be responsible for issuing keys and maintaining records of all keys distributed. The key custodian may only issue keys to authorized key holders. Key Holders are individuals who receive keys for use in the course of thier work. They must sign a key receipt form acknowledging responsibility for the key. A complete inventory of all keys must be maintained, including details such as key type, number of copies, and the individuals to whom keys have been issued. '
        },
        {
            sectionTitle: 'Primaries and Duplicates',
            sectionContent: 'Each site must maintian two copies of all keys. Primaries are the keys that are regularly issued. Duplicates are copies of primary keys securely stored in an alternate area.'
        },
        {
            sectionTitle: 'Administrative Controls: Custodians',
            sectionContent: 'A key and lock custodian and alternate(s) will be appointed in writing. Custodians and alternates shall be responsible for the proper accounting and security of all keys to the activity. Persons without unaccompanied access to property and storage areas will not be appointed as custodians or alternates. In those cases where keys must be available at any time, authorized security or other 24/7 personnel may be designated to secure, issue, and/or receive keys. ',
        },
        {
            sectionTitle: 'Administrative Controls: Limited Access',
            sectionContent: 'The number of personnel authorized to possess and use keys will be limited to those persons who have an absolute need. Persons designated to have access to a key system or separate keys therein, will be identified in writing. Access forms will show the name, position, and key number/area authorized. The access form should be kept by the custodian/alternate who issues keys for ready reference.'
        },
        {
            sectionTitle: 'Administrative Controls: Depositories and Containers',
            sectionContent: 'All keys, when not in use, will be secured on the person to whom assigned, or be secured in lockable container, such as a safe, filing cabinet, or key depository that secured with a locking device or combination lock/padlock. Depositories/containers that are easily removed will be securely affixed to the structure. The key depository will be located in a room where it is kept under surveillance around-the-clock, or in a room that can be securely locked during non-duty hours.'
        },
        {
            sectionTitle: 'Accountability Procedures: Inventory',
            sectionContent: 'A complete written inventory of all keys by serial number and location will be maintained on a Key Control Register and Inventory form.'
        },
        {
            sectionTitle: 'Accountability Procedures: Monthly Inventory',
            sectionContent: 'A monthly visual inventory count of all keys in the system will be conducted by the custodian/alternate. The count will be recorded on a, Monthly Key Control Register and Inventory form. The completed form will be kept in unit files for one year. '
        },
        {
            sectionTitle: 'Accountability Procedures: Documentation',
            sectionContent: 'Keys issued for personal retention and daily use will be documented in the Key Control Register and Inventory form.'
        },
        {
            sectionTitle: 'Accountability Procedures: Daily Inventory',
            sectionContent: 'A daily 100% visual count of all primary keys (those operational keys secured in the daily use key box/depository) will be conducted by the custodian/alternate at the start of each duty day and prior to any keys being issued. The inventory count will be compared against the closing visual count that was annotated on the Daily Key Control Register and Inventory formas the last entry for the previous day. 24-hour sign out/in logs will be closed at midnight each day. Discrepancies between the closing and opening inventory counts will be investigated and resolved before issuing any keys unless not issuing keys would adversely impact the site. Keys that are still out should be accounted for prior to closing. In those cases where secuirty officers or other on-duty personnel are securing and issuing keys on a 24-hour basis, inventories will be conducted by those personnel whenever a change of custody occurs between them.'
        },
        {
            sectionTitle: 'Accountability Procedures: Duplicates',
            sectionContent: 'Duplicate keys do not need to be inventoried daily. Duplicate keys must be inventoried at least monthly. Dupliate keys must be inventoried everytime the secured lockbox or container is accessed.'
        },
        
        {
            sectionTitle: 'Key Loss and Reporting',
            sectionContent: 'Immediately report any lost or stolen keys to the custodian or alternate. The custodian or alternate will investigate the loss and determine the necessary actions, which may include rekeying locks or changing access codes.'
        },
    ];

    const loadDefaultKeyControl = async (assessmentID) => {
        for (let i=0; i<=keyControl.length-1; i++) {
            let sectionTitle = keyControl[i].sectionTitle;
            let sectionContent = keyControl[i].sectionContent;
            let allAssessmentKeyControlData = JSON.stringify({sectionTitle, sectionContent})
            const response = await axiosPrivate.post('/keycontrol/new',
                JSON.stringify({ assessmentID, allAssessmentKeyControlData }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );
        }
    };

    const rapidResponseProcedures = [
        {
            sectionTitle: 'Purpose of Rapid Response Procedures',
            sectionContent: 'The Rapid Recovery Plan documents the actions an organization takes to recover from a disaster. The disaster can be natural or caused by criminal activity. The Rapid Recovery in this program refers to the pre-planned actions allowing a utility to continue, or quickly restore, generation of power, or otherwise function in its intended purpose. This sub-element is sometimes associated with the Utility Recovery Plan, Continuity of Operation Plan, etc., however those plans are usually at the company level, whereas the Rapid Recovery portion of the SP is intended to bring a specific facility back in operation as efficiently as possible. The Rapid Recovery must also address security, with a discussion of what it would take to bring a project back on-line for power generation, including but not limited to stockpiles of materials, location of heavy equipment, warehousing critical spare parts, etc. Interruptions to transmission lines and switch yards should also to be considered. ',
            sectionList: []
        },
        {
            sectionTitle: 'Initial Recovery',
            sectionContent: '',
            sectionList: [
                'If the Emergency Action Plan is still activated, determine appropriate time to terminate EAP and transition to Recovery phase. Define requirements for interim inspection and monitoring above and beyond the Standard Operating Procedures. ',
                'Restore critical systems (generation facilities, dams, water conveyance systems, telecommunications, monitoring systems, controls, etc.) to stable and safe operations. Assure public safety and business continuity.',
                'Evaluate the need to continue the Emergency Management Organization (the Leadership team put in place by activation of the Emergency Action Plan), and transition back to normal organizational structure, roles and responsibilities as soon as feasible. ',
                'Complete detailed condition assessment of all relevant facilities, equipment and operations.',
                'Coordination with other agencies for the mitigation of the emergency, especially for environmental concerns.',
                'Conduct after-event critiques and debriefings as soon as practical; provide incident reports to Company Management and Regulatory Agencies. ',
                'Consider appointing a Recovery Team (members experienced with evaluation of structures, systems, equipment and operations; this team would develop the alternatives to be evaluated and approved for returning to normal operations) to plan and oversee the long-term recovery process.',
                'Establish priorities for permanent repair, reconstruction, or replacement at existing or new locations.',
                'Complete an assessment of losses and an analysis of costs for repairs versus replacements.',
                'Review and define needs for additional specialized technical resources and temporary staff, including additional security staff if necessary, and initiate procurement/recruitment process.',
                'Arrange for orientation and training of any temporary staff.',
                'Determine approximate reimbursements from insurance and other sources of financial assistance, and identify alternatives for financing residual costs.'
            ]
        },
        {
            sectionTitle: 'Long-Term Recovery',
            sectionContent: '',
            sectionList: [
                'Revise Operational Plans, Emergency Action Plans, Disaster Recovery Plans, and Manuals as appropriate.',
                'Identify any enhancements that should be made to hydroelectric facility components and/or operations, and establish strategy for long-term recovery and environmental restoration.',
                'Establish and maintain liaison with federal, state, and local government agencies for inspections, permits and reconstruction as necessary.',
                'Initiate permanent reconstruction of damaged facilities and replacement of damaged equipment.'
            ]
        }
    ];

    const loadDefaultRapidResponse = async (assessmentID) => {
        for (let i=0; i<=rapidResponseProcedures.length-1; i++) {
            let sectionTitle = rapidResponseProcedures[i].sectionTitle;
            let sectionContent = rapidResponseProcedures[i].sectionContent;
            let sectionList = rapidResponseProcedures[i].sectionList;
            let allAssessmentRapidResponseData = JSON.stringify({sectionTitle, sectionContent, sectionList})
            const response = await axiosPrivate.post('/rapidresponse/new',
                JSON.stringify({ assessmentID, allAssessmentRapidResponseData }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );
        }
    };


    const emergencyScenarios = [
        {
            scenario: 'Overtopping (including excessive inflow or reservoir displacement)',
            primaryConcerns: '',
            materialsEquipmentNeeded: [
                '',
            ],
            operatingProcedures: [
                '',
            ]
        },
        {
            scenario: 'Earthquake Damage',
            primaryConcerns: '',
            materialsEquipmentNeeded: [
                '',
            ],
            operatingProcedures: [
                '',
            ]
        },
        {
            scenario: 'Loss of Dam Crest Length',
            primaryConcerns: '',
            materialsEquipmentNeeded: [
                '',
            ],
            operatingProcedures: [
                '',
            ]
        },
        {
            scenario: 'Slide on Upstream or Downstream Slope of Embankment',
            primaryConcerns: '',
            materialsEquipmentNeeded: [
                '',
            ],
            operatingProcedures: [
                '',
            ]
        },
        {
            scenario: 'Slide on Underlying Potential Failure Plane',
            primaryConcerns: '',
            materialsEquipmentNeeded: [
                '',
            ],
            operatingProcedures: [
                '',
            ]
        },
        {
            scenario: 'Excessive Settlement',
            primaryConcerns: '',
            materialsEquipmentNeeded: [
                '',
            ],
            operatingProcedures: [
                '',
            ]
        },
        {
            scenario: 'Sinkhole Activity',
            primaryConcerns: '',
            materialsEquipmentNeeded: [
                '',
            ],
            operatingProcedures: [
                '',
            ]
        },
        {
            scenario: 'Loss of Foundation or Abutment Material (such as landslide/rockfall)',
            primaryConcerns: '',
            materialsEquipmentNeeded: [
                '',
            ],
            operatingProcedures: [
                '',
            ]
        },
        {
            scenario: 'Excessive Seepage/Piping through Embankment, Foundation, or Abutments',
            primaryConcerns: '',
            materialsEquipmentNeeded: [
                '',
            ],
            operatingProcedures: [
                '',
            ]
        },
        {
            scenario: 'Failure of Appurtenant Structure Such as a Spillway Gate',
            primaryConcerns: '',
            materialsEquipmentNeeded: [
                '',
            ],
            operatingProcedures: [
                '',
            ]
        },
        {
            scenario: 'Excessive Cracking in Concrete Section',
            primaryConcerns: '',
            materialsEquipmentNeeded: [
                '',
            ],
            operatingProcedures: [
                '',
            ]
        },
        {
            scenario: 'Penstock Rupture/Failure',
            primaryConcerns: '',
            materialsEquipmentNeeded: [
                '',
            ],
            operatingProcedures: [
                '',
            ]
        },
        {
            scenario: 'Turbine or Other Equipment Failure',
            primaryConcerns: '',
            materialsEquipmentNeeded: [
                '',
            ],
            operatingProcedures: [
                '',
            ]
        },
        {
            scenario: 'Vandalism/Bomb Threat/Terrorism',
            primaryConcerns: '',
            materialsEquipmentNeeded: [
                '',
            ],
            operatingProcedures: [
                '',
            ]
        },
    ];

    const loadEmergencyScenarios = async (assessmentID) => {
        for (let i=0; i<=emergencyScenarios.length-1; i++) {
            let scenario = emergencyScenarios[i].scenario;
            let primaryConcerns = emergencyScenarios[i].primaryConcerns;
            let materialsEquipmentNeeded = emergencyScenarios[i].materialsEquipmentNeeded;
            let operatingProcedures = emergencyScenarios[i].operatingProcedures;
            let allAssessmentEmergencyScenarioData = JSON.stringify({scenario, primaryConcerns, materialsEquipmentNeeded, operatingProcedures})
            const response = await axiosPrivate.post('/emergencyscenarios/new',
                JSON.stringify({ assessmentID, allAssessmentEmergencyScenarioData }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );
        }
    };

        const communicationsPlan = [
            {
                sectionTitle: "Purpose of Communications Plan",
                sectionContent: "To establish a systematic approach coordinate utility personnel, gather information, and provide information to leadership and first responders. A communications plan is a document that defines communications roles and establishes protocols for different groups during an emergency. Each community or jurisdiction will have its own communications plan."
            },
            {
                sectionTitle: "Communications Plan Diagram",
                sectionContent: "Emergency commuincations will follow the organizational structure. All teams will coordinate with the Command Post. The Command Post will relay information to Leadership and coordinate activities with site personnel and first responders.",
                sectionParagraphs: [
                    "This diagram is a scheme of the basic relationships a communications plan is meant to define. In this particular example (a very simple one), we have two response teams, A and B. In this basic diagram, the light blue circle in the center represents theCommand Unit.",
                    "Note that while the members of each team can communicate directly with one another, only the team leader can communicate with the Command Post, and only the Command Post can communicate with other agencies.",
                    "The Command Post may instruct individual team members or team leaders to contact another team or an emergency response agency directly. How this happens will be discussed later under Net Control Operations.",
                ]
            },
            {
                sectionTitle: "Teams",
                sectionContent: "This communication plan defines six (6) teams for emergency communications and recovery coordination.",
                sectionParagraphs: [
                    "Team 1: Command Post",
                    "Team 2: Maintenance",
                    "Team 3: Operations",
                    "Team 4: Safety",
                    "Team 5: Leadership",
                    "Team 6: First Responders"
                ]
            },
            {
                sectionTitle: 'Communications Methods/Modes',
                sectionContent: 'A communications mode is a technology or method for communicating. Each communications mode has its own particular advantages and limitations.',
                sectionParagraphs: [
                    "Runners. Runners are volunteers who carry messages from one location to another. Runners are extremely reliable, since they can operate in situations that would otherwise disable other communications systems. Where possible, use people who are not doing anything else to help out as runners; otherwise you will deplete your pool of trained volunteers. Always have runners carry written messages in order to avoid miscommunication.",
                    "Landline Telephones. Landline telephones, especially analog phones, may operate during power failures, as they are powered by the phone line itself. Handheld phones may only last as long as their batteries, and digital phones may or may not work, depending on the model. Many communities now have Enhanced 9-1-1, enabling 9-1-1 operators to lock onto your location by providing them your address and phone number. Be aware that local telephone systems typically cannot handle a load of more than 10% at any one time. That means that if more than 10% of the people in an area attempt to use the phone at the same time, the system will overload and fail.",
                    "Cellular Phones. The greatest advantages of cell phones as communications devices are that they are commonplace and that they are mobile. Most cell phones are also able to transmit text messages. In the event of a system overload or a weak signal, a text message may get through when a voice message will not. This is because a text message requires far less bandwidth than voice. Cell phone networks are also easily overloaded during times of high demand. Another problem with using cell phones in emergency communications is that first responders must have each other’s cell phone numbers. For those volunteers using cell phones during an emergency response, it is recommended that hands-free headsets and microphones be used.",
                    "Two-Way Radios. Two-way radio is a communications mode that does not rely on external power supplies or physical communications infrastructure. This makes two-way radios a good communications mode during an emergency.",
                    "Email, Chat, or Removable Media (computer-based). Computer communications includes e-mail, photos, video, audio, formatted documents, or other data transmitted via the Internet or by runners using USB drives (commonly known as “thumb drives”) or other media such as CDs. One of the overlooked advantages to using computers for communication is the ease of mass distribution. Using e-mail, alerts, notices, preformatted documents, and other information can be sent out to any number of participants with a single click. While laptops are portable, they may not be ideal for use in emergency response situations due to their size. Also, both senders and receivers have to have functioning, specialized equipment in order to communicate using computers. Receivers must also be monitoring such equipment. It doesn’t help to e-mail a team leader a photo of a dangerous situation if that team leader doesn’t have immediate access to e-mail.",
                    "Satellite Phones. When other phone systems are down, satellite phones will only be able to communicate locally with other satellite phones. Satellite phones are typically available to and used by large emergency responders, such as FEMA. Satellite phones range in pricefrom $750 to upwards of several thousand dollars.",
                ]
                // sectionParagraphs: [
                //     {
                //         type: "Runners",
                //         description: "Runners are volunteers who carry messages from one location to another. Runners are extremely reliable, since they can operate in situations that would otherwise disable other communications systems. Where possible, use people who are not doing anything else to help out as runners; otherwise you will deplete your pool of trained volunteers. Always have runners carry written messages in order to avoid miscommunication.",
                //         advantages: [
                //             "Reliability",
                //             "Flexibility",
                //             "Familiar with environment",
                //             "Availability",
                //             "No special training"
                //         ],
                //         limitations: [
                //             "Distance and time",
                //             "Requires written information for accuracy",
                //             "Availability (deplete your team)",
                //             "Requires familiarity with the area",
                //         ]
                //     },
                //     {
                //         type: "Landline Telephones",
                //         description: "Landline telephones, especially analog phones, may operate during power failures, as they are powered by the phone line itself. Handheld phones may only last as long as their batteries, and digital phones may or may not work, depending on the model. Many communities now have Enhanced 9-1-1, enabling 9-1-1 operators to lock onto your location by providing them your address and phone number. Be aware that local telephone systems typically cannot handle a load of more than 10% at any one time. That means that if more than 10% of the people in an area attempt to use the phone at the same time, the system will overload and fail.",
                //         advantages: [
                //             "Familiarity",
                //             "Commonplace",
                //             "Enhanced 9-1-1",
                //             "Data transfer",
                //         ],
                //         limitations: [
                //             "Not mobile",
                //             "System overloads easily",
                //             "Network can sustain physical damage",
                //             "Power failure may/may not affect",
                //         ]
                //     },
                //     {
                //         type: "Cellular Phones",
                //         description: "The greatest advantages of cell phones as communications devices are that they are commonplace and that they are mobile. Most cell phones are also able to transmit text messages. In the event of a system overload or a weak signal, a text message may get through when a voice message will not. This is because a text message requires far less bandwidth than voice. Cell phone networks are also easily overloaded during times of high demand. Another problem with using cell phones in emergency communications is that first responders must have each other’s cell phone numbers. For those volunteers using cell phones during an emergency response, it is recommended that hands-free headsets and microphones be used.",
                //         advantages: [
                //             "Familiarity",
                //             "Mobility",
                //             "Commonplace",
                //             "Text messaging - requires less bandwidth and may get through when voice will not",
                //             "Camera phones",
                //         ],
                //         limitations: [
                //             "Towers may fail due to power outage or damage",
                //             "System overloads easily",
                //             "Requires knowledge of phone numbers",
                //             "May be dependent on landlines",
                //         ]
                //     },
                //     {
                //         type: "Two-Way Radios",
                //         description: "Two-way radio is a communications mode that does not rely on external power supplies or physical communications infrastructure. This makes two-way radios a good communications mode during an emergency.",
                //         advantages: [
                //             "Range of 2-5 miles (varies with terrain)",
                //             "Best used for intra-team communications",
                //             "Base station or mobile",
                //             "Boost signal with external antennas or repeaters",
                //             "Transmit voice or data",
                //             "No license required"
                //         ],
                //         limitations: [
                //             "Limited channels/frequencies available for use",
                //             "Can be expensive",
                //         ]
                //     },
                //     {
                //         type: "Email or Chat (computer-based)",
                //         description: "Computer communications includes e-mail, photos, video, audio, formatted documents, or other data transmitted via the Internet or by runners using USB drives (commonly known as “thumb drives”) or other media such as CDs. One of the overlooked advantages to using computers for communication is the ease of mass distribution. Using e-mail, alerts, notices, preformatted documents, and other information can be sent out to any number of participants with a single click. While laptops are portable, they may not be ideal for use in emergency response situations due to their size. Also, both senders and receivers have to have functioning, specialized equipment in order to communicate using computers. Receivers must also be monitoring such equipment. It doesn’t help to e-mail a team leader a photo of a dangerous situation if that team leader doesn’t have immediate access to e-mail.",
                //         advantages: [
                //             "May work when other systems are down",
                //             "Provides electronic record",
                //             "Versatile network connections",
                //             "Relatively secure",
                //             "Large amounts of information",
                //             "Formatted/preformatted information",
                //             "Thumb drives and discs do not require Internet",
                //             "Information redistribution"
                //         ],
                //         limitations: [
                //             "Equipment may not be mobile",
                //             "May require Internet connectivity",
                //             "Requires specific hardware",
                //             "Expensive",
                //         ]
                //     },
                //     {
                //         type: "Satellite Phones",
                //         description: "When other phone systems are down, satellite phones will only be able to communicate locally with other satellite phones. Satellite phones are typically available to and used by large emergency responders, such as FEMA. Satellite phones range in pricefrom $750 to upwards of several thousand dollars.",
                //         advantages: [
                //             "Always on",
                //             "Global operation",
                //         ],
                //         limitations: [
                //             "Expensive",
                //             "Rare",
                //             "Requires visibility to sky, or building with compatible antenna",
                //         ]
                //     },
                // ],
            },
            {
                sectionTitle: "Net Operations",
                sectionContent: "Net Operations, or a Controlled Net, is a system for coordinating communication among groups of four or more people.",
                sectionParagraphs: [
                    "As long as a small team is communicating among themselves, they use standard radio protocol. However, once a team needs to contact either another team, report into a command unit, or contact another emergency response agency, they need to contact a Net Control Operator.",
                    "A Controlled Net has one or more Net Control Operators. The Net Control Operator is essentially a radio “traffic cop.” When you call a Net Control Operator, the operator will:",
                    "Direct you to call whomever you need to speak to.",
                    "Direct you to stand by.",
                    "Direct you to a different radio channel.",
                    "Prioritize calls based on the nature of the message.",
                    "A Net Control Operator is functioning as the “brain” of a communications network. Large networks may require several networks of Net Control Operators. The Command Post also serves as the Net Control Operator but may delegate this task to another entity based on operations tempo."
                ]
            },
        ];


        const loadDefaultCommunicationsPlan = async (assessmentID) => {
            for (let i=0; i<=communicationsPlan.length-1; i++) {
                let sectionTitle = communicationsPlan[i].sectionTitle;
                let sectionContent = communicationsPlan[i].sectionContent;
                let sectionParagraphs = communicationsPlan[i].sectionParagraphs;
                let allAssessmentCommunicationPlanData = JSON.stringify({sectionTitle, sectionContent, sectionParagraphs})
                const response = await axiosPrivate.post('/communicationsplan/new',
                    JSON.stringify({ assessmentID, allAssessmentCommunicationPlanData }),
                    {
                        headers: { 'Content-Type': 'application/json' },
                        withCredentials: true
                    }
                );
            }
        };
      
    
    
    // Create the record and redirect the user to the appropriate webpage. 
    const handleCreateRecord = async () => {
        setSetupNewAssessment(!setupNewAssessment);
        try {
            const response = await axiosPrivate.post(`/assessment/new`,
                JSON.stringify({ userId, damID, nidID, damName, startDate, renewByDate, assessmentType, assessmentYear, estimatedSecurityGroup, securityGroupChosen, securityGroupVerified, securityGroupLocal }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );
            try {
                const assessmentID = response.data._id;
                loadDefaultThreatConditions(assessmentID);
                loadDefaultInternalResponse(assessmentID);
                loadDefaultKeyControl(assessmentID);
                loadDefaultRapidResponse(assessmentID);
                loadEmergencyScenarios(assessmentID);
                loadDefaultCommunicationsPlan(assessmentID);
                for (let i=0; i<=DefaultCriticalAssets.length; i++) {
                    let assetName = DefaultCriticalAssets[i].assetName;
                    let assetType = DefaultCriticalAssets[i].assetType;
                    const response = await axiosPrivate.post(`/criticalassets/criticalasset/new`,
                        JSON.stringify({ assessmentID, assetName, assetType }),
                      {
                          headers: { 'Content-Type': 'application/json' },
                          withCredentials: true
                      }
                    );
                }
                loadDefaultThreatConditions(assessmentID);
            
            // console.log(JSON.stringify(response.data._id));
          
            } catch (err) {
                if (!err?.response) {
                    console.log('No Server Response');
                } else {
                    console.log('Action Failed')
                }
            }
            
            setSetupNewAssessment(!setupNewAssessment);
            if (securityGroupLocal==="Security Group 3") {
                navigate(`/fercassessment/securitygroup3/${response.data._id}`);
            } else if (securityGroupLocal==="Security Group 2") {
                navigate(`/fercassessment/securitygroup2/${response.data._id}`); 
            } else if (securityGroupLocal==="Security Group 1") {
                navigate(`/fercassessment/securitygroup1/${response.data._id}`);
            } else {
                navigate(`/fercassessment/${response.data._id}`);
            }
            
            // Axios provides responses in JSON by default. The response JSON is always named 'data'. 
            // The console log commands below allow you to see the responses from Axios for the response.  
            // navigate(from, { replace: true });
        } catch (err) {
            if (!err?.response) {
                console.log('No Server Response');
            } else {
                console.log('Action Failed')
            }
        }
    };

    // console.log('screen width is: ');
    // console.log(window.screen.width)

    function handleChange(...args) {
        // console.log(args);
        setPage(args[0]);
        setPageSize(args[1]);
        setSort(args[2]);
        setIsChanged(true);
    };

    const handleStateChange = async (e) => {
        e.preventDefault();
        setCurrentUSState(e.target.value);
        setUSState(e.target.value.name);
        setIsChanged(true);
    };

    const handleCountyChange = async (e) => {
        e.preventDefault();
        setCurrentCounty(e.target.value);
        // const filteredDamsData = allDamData.filter(item => item.county === "Whatcom");
        // console.log(filteredDamsData);
        // setAllDamData(filteredDamsData);
        setIsChanged(true);
    };

    const handleSearch = async () => { 
        setPage(0);
        setPageSize(50);
        setSearch(searchInput);
        setUSState("");
        setCurrentCounty("");
        // const response = await axiosPrivate.get(`/dams/search?page=${page}&pageSize=${pageSize}&search=${searchInput}`, { 
        //     withCredentials: true
        // });
        // const data = await response.data;
        // setAllDamData(data);
        // setTotal(data.length);
        // setPage(page);
        setIsChanged(true);
    };

    // console.log(window.innerWidth);
    // console.log(window.screen.width);
    // console.log(window.devicePixelRatio);

    return (
        <Box component="main" sx={{ flexGrow: 1, p: 3, width: 'calc(100vw - ${drawerWidth}px)', overflow:'clip' }}>

            <Dialog
                open={loadingScreen}
                aria-labelledby="loading-dialog-title"
                aria-describedby="loading-dialog-description"
                >
                <DialogTitle id="loading-dialog-title"> </DialogTitle>
                <DialogContent>
                <DialogContentText id="loading-dialog-description">
                    <Typography variant='h2'>Loading...</Typography>
                </DialogContentText>
                </DialogContent>
            </Dialog>

            <Dialog
                open={setupNewAssessment}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                >
                <DialogTitle id="alert-dialog-title"> </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <Typography variant='h2'>Setting Up Assessment...</Typography>
                </DialogContentText>
                </DialogContent>
            </Dialog>

             

            
            <Grid container spacing={2}>
                <Grid item xs={5}>
                    {/** Dam Info on row select box. */}
                    <Grid item xs={12}>
                        <Paper sx={{ backgroundImage: "none", backgroundColor: theme.palette.background.alt, borderRadius: "0.55rem", mr:'0.55rem', p:'4px', minHeight:'250px'}} >
                            <Grid item xs={12}>
                                <Button variant="contained" disabled={!cardContent} onClick={handleSelectDam}>
                                    Select
                                </Button>
                                <Divider sx={{ border:0.5, mt:'6px' }}/>
                            </Grid>
                            {cardContent ? (
                            <Grid container spacing={2}>
                            {/* Column 1 */}
                            <Grid item xs={3}>
                                <Header title="Name:" />
                                <Typography sx={{ fontSize: 16}} color={theme.palette.secondary[700]} gutterBottom>
                                    {cardContent.damName || "--"}
                                </Typography>
                                <Header title="Owner(s):" />
                                <Typography sx={{ fontSize: 16}} color={theme.palette.secondary[700]} gutterBottom>
                                    {cardContent.ownerNames || "--"}
                                </Typography>
                                {/* <Header title="Operational Status:" />
                                <Typography sx={{ fontSize: 16}} color={theme.palette.secondary[700]} gutterBottom>
                                    {cardContent.operationalStatus || "--"}
                                </Typography>
                                <Header title="Primary Purpose:" />
                                <Typography sx={{ fontSize: 16}} color={theme.palette.secondary[700]} gutterBottom>
                                    {cardContent.primaryPurpose || "--"}
                                </Typography> */}
                                {/* <Header title="Purposes:" />
                                <Typography sx={{ fontSize: 16}} color={theme.palette.secondary[700]} gutterBottom>
                                    {cardContent.purposes || "--"}
                                </Typography>
                                <Header title="NID ID:" />
                                <Typography sx={{ fontSize: 16}} color={theme.palette.secondary[700]} gutterBottom>
                                    {cardContent.nidID || "--"}
                                </Typography> */}
                            </Grid>

                            {/* Column 2 */}
                        <Grid item xs={3}>
                                <Header title="Dam Type:" />
                                <Typography sx={{ fontSize: 16}} color={theme.palette.secondary[700]} gutterBottom>
                                    {cardContent.damTypes || "--"}
                                </Typography>
                                <Header title="NID Height:" />
                                <Typography sx={{ fontSize: 16}} color={theme.palette.secondary[700]} gutterBottom>
                                    {Intl.NumberFormat().format(cardContent.nIDHeightFt) || "--"} ft
                                </Typography>
                                <Header title="Length:" />
                                <Typography sx={{ fontSize: 16}} color={theme.palette.secondary[700]} gutterBottom>
                                    {Intl.NumberFormat().format(cardContent.damLengthFt) || "--"} ft
                                </Typography>  
                                {/* <Header title="Year Built:" />
                                <Typography sx={{ fontSize: 16}} color={theme.palette.secondary[700]} gutterBottom>
                                    {cardContent.yearCompleted || "--"}
                                </Typography>  
                                <Header title="Max Storage:" />
                                <Typography sx={{ fontSize: 16}} color={theme.palette.secondary[700]} gutterBottom>
                                    {Intl.NumberFormat().format(cardContent.maxStorageAcreFt) || "--"} Acre-Ft
                                </Typography>  
                                <Header title="Normal Storage:" />
                                <Typography sx={{ fontSize: 16}} color={theme.palette.secondary[700]} gutterBottom>
                                    {Intl.NumberFormat().format(cardContent.normalStorageAcreFt) || "--"} Acre-Ft
                                </Typography>   */}
                        </Grid>

                        {/* Column 3 */}
                        <Grid item xs={3}>
                                <Header title="Hazard Potential:" />
                                    <Typography sx={{ fontSize: 16}} color={theme.palette.secondary[700]} gutterBottom>
                                        {cardContent.hazardPotentialClassification || "--"}
                                    </Typography>
                                <Header title="Last Inspection Date / Frequency:" />
                                    <Typography sx={{ fontSize: 16}} color={theme.palette.secondary[700]} gutterBottom>
                                        {cardContent.lastInspectionDate} / {cardContent.inspectionFrequency} year(s)
                                    </Typography>
                                {/* <Header title="Condition Assessment:" />
                                    <Typography sx={{ fontSize: 16}} color={theme.palette.secondary[700]} gutterBottom>
                                        {cardContent.hazardPotentialClassification || "--"}
                                    </Typography>
                                <Header title="Emergency Action Plan Prepared:" />
                                    <Typography sx={{ fontSize: 16}} color={theme.palette.secondary[700]} gutterBottom>
                                        {cardContent.eAPPrepared}
                                    </Typography>
                                <Header title="Primary Source Agency:" />
                                    <Typography sx={{ fontSize: 16}} color={theme.palette.secondary[700]} gutterBottom>
                                        {cardContent.sourceAgency || "--"}
                                    </Typography> */}
                        </Grid>

                        {/* Column 4 */}
                        <Grid item xs={3}>
                        <Header title="River or Stream:" />
                                <Typography sx={{ fontSize: 16}} color={theme.palette.secondary[700]} gutterBottom>
                                    {cardContent. riverorStreamName || "--"}
                                </Typography>
                            <Header title="City:" />
                                <Typography sx={{ fontSize: 16}} color={theme.palette.secondary[700]} gutterBottom>
                                    {cardContent.city}
                                </Typography>
                            {/* <Header title="County:" />
                                <Typography sx={{ fontSize: 16}} color={theme.palette.secondary[700]} gutterBottom>
                                    {cardContent.county} ft
                                </Typography>
                            <Header title="State:" />
                                <Typography sx={{ fontSize: 16}} color={theme.palette.secondary[700]} gutterBottom>
                                    {cardContent.state} ft
                                </Typography> */}
                        </Grid>
                            </Grid>
                                ) : (
                                <FlexBetween sx={{ padding:'1rem' }}>
                                        <h1>CHOOSE A DAM FROM THE TABLE BELOW FOR MORE DETAILS.</h1>
                                </FlexBetween>                        
                                    )
                            }  
                        </Paper>
                    </Grid>

                {/** Pop up for question information.  */}
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                <DialogTitle id="alert-dialog-title">
                {dialogTitle}
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <Typography sx={{ p: '1rem' }}>
                        {dialogMessage}
                    </Typography>
                    <Typography sx={{ paddingTop: '2rem', alignContent: 'end'}}>
                        <a href={dialogReferenceLink} target='_blank'>{dialogReference}</a>
                    </Typography>
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose} autoFocus>
                    Close
                </Button>
                </DialogActions>
                </Dialog>

                    {/** Questions box to verify some dam assessment information. */}
                    <Grid item xs={12} >
                        <Paper sx={{ marginTop:'1rem', backgroundImage: "none", backgroundColor: theme.palette.background.alt, borderRadius: "0.55rem", mr:'0.55rem', p:'1rem', minHeight:'250px'}} >
                            <Typography>
                                Verify Information
                            </Typography>
                            {damData ? (questionIndex<OverviewQuestions.length) ? (
                            <Box>
                                {/* <Typography>{questionIndex}</Typography> */}
                                <Typography display='inline' sx={{ fontSize: 20, p: '2rem'}}>
                                    {OverviewQuestions[questionIndex].questionText}
                                </Typography>
                                {OverviewQuestions[questionIndex].info[0].infoText && OverviewQuestions[questionIndex].info[0].infoText.length>0 && 
                                    <IconButton onClick={(() => handleInfo(OverviewQuestions[questionIndex].info[0]))}><InfoIcon /></IconButton>
                                }
                                <Typography sx={{ alignContent:'center'}}>
                                    {OverviewQuestions[questionIndex].buttonInfo.map((index) =>
                                        <Button value={index.value} sx={{ m: '1rem' }} variant="contained" onClick={index.onClick}>
                                            {index.name}
                                        </Button>
                                    )}
                                </Typography> 
                            </Box>
                        ) : ( 
                            <Box>
                                <Typography sx={{m:'5px', p: '10px'}}>
                                    <b>If this looks right, click 'BEGIN ASSESSMENT'.</b>
                                </Typography>
                                <Divider />
                                <Typography>
                                        Dam Name: {damName}
                                </Typography>
                                <Typography>
                                    Assessment Type: {assessmentType==="annualAssessment" ? ("Annual") : (assessmentType==='closureRequest') ? ("Closure") : ('Other') }
                                </Typography>
                                <Typography>
                                    Security Group: {securityGroupLocal}
                                </Typography>
                                <Grid item xs={12}>
                                    <Divider sx={{ border:0.5, mb:'6px' }}/>
                                    <Box sx={{ alignItems:'right'}}>
                                        <Button variant="contained" disabled={questionIndex<2} onClick={handleCreateRecord}>
                                            Begin Assessment
                                        </Button>
                                        
                                    </Box>
                                    </Grid>
                            </Box>
                        ) : (
                            <FlexBetween sx={{ padding:'1rem' }}>
                                <h1>Click 'Select' button to begin the assessment.</h1>
                            </FlexBetween>   
                        )}
                        </Paper>
                    </Grid>
                </Grid>

                {/** Map of row selected dam. */}
                <Grid item xs={7} sx={{ justifyContent: "space-between" }}>
                {cardContent ? ( 
                    <Paper sx={{ p:"5px", m:'auto', minHeight:'565px' }}>
                        <FlexBetween>
                        <Typography sx={{ fontSize: 14}} color={theme.palette.secondary[700]} gutterBottom>
                            <b>Lat/Long:</b> {cardContent.latitude}, {cardContent.longitude} <b style={{ paddingLeft:'1rem'}}>City:</b> {cardContent.city || "--"} <b style={{paddingLeft:'1rem'}}>County:</b> {cardContent.county || "--"}  
                        </Typography> 
                        </FlexBetween>
                        <FlexBetween sx={{ display:'flex', justifyContent:'center' }}>
                            <MapContainer
                            center={center}
                            zoom={zoomLevel}
                            style={{ justifyContent:'center', width: '50vw', height: '50vh', color:'grey' }}
                            >
                                <LayersControl position="topright">
                                    <LayersControl.BaseLayer checked name='Satellite'>
                                        <TileLayer
                                            url="https://api.maptiler.com/maps/satellite/{z}/{x}/{y}.jpg?key=dfQGVHkZAlv1Uf5i9EIq"
                                            attribution='<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>'
                                        />
                                    </LayersControl.BaseLayer>
                                    <LayersControl.BaseLayer name='Street Map'>
                                        <TileLayer
                                            url="https://api.maptiler.com/maps/basic-v2/{z}/{x}/{y}.png?key=dfQGVHkZAlv1Uf5i9EIq"
                                            attribution='<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>'
                                        />
                                    </LayersControl.BaseLayer>
                                    <LayersControl.BaseLayer name='Digital Elevation Model'>
                                        <TileLayer
                                            url="https://api.maptiler.com/tiles/terrain-rgb-v2/{z}/{x}/{y}.webp?key=dfQGVHkZAlv1Uf5i9EIq"
                                            attribution='<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>'
                                        />
                                    </LayersControl.BaseLayer>
                                    <LayersControl.Overlay checked name={cardContent.damName}>
                                        <Marker position={center} icon={icon}>
                                        <Popup>
                                            <b>{cardContent.damName}</b> <br /> Surface Area (Acres): {cardContent.surfaceAreaAcres} <br /> Drainage Area Sq Miles: {cardContent.drainageAreaSqMiles} <br /> Spillway Type: {cardContent.spillwayType} <br /> Spillway Width (ft): {cardContent.spillwayWidthFt} <br /> Number of Locks: {cardContent.numberofLocks} <br /> Outlet Gate Type: {cardContent.outletGateType}
                                        </Popup>
                                        </Marker>
                                    </LayersControl.Overlay>
                                    </LayersControl>
                            {/* <GeoJSON data={statesData.features} /> */}
                                <SetMapView coords={[cardContent.latitude, cardContent.longitude]}/>
                            </MapContainer>
                        </FlexBetween>
                    </Paper>
                    ) : (
                        <Paper sx={{ p:"5px", minHeight:'565px' }}>
                            <FlexBetween>
                            <Typography sx={{ fontSize: 14}} color={theme.palette.secondary[700]} gutterBottom>
                                Lat/Long: --  City: --  County: --
                            </Typography> 
                            </FlexBetween>
                            <FlexBetween sx={{ display:'flex', justifyContent:'center' }}>
                                <MapContainer
                                center={[40.63463151377654, -97.89969605983609]}
                                zoom={zoomLevel}
                                style={{ width: '50vw', height: '50vh' }}
                                >
                                    <LayersControl position="topright">
                                    <LayersControl.BaseLayer name='Satellite'>
                                        <TileLayer
                                            url="https://api.maptiler.com/maps/satellite/{z}/{x}/{y}.jpg?key=dfQGVHkZAlv1Uf5i9EIq"
                                            attribution='<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>'
                                        />
                                    </LayersControl.BaseLayer>
                                    <LayersControl.BaseLayer checked name='Street Map'>
                                        <TileLayer
                                            url="https://api.maptiler.com/maps/basic-v2/{z}/{x}/{y}.png?key=dfQGVHkZAlv1Uf5i9EIq"
                                            attribution='<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>'
                                        />
                                    </LayersControl.BaseLayer>
                                    <LayersControl.BaseLayer name='Digital Elevation Model'>
                                        <TileLayer
                                            url="https://api.maptiler.com/tiles/terrain-rgb-v2/{z}/{x}/{y}.webp?key=dfQGVHkZAlv1Uf5i9EIq"
                                            attribution='<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>'
                                        />
                                    </LayersControl.BaseLayer>
                                    </LayersControl>
                                </MapContainer>
                            </FlexBetween>
                        </Paper>
                        )}        
                </Grid>
            

            {/** DROPDOW MENUS FOR SEARCHES */}
                <Grid item xs={4} >
                    <Paper>

                  
                <FormControl fullWidth>
                    <InputLabel id="state">State</InputLabel>
                    <Select
                        labelId="state"
                        id="state"
                        value={currentUSState}
                        label="State"
                        onChange={(e) => handleStateChange(e)}
                        // onBlur={handleStateBlur}
                    >
                        {usaStatesWithCounties.map((state, index) => 
                            <MenuItem value={usaStatesWithCounties[index]}>{state.name}</MenuItem>
                        )}
                        {/* {stateList.map(state =>  
                             <MenuItem value={state.name}>{state.name}</MenuItem>
                        )} */}
                    </Select>
                    </FormControl>
                    </Paper>
                </Grid>
                <Grid item xs={4} >
                    <Paper>
                    <FormControl fullWidth>
                    <InputLabel id="county">County</InputLabel>
                        <Select
                            labelId="county"
                            id="county"
                            value={currentCounty || currentUSState?.counties[0]}
                            label="County"
                            onChange={(e) => handleCountyChange(e)}
                            // onBlur={handleStateBlur}
                        >
                        {currentUSState?.counties?.map(county =>  
                             <MenuItem value={county}>{county}</MenuItem>
                        )}
                    </Select>
                    </FormControl>
                    </Paper>
                </Grid>
                {/* <Grid item xs={4}>
                    <Paper>
                    <TextField
                label="Search..."
                sx={{ mb: "0.5rem", ml:'0.5rem', width:'98%' }}
                onChange={(e)=>setSearchInput(e.target.value)}
                value={searchInput}
                variant="standard"
                InputProps={{
                    endAdornment:  (
                        <InputAdornment position="end">
                            <IconButton
                                onClick={(searchInput)=> {
                                    setSearch(searchInput);
                                    handleSearch();
                                    // setIsChanged(true);
                                    // // console.log("FROM DATAGRID CUSTOM TOOLBAR " + searchInput);
                                    setSearchInput("");
                                }}
                            >
                                <Search />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
                    </Paper>
                </Grid> */}

                {/** DATA GRID OF ALL DAMS */}
                <Grid item xs={12} sx={{  maxWidth:window.screen.width }}>
                <Paper>
                        <Box
                        sx={{ 
                            "& .MuiDataGrid-root": {
                                border: "none"
                            },
                            "& .MuiDataGrid-cell": {
                                borderBottom: "none"
                            },
                            "& .MuiDataGrid-columnHeaders": {
                                backgroundColor: theme.palette.secondary.alt,
                                color: theme.palette.secondary[100],
                                borderBottom: "solid 2px black",
                                borderTop: "solid 2px black"
                            },
                            "& .MuiDataGrid-virtualScroller": {
                                backgroundColor: theme.palette.primary.light,
                            },
                            "& .MuiDataGrid-footerContainer": {
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.primary[100],
                                borderTop: "none"
                            },
                            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                color: `${theme.palette.secondary[200]} !important`,
                            },
                            // maxWidth: '99%',
                        }}
                        >
                        <DataGrid
                            onRowClick={handleRowClick}
                            getRowId={(row) => row._id}
                            rows={allDamData || defaultDam}
                            columns={columns}
                            rowCount={(allDamData && total) || 0}
                            // rowsPerPageOptions={[20, 50, 100]}
                            pageSizeOptions={[10, 25, 50, 100]}
                            pagination={true}
                            page={page}
                            pageSize={pageSize}
                            paginationMode='server'
                            sortingMode='server'
                            onPaginationModelChange={(newPage) => handleChange(newPage.page, newPage.pageSize, sort, search)}
                            // onPageSizeChange={(newPageSize) => handleChange(page, newPageSize, sort, search)}
                            onSortModelChange={(newSortModel) => handleChange(page, pageSize, JSON.stringify(newSortModel), search)}
                            // onSortModelChange={(newSortModel) => {console.log(newSortModel); setSort(newSortModel); getDams()}}
                            components={{ Toolbar: GridToolbar }}
                            componentProps={{
                                toolbar: { searchInput, setSearchInput, setSearch, setIsChanged }
                            }}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                    pageSize: 50,
                                    },
                                },
                                }}
                        />
                         {isChanged ? (
                            getDams(page, pageSize, sort, search, usState, currentCounty),
                            setIsChanged(false)
                        ) : ( 
                            null
                        )}
                       </Box>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
};

export default SelectDam;