import React, { useRef, useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Box, Button, Divider, FormControlLabel , Typography, useTheme, Grid, Paper, useMediaQuery } from "@mui/material";
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import QuestionnaireForm from '../../components/QuestionnaireForm';

const RMFAssessment = () => {

  const [questions, setQuestions] = useState();
  const axiosPrivate = useAxiosPrivate();
  const { assessmentID } = useParams();


  return (
    <div>Coming soon! {assessmentID}</div>
  )
}

export default RMFAssessment