import React, { useRef, useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { Box, Button, Tab, Link, Typography, Divider, Table, TableRow} from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import { useTheme, useMediaQuery, Grid, Paper } from "@mui/material";
import { TabContext, TabList, TabPanel } from '@mui/lab';
import CircleIcon from '@mui/icons-material/Circle';

import WidgetWrapper from '../../../components/WidgetWrapper';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';

import SecurityAssessment from '../SecurityAssessment';
import VulnerabilityAssessment from '../VulnerabilityAssessment';
import CyberSCADAAssessment from '../CyberSCADAAssessment';
import SecurityPlan from '../SecurityPlan';
import Procedures from '../Procedures';
import GenerateReport from '../GenerateReport';
import QuestionnaireForm from '../../../components/QuestionnaireForm';


const SecurityGroup3 = () => {
  // **valueComplete should be the value of the next tab in the Step X page or the fercAssessmentPage. 
  const valueComplete = '4';
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const [assessmentdata, setAssessmentData] = useState();
  const [currentDam, setCurrentDam] = useState({});
  const { assessmentID } = useParams();
  const [damData, setDamData] = useState(null);

  const securityGroup = "securityGroup1";

  // update this variable name.
  const [data, setData] = useState();
  const [processValue, setProcessValue] = useState('1');
  const [tabValue, setTabValue] = useState('1');

  const userRef = useRef();
const errRef = useRef();
const [errMsg, setErrMsg] = useState('');
const isNonMobile = useMediaQuery("(min-width:600px)");             // From mern_app project

const [overviewScopingQuestions, setOverviewScopingQuestions] = useState(false);
const [hasPhysicalSecurityPlan, setHasPhysicalSecurityPlan] = useState();
const [hasSecurityOperationsProcedures, setHasSecurityOperationsProcedures] = useState();
const [hasaPhysicalSecurityDescription, setHasPhysicalSecurityDescription] = useState();
const [hasKeyControlProcedures, setHasKeyControlProcedures] = useState();
const [hasThreatLevelContingencyPlanning, setHasThreatLevelContingencyPlanning] = useState();
const [hasCommunicationsProcedures, setHasCommunicationsProcedures] = useState();
const [hasInternalEmergencyResponseRapidRecoveryProcedures, setHasInternalEmergencyResponseRapidRecoveryProcedures] = useState();
const [hasSiteAccessProcedures, setHasSiteAccessProcedures] = useState();
const [hasCivilDisturbanceProcedures, setHasCivilDisturbanceProcedures] = useState();
const [hasBombThreatProcedures, setHasBombThreatProcedures] = useState();
const [hasTemporaryProjectClosureProcedures, setHasTemporaryProjectClosureProcedures] = useState();
const [hasSecurityMaintenanceTesting, setHasSecurityMaintenanceTesting] = useState();



  const handleChange = (event, newValue) => {
      setTabValue(newValue);
  }

// **GET current assessment data. 
  const getAssessment = async () => {
    try {
        const response = await axiosPrivate.get(`/assessment/${assessmentID}`, {
            withCredentials: true
        });
        const data = await response.data;
        // console.log(data);
        setAssessmentData(data);
        const damRecord = await axiosPrivate.get(`/dams/${data[0].damID}`, {
          withCredentials: true
      });
      const damRecordData = await damRecord.data;
      setCurrentDam(damRecord.data[0]);
    }
    catch (err) {
        // alert("Login Expired.");
        console.error(err);
        navigate('/login', { state: { from: location }, replace: true });
    }
  };

  // Execute these functions on load. Add functions as necessary. 
  useEffect(() => {
    // getDams();
    // getAssessment();
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps

  // **UPDATE Assessment Record 
  const handleUpdate = async () => {
    try {
        const response = await axiosPrivate.patch(`/assessment/${assessmentID}`,
        
        // Example of how to structure the inputs to be updated. This is from the SelectDam.jsx component.
        // { "damID": damID, "nidID": nidID },
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    // console.log(JSON.stringify(response.data._id));
    setTabValue(valueComplete);

    } catch (err) {
        // alert("Login Expired.");
        console.error(err);
        navigate('/login', { state: { from: location }, replace: true });
    }
  }
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    let overviewScopingQuestions = true;
    const OverviewScope = JSON.stringify({ hasPhysicalSecurityPlan, hasSecurityOperationsProcedures, hasaPhysicalSecurityDescription, hasKeyControlProcedures, hasThreatLevelContingencyPlanning, hasCommunicationsProcedures, hasInternalEmergencyResponseRapidRecoveryProcedures, hasSiteAccessProcedures, hasCivilDisturbanceProcedures, hasBombThreatProcedures, hasTemporaryProjectClosureProcedures, hasSecurityMaintenanceTesting });
    try {
        const response = await axiosPrivate.patch(`/assessment/${assessmentID}`,
    //    JSON.stringify({ scopingQuestions, hasInventory, hasFERCDesignation, hasOtherFERCProjects, hasCybersecurityPlan, cybersecurityPlanName, nERCCIPApplies, nERCCIPFindingsAddressed, hasBusinessCyberAssets, missionEssentialAssetsIdentified, userTraining }),
        JSON.stringify({ overviewScopingQuestions, OverviewScope }),
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    // setSecurityGroup(securityGroupLocal);
    getAssessment();
    } catch (err) {
       // alert("Login Expired.");
        console.error(err);
        navigate('/login', { state: { from: location }, replace: true });
    }
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }

    const GridItemQuestionXS = 6;
    const GridItemInputXS = 5;
    const GridItemInputSX = 'center';
    const inputWidth = '18px';
    const inputHeight = '18px';
    const GridItemCommentsXS = 3;
    const inputCommentWidth = '80%';
    const checkboxMarginLeft = '10%';
    const GridItemCheckboxContainerInputXS = 5;


    const SG1OverviewQuestionsList = [
        //***Example of checkbox question. */
        
        {
            //***Example of radio button question. */
        uid: 'SG1-1',
        variable: hasPhysicalSecurityPlan,
        questionNumber: '1',
        questionText: 'Do you have a site-specific Physical Security Plan or a Physical Security Plan that can be modified for this specific FERC project?',
        name: "hasPhysicalSecurityPlan",
        type: 'radio',
        answerType: 'YesNo', 
        //***Only used for question type 'checkbox'. */
        checkboxInputs: [
            {
            // answer options for checkbox
            },
            {
            // answer options for checkbox
            }
        ], 
        onChangeInput: setHasPhysicalSecurityPlan,
        //***If this question has a comments section, set 'comments: true' and complete 'idComment', 'valueComment', 'onChangeComments' */
        comments: 'false',
        // idComment: "hasPhysicalSecurityPlanComments",
        // valueComment: hasPhysicalSecurityPlanComments,
        // onChangeComments: setDoesAnnualPolicyReviewComments,
        //***Modify these settings if you want to change them from the defaults for this specific question. */
        GridItemQuestionXS: GridItemQuestionXS,
        GridItemInputXS: GridItemInputXS,
        GridItemCommentsXS: GridItemCommentsXS,
        GridItemInputSX: GridItemInputSX,
        inputStyleWidth: inputWidth,
        inputStyleHeight: inputHeight,
        inputCommentWidth: inputCommentWidth,
        checkboxMarginLeft: checkboxMarginLeft,
        GridItemCheckboxContainerInputXS: GridItemCheckboxContainerInputXS,
        }, 
        {
            uid: 'SG1-2',
            variable: hasPhysicalSecurityPlan,
            questionNumber: '2',
            questionText: 'If yes, does your Physical Security Plan include the below items?  Check all that apply.',
            name: "hasPhysicalSecurityPlan",
            type: 'checkbox',
            answerType: 'YesNo',
            //***Only used for question type 'checkbox'. */ 
            checkboxInputs: [
                {
                    variable: hasSecurityOperationsProcedures,
                    name: "hasSecurityOperationsProcedures",
                    title: "Security Operations Procedures (required)",
                    onChangeInput: setHasSecurityOperationsProcedures,
                },
                {
                    variable: hasaPhysicalSecurityDescription,
                    name: "hasaPhysicalSecurityDescription",
                    title: "Physical Security Description/Layout/Inventory (optional)",
                    onChangeInput: setHasPhysicalSecurityDescription,
                },
                {
                    variable: hasKeyControlProcedures,
                    name: "hasKeyControlProcedures",
                    title: "Key Control Procedures (required)",
                    onChangeInput: setHasKeyControlProcedures,
                },
                {
                    variable: hasThreatLevelContingencyPlanning,
                    name: "hasThreatLevelContingencyPlanning",
                    title: "Threat Level Contingency Planning (required)",
                    onChangeInput: setHasThreatLevelContingencyPlanning,
                },          
                {
                    variable: hasCommunicationsProcedures,
                    name: "hasCommunicationsProcedures",
                    title: "Communications Procedures and Redundancies (required)",
                    onChangeInput: setHasCommunicationsProcedures,
                },
                {
                    variable: hasInternalEmergencyResponseRapidRecoveryProcedures,
                    name: "hasInternalEmergencyResponseRapidRecoveryProcedures",
                    title: "Internal Emergency Response and Rapid Recovery Plan (required)",
                    onChangeInput: setHasInternalEmergencyResponseRapidRecoveryProcedures,
                },
                {
                    variable: hasSiteAccessProcedures,
                    name: "hasSiteAccessProcedures",
                    title: "Site Access Procedures (optional)",
                    onChangeInput: setHasSiteAccessProcedures,
                },          
                {
                    variable: hasCivilDisturbanceProcedures,
                    name: "hasCivilDisturbanceProcedures",
                    title: "Civil Disturbance Procedures (optional)",
                    onChangeInput: setHasCivilDisturbanceProcedures,
                },
                {
                    variable: hasBombThreatProcedures,
                    name: "hasBombThreatProcedures",
                    title: "Bomb Threat Procedures (optional)",
                    onChangeInput: setHasBombThreatProcedures,
                },
                {
                    variable: hasTemporaryProjectClosureProcedures,
                    name: "hasTemporaryProjectClosureProcedures",
                    title: "Temporary Project Closure Procedures (optional)",
                    onChangeInput: setHasTemporaryProjectClosureProcedures,
                },
                {
                    variable: hasSecurityMaintenanceTesting,
                    name: "hasSecurityMaintenanceTesting",
                    title: "Security Maintenance and Testing Procedures (optional)",
                    onChangeInput: setHasSecurityMaintenanceTesting,
                },
            ],
            //***If this question has a comments section, set 'comments: true' and complete 'idComment', 'valueComment', 'onChangeComments' */
            comments: 'false',
            // idComment: "hasPolicyComments",
            // valueComment: hasPolicyComments,
            // onChangeComments: setHasPolicyComments,
            //***Modify these settings if you want to change them from the defaults for this specific question. */
            GridItemQuestionXS: 6,  // GridItemQuestionXS,
            GridItemInputXS: 3,     // GridItemInputXS,
            GridItemCommentsXS: GridItemCommentsXS,
            GridItemInputSX: GridItemInputSX,
            inputStyleWidth: inputWidth,
            inputStyleHeight: inputHeight,
            inputCommentWidth: inputCommentWidth,
            checkboxMarginLeft: checkboxMarginLeft,
            GridItemCheckboxContainerInputXS: GridItemCheckboxContainerInputXS,
        }, 
    ]

  return (
    <Box>
      <h3>Security Group 3 Assessment</h3>
      {/* <Stepper steps={steps}/> */}
        <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 5, borderColor: 'divider'}}>
                <TabList onChange={handleChange} >
                    <Tab sx={{ border: 2 }} label='Overview' value='1' />  {/* iconPosition accepts start, bottom, top, end as values*/}
                    <Tab sx={{ border: 2 }} label='Security Assessment' value='2' />
                    <Tab sx={{ border: 2 }} label='Cyber/SCADA Inventory Evaluation' value='3' />
                    <Tab sx={{ border: 2 }} label='Security Plan' value='4' />
                </TabList>
            </Box>




         
            <TabPanel value='1'>
                 <WidgetWrapper sx={{ m: '1rem' }}>
                    <Typography>
                        A Security Group 3 Dam is defined by the level of its Consequence, Vulnerability, and Likelihood of Attack values as derived from the DAMSVR (v2) analysis. 
                    </Typography>
                    <Typography sx={{ mt: '1rem'}}>
                        Dams belonging to Security Group 3 will be inspected by FERC D2SI Engineers during scheduled Dam Safety Inspections roughly every 1 to 3 years. 
                    </Typography>
                    <Typography sx={{ mt: '1rem'}}>
                        You must maintain a cybersecuirty inventory and complete the consequence determination assessment annually for each cyber asset.
                    </Typography>
                    <Typography sx={{ mt: '1rem'}}>
                        There are <u>no</u> other security requirements for dams belonging to Security Group 3.  However, "a basic level of secuirty is recommended" by FERC, see Section 2.0 of the <Link href='https://www.ferc.gov/sites/default/files/2020-04/security.pdf'><i>Federal Energy Regulatory Commission Division of Dam Safety and Inspections FERC Security Program for Hydropower Projects Revision 3A</i></Link>, henceforth referred to as "Revision 3a". 
                    </Typography>
                    <Typography sx={{ mt: '1rem'}}>
                        FERC highly recommends a Security Assessment and Security Plan, but these are "at the discretion of the licensee".
                    </Typography>
                    <Typography sx={{ mt: '1rem'}}>
                        FERC comments about Security Group 3 Dams are expected to be minimal.
                    </Typography>
                    <Typography sx={{ mt: '1rem'}}>
                        This tool provides a standardized approach to the Secuirty Assessment, Security Plan, and annual evaluation of cyber assets. 
                    </Typography>
                 </WidgetWrapper>
                 <WidgetWrapper sx={{ m: '1rem' }}>
                    <Typography>Licensee's must complete the:</Typography>
                    <Typography><li>Cyber/SCADA Inventory Evaluation</li></Typography>
                    <Divider sx={{ mb:'1rem' }} />
                    <Typography>Licensee's may complete the:</Typography>
                    <Typography><li>Scoping Questions (on this page)</li></Typography>
                    <Typography><li>Security Assessment</li></Typography>
                    <Typography><li>Security Plan</li></Typography>
                 </WidgetWrapper>

                <WidgetWrapper sx={{ m: '1rem' }}>
                <Grid container spacing={2} columns={12}>
                    <Grid item xs={12}>
                            <section>
                                <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">
                                    {errMsg}
                                </p>
                                <h1>Scoping Questions</h1>
                                <form onSubmit={handleSubmit}>
                                <Box
                                    display="grid"
                                    alignItems="downward"
                                    gridTemplateColumns="repeat(4, minmax(1fr, 1fr))"
                                    sx={{
                                        "& > div": { gridColumn: isNonMobile ? undefined : "span 4"},
                                    }}
                                >
                                    {SG1OverviewQuestionsList.map(({
                                    uid,
                                    variable,
                                    GridItemQuestionXS,
                                    questionNumber,
                                    questionText,
                                    GridItemInputXS,
                                    GridItemInputSX,
                                    inputStyleWidth,
                                    inputStyleHeight,
                                    checkboxMarginLeft,
                                    GridItemCheckboxContainerInputXS,
                                    name,
                                    type,
                                    answerType,
                                    checkboxInputs,
                                    onChangeInput,
                                    comments,
                                    GridItemCommentsXS,
                                    inputCommentWidth,
                                    idComment,
                                    valueComment,
                                    onChangeComments
                                    }) => (
                                    <QuestionnaireForm 
                                        key={uid}
                                        variable={variable}
                                        GridItemQuestionXS={GridItemQuestionXS}
                                        questionNumber={questionNumber}
                                        questionText={questionText}
                                        GridItemInputXS={GridItemInputXS}
                                        GridItemInputSX={GridItemInputSX}
                                        inputStyleWidth={inputStyleWidth}
                                        inputStyleHeight={inputStyleHeight}
                                        checkboxMarginLeft={checkboxMarginLeft}
                                        GridItemCheckboxContainerInputXS={GridItemCheckboxContainerInputXS}
                                        name={name}
                                        type={type}
                                        answerType={answerType}
                                        checkboxInputs={checkboxInputs}
                                        onChangeInput={onChangeInput}
                                        comments={comments}
                                        GridItemCommentsXS={GridItemCommentsXS}
                                        inputCommentWidth={inputCommentWidth}
                                        idComment={idComment}
                                        valueComment={valueComment}
                                        onChangeComments={onChangeComments}
                                        />
                                    )
                                    )}
                                    
                                    <Grid item xs={12}><Divider /></Grid>
                                {/* <button disabled={( hasFERCDesignation==="" && hasInventory==="" && hasOtherFERCProjects==="" && hasCybersecurityPlan==="" && hasCybersecurityPlan!==null && nERCCIPApplies==="" && nERCCIPFindingsAddressed==="" && hasBusinessCyberAssets==="" && missionEssentialAssetsIdentified==="" )} style={buttonStyle}>Submit</button> */}
                                <Button sx={{ m: '1rem', width: '25%' }} variant="contained" onClick={handleSubmit}>Submit</Button>
                                {/* disabled={( hasFERCDesignation==="" || hasInventory==="" || hasOtherFERCProjects==="" || hasCybersecurityPlan==="" || nERCCIPApplies==="" || nERCCIPFindingsAddressed==="" || hasBusinessCyberAssets==="" || missionEssentialAssetsIdentified==="" || userTraining==="" )} */}
                                </Box>
                                </form>
                            </section>
                    </Grid>
                </Grid>
                </WidgetWrapper>
            </TabPanel>




            <Box sx={{ backgroundColor: theme.palette.background }}>
            <TabPanel value='2'>
                <SecurityAssessment securityGroup={securityGroup} />
            </TabPanel>
            <TabPanel value='3'>
                <CyberSCADAAssessment securityGroup={securityGroup} />
            </TabPanel>
            <TabPanel value='4'>
                <SecurityPlan />
            </TabPanel>
            </Box>
        </TabContext>
        </Box>
  )
}

export default SecurityGroup3