import React, { useState, useEffect } from 'react';
import { Box, Button, Collapse, Typography, useTheme, Grid, Divider, Select, MenuItem, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Paper, IconButton, Slider } from "@mui/material";
import { useParams } from 'react-router-dom';
import Header from '../../../components/Header';
import WidgetWrapper from '../../../components/WidgetWrapper';
import FERCRiskEquationLong from './images/FERCRiskEquationLong.png';
import { styled } from '@mui/material/styles';
import MuiInput from '@mui/material/Input';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import ThreatProfileBuilder2 from './ThreatProfileBuilder2';


const ThreatProfileBuilder = ({
    _id,
    assetName,
    assetDescription
}) => {

    const [userAddedThreatTemp, setUserAddedThreatTemp] = useState("");
    const [userAddedVulnTemp, setUserAddedVulnTemp] = useState("");
    const [userAddedLikelihoodTemp, setUserAddedLikelihoodTemp] = useState("");
    const [threatValue, setThreatValue] = React.useState(0);
    const [vulnValue, setVulnValue] = React.useState(0);
    const [likelihoodValue, setLikelihoodValue] = React.useState(0);
    const Input = styled(MuiInput)` width: 42px;`;
    const [allCriticalAssetData, setAllCriticalAssetData] = useState();
    const { assessmentID } = useParams();
    const axiosPrivate = useAxiosPrivate();
    const newThreatProfileID = 1;
    const [threatProfiles, setThreatProfiles] = useState();
    const [threatProfileNumber, setThreatProfileNumber] = useState(1);
    const [refresh, setRefresh] = useState(false);
    const [allThreatData, setAllThreatData] = useState();
    const [allVulnerabilityData, setAllVulnerabilityData] = useState();
  
  
    const handleAddNewThreatProfile = async () => {
        if (setUserAddedThreatTemp) {
          let criticalAssetID = _id;
          let threatName = "select threat";
          let threatValue = 1;
          let vulnerabilityName = "select vulnerability";
          let vulnerabiltiyValue = 1;
          let likelihoodValue = 1;
          let consequenceValue = 1;
          let threatProfileComments ="";
          let active = true;
    
          let threatProfileData = JSON.stringify({ threatName, threatValue, vulnerabilityName, vulnerabiltiyValue, likelihoodValue, threatProfileComments });
    
          try {
            const response = await axiosPrivate.post(`/threatprofiles/threatprofiles/new`,
              JSON.stringify({ assessmentID, criticalAssetID, threatName, threatValue, vulnerabilityName, vulnerabiltiyValue, likelihoodValue, threatProfileComments, active }),
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            } 
          );
          } catch (err) {
              console.log(err);
          }
          setRefresh(!refresh);
        }
        // setUserAddedThreatTemp("");
        // setRefresh(!refresh);
      };

      // Get Threat Profile for each Critical Asset.
    const getThreatProfiles = async () => {
            let criticalAssetID = _id;
            try {
                const response = await axiosPrivate.get(`/threatprofiles/threatprofiles/${criticalAssetID}`, {
                    withCredentials: true
                });
                const data = await response.data;
                setThreatProfiles(data);
                console.log(data);
              } catch (err) {
                // alert("Login Expired.");
                console.error(err);
                // navigate('/login', { state: { from: location }, replace: true });
              }
        };
    
        const getThreats = async () => {
            try {
              const response = await axiosPrivate.get(`/threats/threats/${assessmentID}`, {
                  withCredentials: true
              });
              const data = await response.data;
              setAllThreatData(data);
              console.log('Get Threats from ThreatProfileBUilder2 called')
              console.log(data);
            } catch (err) {
              // alert("Login Expired.");
              console.error(err);
              // navigate('/login', { state: { from: location }, replace: true });
            }
          };

           // Get Vulnerabilities Function.  This should pull vulnerabilities for the current assessment. 
        const getVulnerabilities = async () => {
            try {
            const response = await axiosPrivate.get(`/vulnerabilities/vulnerabilities/${assessmentID}`, {
                withCredentials: true
            });
            const data = await response.data;
            setAllVulnerabilityData(data);
            console.log(data);
            } catch (err) {
            // alert("Login Expired.");
            console.error(err);
            // navigate('/login', { state: { from: location }, replace: true });
            }
        };
        

        useEffect(() => {
            // getCriticalAssets();
            getThreats();
            getThreatProfiles();
            getVulnerabilities();
          }, [refresh]);  // eslint-disable-line react-hooks/exhaustive-deps
        //   if (!threatProfiles) {
        //     return null;
        //   }

    return (
    <Box>
        <Grid item xs={12}>
                <WidgetWrapper>
                    <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant='h3'>Critical Asset: {assetName}</Typography>
                        <Typography sx={{ mt:'0.5rem' }}>Description: {assetDescription}</Typography>
                        <Divider />
                    </Grid>
                    <Grid item xs ={12}>
                        <Button sx={{ minWidth:'100px', width:'10%', mr:'5%' }} variant="contained" onClick={handleAddNewThreatProfile}>Add Threat Profile</Button>
                    </Grid>
 
                        {threatProfiles?.map(
                            ({
                                _id,
                                threatName,
                                threatValue,
                                vulnerabilityName,
                                vulnerabilityValue,
                                likelihoodValue,
                                consequenceValue,
                                threatProfileComments
                            }) => (
                                <Grid item xs={4}>
                                <ThreatProfileBuilder2
                                    key={_id}
                                    _id={_id}
                                    threatName={threatName}
                                    threatValue={threatValue}
                                    vulnerabilityName={vulnerabilityName}
                                    vulnerabilityValue={vulnerabilityValue}
                                    likelihoodValue={likelihoodValue}
                                    consequenceValue={consequenceValue}
                                    threatProfileComments={threatProfileComments}
                                    refresh={refresh}
                                    setRefresh={setRefresh}
                                    allThreatData={allThreatData}
                                    allVulnerabilityData={allVulnerabilityData}
                                />
                            </Grid>
                            )
                        )}
                

                    </Grid>
                </WidgetWrapper>
            </Grid>




    </Box>
  )
}

export default ThreatProfileBuilder