import React, { useState, useEffect } from 'react';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { Box, Button, Typography, IconButton, useTheme, useMediaQuery, Link, Grid, Paper, Divider, RadioGroup, FormControlLabel, MenuItem, List, ListItem, ListItemIcon, Radio, Select, Tooltip } from "@mui/material";
import Header from "../../../components/Header";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { all } from 'axios';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';


const SecurityAssessmentResults = () => {

const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const [assessmentdata, setAssessmentData] = useState();
  const [currentDam, setCurrentDam] = useState({});
  const { assessmentID } = useParams();
  const [rows, setRows] = useState();
  const [allCriticalAssetData, setAllCriticalAssetData] = useState();
  const [footScore, setFootScore] = useState(0);
  const [vehicleScore, setVehicleScore]= useState(0);
  const [boatScore, setBoatScore] = useState(0);
  const [footLength, setFootLength] = useState(0);
  const [vehicleLength, setVehicleLength] = useState(0);
  const [boatLength, setBoatLength] = useState(0);



  const [refresh, setRefresh] = useState();

     // Get Critical Assets Function.  This should pull cyberAssets for the current assessment. 
    const getCriticalAssets = async () => {
        try {
        const response = await axiosPrivate.get(`/criticalassets/criticalassets/${assessmentID}`, {
            withCredentials: true
        });
        const data = await response.data;
        setAllCriticalAssetData(data);

        let footScore1 = 0;
        let vehicleScore1 = 0;
        let boatScore1 = 0;

        for (let index in data) {
            let tempCriticalAsset = data[index];
            let tempScoreFoot = tempCriticalAsset.externalAccess + tempCriticalAsset.physicalSecurity + tempCriticalAsset.detection + tempCriticalAsset.delay + tempCriticalAsset.response + tempCriticalAsset.responseTime + tempCriticalAsset.assessmentExternal + tempCriticalAsset.assessmentInternal + tempCriticalAsset.securityAssessment + tempCriticalAsset.securityPlan + tempCriticalAsset.cybersecurity + tempCriticalAsset.cpme + tempCriticalAsset.cdpr;
            footScore1 = footScore1 + tempScoreFoot;
            

            let tempScoreVehicle = tempCriticalAsset.externalAccessVehicle + tempCriticalAsset.physicalSecurityVehicle + tempCriticalAsset.detectionVehicle + tempCriticalAsset.delayVehicle + tempCriticalAsset.responseVehicle + tempCriticalAsset.responseTimeVehicle + tempCriticalAsset.assessmentExternalVehicle + tempCriticalAsset.assessmentInternalVehicle + tempCriticalAsset.securityAssessmentVehicle + tempCriticalAsset.securityPlanVehicle + tempCriticalAsset.cybersecurityVehicle + tempCriticalAsset.cpme + tempCriticalAsset.cdpr;
            vehicleScore1 = vehicleScore1 + tempScoreVehicle;
            
            let tempScoreBoat = tempCriticalAsset.externalAccessBoat + tempCriticalAsset.physicalSecurityBoat + tempCriticalAsset.detectionBoat + tempCriticalAsset.delayBoat + tempCriticalAsset.responseBoat + tempCriticalAsset.responseTimeBoat + tempCriticalAsset.assessmentExternalBoat + tempCriticalAsset.assessmentInternalBoat + tempCriticalAsset.securityAssessmentBoat + tempCriticalAsset.securityPlanBoat + tempCriticalAsset.cybersecurityBoat + tempCriticalAsset.cpme + tempCriticalAsset.cdpr;
            boatScore1 = boatScore1 + tempScoreBoat;
        }
        setFootScore(footScore1);
        setVehicleScore(vehicleScore1);
        setBoatScore(boatScore1);

        // console.log(data);
        } catch (err) {
        // alert("Login Expired.");
        console.error(err);
        // navigate('/login', { state: { from: location }, replace: true });
        }
    };

    useEffect(() => {
        getCriticalAssets();
    }, [footScore]);  // eslint-disable-line react-hooks/exhaustive-deps
    if (!allCriticalAssetData) {
        return null;
    };


    const getSecurityAssessmentScoresFoot = (index) => {
        const securityAssessmentScoreFoot = index.externalAccess + index.physicalSecurity + index.detection + index.delay + index.response + index.responseTime + index.assessmentExternal + index.assessmentInternal + index.securityAssessment + index.securityPlan + index.cybersecurity; // + index.cpme + index.cdpr;
        return (
            securityAssessmentScoreFoot
        )
    };

    const getSecurityAssessmentScoresVehicle = (index) => {
        try{
            const securityAssessmentScoreVehicle = index.externalAccessVehicle + index.physicalSecurityVehicle + index.detectionVehicle + index.delayVehicle + index.responseVehicle + index.responseTimeVehicle + index.assessmentExternalVehicle + index.assessmentInternalVehicle + index.securityAssessmentVehicle + index.securityPlanVehicle + index.cybersecurityVehicle; // + index.cpme + index.cdpr;
            return (
                // index.physicalSecurityVehicle
                securityAssessmentScoreVehicle
            )       
        } catch (err) {
            console.log(err);
        }       
    };

    const getSecurityAssessmentScoresBoat = (index) => {
        const securityAssessmentScoreBoat = index.externalAccessBoat + index.physicalSecurityBoat + index.detectionBoat + index.delayBoat + index.responseBoat + index.responseTimeBoat + index.assessmentExternalBoat + index.assessmentInternalBoat + index.securityAssessmentBoat + index.securityPlanBoat + index.cybersecurityBoat; // + index.cpme + index.cdpr;

        return (
            securityAssessmentScoreBoat
        )
    };


  return (
    <Box>
        <Grid container spacing={1} sx={{ border: 'solid 1px' }}>
            <Grid item xs={6} sx={{ border: 'solid 1px' }}>
                <Typography variant="h3">HYDRO PROJECT</Typography>
                <Typography variant="h3">Critical-Physical Dam</Typography>
                <Typography variant="h3">Related Assets</Typography>
            </Grid>
            <Grid item xs={2} sx={{ border: 'solid 1px' }}>
                <Typography variant="h3">Overall Score</Typography>
                <Typography variant="h3">Attacker on Foot</Typography>
            </Grid>
            <Grid item xs={2} sx={{ border: 'solid 1px' }}>
                <Typography variant="h3">Overall Score</Typography>
                <Typography variant="h3">Attacker in Vehicle</Typography>
            </Grid>
            <Grid item xs={2} sx={{ border: 'solid 1px' }}>
                <Typography variant="h3">Overall Score</Typography>
                <Typography variant="h3">Attacker in Boat</Typography>
            </Grid>

            {allCriticalAssetData?.map((index) => (
                <>
                   <Grid item xs={6}>
                        <Typography>{index.assetName}</Typography>
                    </Grid>

                <Grid item xs={2} sx={{ display:'flex', justifyContent:'flex-end', pr:'1rem' }}>
                    <Typography>{getSecurityAssessmentScoresFoot(index)}</Typography>
                    <FiberManualRecordIcon
                        sx= {{
                            color: (
                                ((getSecurityAssessmentScoresFoot(index)) >= 40 && 'green') || 
                                ((getSecurityAssessmentScoresFoot(index)) >= 33 && 'yellow') || 
                                ((getSecurityAssessmentScoresFoot(index)) >= 23 && 'orange') || 
                                ((getSecurityAssessmentScoresFoot(index)) <= 22 && 'red') )
                        }}
                    />
                </Grid>
                <Grid item xs={2} sx={{ display:'flex', justifyContent:'flex-end', pr:'1rem' }}>
                   <Typography>{getSecurityAssessmentScoresVehicle(index)}</Typography>
                   <FiberManualRecordIcon
                       sx= {{
                           color: (
                            ((getSecurityAssessmentScoresVehicle(index)) >= 40 && 'green') || 
                            ((getSecurityAssessmentScoresVehicle(index)) >= 30 && 'yellow') || 
                            ((getSecurityAssessmentScoresVehicle(index)) >= 20 && 'orange') || 
                            ((getSecurityAssessmentScoresVehicle(index)) <= 20 && 'red') )
                       }}
                   />
               </Grid>
                <Grid item xs={2} sx={{ display:'flex', justifyContent:'flex-end', pr:'1rem' }}>
                  <Typography>{getSecurityAssessmentScoresBoat(index)}</Typography>
                  <FiberManualRecordIcon
                      sx= {{
                          color: (
                            ((getSecurityAssessmentScoresBoat(index)) > 40 && 'green') || 
                            ((getSecurityAssessmentScoresBoat(index)) > 30 && 'yellow') || 
                            ((getSecurityAssessmentScoresBoat(index)) > 20 && 'orange') || 
                            ((getSecurityAssessmentScoresBoat(index)) < 20 && 'red') )

                      }}
                  />
                </Grid>
              </>
            )
            )}

        <Grid item xs={6} sx={{ border: 'solid 1px'}}>
            <Typography variant="h2">Project Score:</Typography>
        </Grid>

        <Grid item xs={2} sx={{ border: 'solid 1px', display:'flex', justifyContent:'flex-end', pr:'1rem' }}>
            {/* <Typography>{footScore}</Typography> */}
            <Typography>{(footScore / (allCriticalAssetData.length+2)).toFixed(0)}</Typography> 
            <FiberManualRecordIcon 
                sx={{ 
                    color: (
                    ((footScore / allCriticalAssetData.length) > 40 && 'green') || 
                    ((footScore / allCriticalAssetData.length) > 30 && 'yellow') || 
                    ((footScore / allCriticalAssetData.length) > 20 && 'orange') || 
                    ((footScore / allCriticalAssetData.length) < 20 && 'red') )
                }} />
        </Grid>
        <Grid item xs={2} sx={{ border: 'solid 1px', display:'flex', justifyContent:'flex-end', pr:'1rem'  }}>
            {/* <Typography>{vehicleScore}</Typography> */}
            <Typography>{(vehicleScore / (allCriticalAssetData.length+2)).toFixed(0)} </Typography> 
            <FiberManualRecordIcon 
                sx={{ 
                    color: (
                    ((vehicleScore / allCriticalAssetData.length) > 40 && 'green') || 
                    ((vehicleScore / allCriticalAssetData.length) > 30 && 'yellow') || 
                    ((vehicleScore / allCriticalAssetData.length) > 20 && 'orange') || 
                    ((vehicleScore / allCriticalAssetData.length) < 20 && 'red') )
                }} />
        </Grid>
        <Grid item xs={2} sx={{ border: 'solid 1px', display:'flex', justifyContent:'flex-end', pr:'1rem'}}>
            {/* <Typography>{boatScore}</Typography> */}
            <Typography>{(boatScore / (allCriticalAssetData.length+2)).toFixed(0)} </Typography> 
            <FiberManualRecordIcon 
                sx={{ 
                    color: (
                    ((boatScore / allCriticalAssetData.length) > 40 && 'green') || 
                    ((boatScore / allCriticalAssetData.length) > 30 && 'yellow') || 
                    ((boatScore / allCriticalAssetData.length) > 20 && 'orange') || 
                    ((boatScore / allCriticalAssetData.length) < 20 && 'red') )
                }} />
        </Grid>
        </Grid>
    </Box>
  )
}

export default SecurityAssessmentResults