import React from 'react';
import { useRef, useState, useEffect } from 'react';
import useAuth from '../hooks/useAuth';
import axios from '../api/axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { Grid } from '@mui/material';    // imported for map tutorial
import { useDispatch } from "react-redux";
import { setLogin } from "../state";
import BackgroundImage from './usa_nightimage.jpg';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import riskRegister from './riskRegister.png';
import securityAssessment from './securityAssessment.png';
import securityAssessment2 from './securityAssessment2.png';
import threatProfiles from './threatProfiles3.png';
import attackTrees from './attackTrees.png';
import cyberInventory from './cyberInventory.png';
import FlexBetween from './FlexBetween';
import apexpng from '../scenes/layoutNewAssessmentPage/apexpng.png'

// START from mern_app project
import { Box, Button, Link, Typography, useTheme, useMediaQuery } from "@mui/material";

// END from mern_app project

const LOGIN_URL = '/auth';


const Login = () => {

    const images = [
        `${securityAssessment}`,
        `${securityAssessment2}`,
        `${threatProfiles}`,
        `${riskRegister}`,
        `${cyberInventory}`,
        `${attackTrees}`
    ];

    const style = {
        container: {
            position:'relative',
            backgroundImage: `url(${BackgroundImage})`,
            backgroundSize: 'cover',
            // height:'100vh',
            // width:'100%'
        }, 
        overlay: {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            // background: '#3399ff, rgba(0, 0, 0, 0.5)'
            // 'linear-gradient(to bottom, #3399ff, rgb(0, 0, 0, 0.5))', // Adjust the color and transparency 
        }
    };


    const dispatch = useDispatch();
    const theme = useTheme();
    const { auth, setAuth, persist, setPersist } = useAuth();           // From mern_app project 
    const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");    // From mern_app project
    const isNonMobile = useMediaQuery("(min-width:600px)");             // From mern_app project

    const [mobileLogin, setMobileLogin] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";
    const userRef = useRef();
    const errRef = useRef();

    const [user, setUser] = useState('');
    const [password, setPassword] = useState('');
    const [errMsg, setErrMsg] = useState('');

    const buttonStyle = {
        'backgroundColor': '#00D5FA',
        'width': '35%',
        'marginTop': '15px',
        'padding': '10px'
    }

    const buttonStyleMobile = {
        'backgroundColor': '#00D5FA',
        'width': '50%',
        'marginTop': '15px',
        'padding': '10px'
    }
   
    useEffect(() => {
        userRef.current.focus();
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [user, password])

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('/auth',
                JSON.stringify({ user, password }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );
            const data = response;
            console.log(data);
            console.log(JSON.stringify(response));
            const accessToken = response?.data?.accessToken;
            setAuth({ accessToken });
            dispatch(
                setLogin({
                    user: user.username,
                    token: accessToken
                })
            );
            setUser('');
            setPassword('');
            navigate(from, { replace: true });
        } catch (err) {
            if (!err?.response) {
                setErrMsg('No server response.');
            } else if (err.response?.status === 400) {
                setErrMsg('Missing username or password');
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized');
            } else {
                setErrMsg('Login Failed');
            }
            errRef.current.focus();
        }
    }

    const togglePersist = () => {
        setPersist(prev => !prev);
    }

    useEffect(() => {
        localStorage.setItem("persist", persist);
    }, [persist])

  return (
    <Box style={style.container} >
        {isNonMobile ? ( 
            <>
                <Box style={{ background: 'linear-gradient(to bottom, #3399ff, rgb(0, 0, 0, 0.5))', height:'100vh' }}>
                    <Grid container spacing={2} columns={12} >
            
                    {/** LEFT SIDE OF LOGIN SCREEN */}
                    <Grid item xs={6} m="1rem" align="center" sx={{ mt:'10%' }}>
                        
                        <Grid container spacing={2}>

                            <Grid item xs={12}>
                                <Box>
                                    <Typography variant='h1' sx={{ color:'white' }}>Never Fail a FERC Audit. </Typography>
                                    <Typography variant='h3' sx={{ color:'white' }}>Everything you need for Hydropower Security.</Typography>
                                </Box>
                            </Grid>

                            <Grid item xs={12} >
                                <Box sx={{ mt:'5rem', width:'70%' }}>
                                    <Slide >
                                        <div>
                                        <div className="each-slide-effect">
                                            <div style={{ 'backgroundImage': `url(${images[0]})` }}>
                                                <span></span>
                                            </div>
                                        </div>
                                        <div style={{ backgroundColor:'white' }}>Structured Physical Security Assessment</div>
                                        </div>
                                        <div>
                                        <div className="each-slide-effect">
                                            <div style={{ 'backgroundImage': `url(${images[1]})` }}>
                                                <span></span>
                                            </div>
                                        </div>
                                        <div style={{ backgroundColor:'white', p:'5px' }}>Actionable Assessment Results</div>
                                        </div>
                                        <div>
                                        <div className="each-slide-effect">
                                            <div style={{ 'backgroundImage': `url(${images[2]})` }}>
                                                <span></span>
                                            </div>
                                        </div>
                                        <div style={{ backgroundColor:'white' }}>Create Threat Profiles for each Critical Asset</div>
                                        </div>
                                        <div>
                                        <div className="each-slide-effect">
                                            <div style={{ 'backgroundImage': `url(${images[3]})` }}>
                                                <span></span>
                                            </div>
                                            
                                        </div>
                                        <div style={{ backgroundColor:'white' }}>Quantitative Relative Risk Score</div>
                                        </div>
                                        <div>
                                        <div className="each-slide-effect">
                                            <div style={{ 'backgroundImage': `url(${images[4]})` }}>
                                                <span></span>
                                            </div>
                                            
                                        </div>
                                        <div style={{ backgroundColor:'white' }}>Cybersecurity Inventory and Assessment Tool</div>
                                        </div>
                                        <div>
                                        <div className="each-slide-effect">
                                            <div style={{ 'backgroundImage': `url(${images[5]})` }}>
                                                <span></span>
                                            </div>
                                            
                                        </div>
                                        <div style={{ backgroundColor:'white' }}>Build Cybersecurity Attack Trees</div>
                                        </div>
                                    </Slide>
                                    <Typography color="white" variant='h3'>We've automated <i>FERC's Security Program for Hydropower Projects Revision 3A </i></Typography>
                                </Box>
                            </Grid>
                        </Grid>

                    </Grid>

                    {/** RIGHT SIDE OF LOGIN SCREEN */}
                    <Grid item xs={5} sx={{ mt:'10%' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Box
                                p="2rem"
                                m="2rem auto"
                                borderRadius="1.5rem"
                                backgroundColor={theme.palette.background.alt}
                                sx={{
                                    boxShadow: '5px 5px 10px 10px black', 
                                    width: '50%',
                                    minWidth: '300px'
                                }}
                                >
                                {/* <Typography fontWeight="500" variant="h5" sx={{mb: "1.5rem"}}>
                                    Welcome to WEBPAGE NAME!
                                </Typography> */}
                                    <section>
                                        <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">
                                            {errMsg}
                                        </p>
                                        <h1>Sign In</h1>
                                        <form onSubmit={handleSubmit}>
                                        <Box
                                        >
                                            <label htmlFor="username">Username</label>
                                            <input 
                                                type="text" 
                                                id="username"
                                                ref={userRef}
                                                autoComplete="off"
                                                onChange={(e) => setUser(e.target.value)}
                                                value={user}
                                                required 
                                                style={{ width: '99%', margin:'auto' }}
                                            />
                                            <label style={{ marginTop:"1rem" }} htmlFor="password">Password</label>
                                            <input 
                                                type="password" 
                                                id="password"
                                                onChange={(e) => setPassword(e.target.value)}
                                                value={password}
                                                required 
                                                style={{ width: '99%', margin:'auto' }}
                                            />
                                            <button style={buttonStyle}>Sign In</button>
                                            <div className="persistCheck">
                                                <input 
                                                    type="checkbox"
                                                    id="persist"
                                                    onChange={togglePersist}
                                                    checked={persist}
                                                />
                                                <label htmlFor="persist">Remember this device.</label>
                                                <span className="line" style={{ marginLeft:'3rem' }} >
                                                {/*put router link here*/}
                                                <a href="/loginhelp">Need help logging in?</a>
                                            </span>
                                            </div>
                                            </Box>
                                        </form>
                                    
                                        <p>
                                            not registered?<br />
                                            <span className="line">
                                                {/*put router link here*/}
                                                <a href="/register">Sign Up</a>
                                            </span>
                                        </p>
                                    </section>
                                </Box>
                                <Typography sx={{ display:'flex', justifyContent:'center', color:'white' }}>We'll never share your information.</Typography>
                                <Typography sx={{ display:'flex', justifyContent:'center', color:'white' }}>Your data is dual-encrypted and processed in a SOC2 Type II Compliant environment. </Typography>
                            </Grid>
                        </Grid>
                        
                    </Grid>

                </Grid>
            </Box>
        </>
            
        ) : (
            <Box style={{ background: 'linear-gradient(to bottom, #3399ff, rgb(0, 0, 0, 0.5))' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Box >
                                <Typography variant='h2' sx={{ color:'white', textAlign:'center' }}>Never Fail a FERC Audit. </Typography>
                                <Typography variant='h3' sx={{ color:'white', textAlign:'center' }}>Everything you need for Hydropower Security.</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} >
                            <Box sx={{ width:'95%' }} ref={userRef}>
                                <Slide >
                                    <div>
                                    <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">
                                        {errMsg}
                                    </p>
                                    <div className="each-slide-effect">
                                        <div style={{ 'backgroundImage': `url(${images[0]})` }}>
                                            <span></span>
                                        </div>
                                    </div>
                                    <div style={{ backgroundColor:'white' }}>Structured Physical Security Assessment</div>
                                    </div>
                                    <div>
                                    <div className="each-slide-effect">
                                        <div style={{ 'backgroundImage': `url(${images[1]})` }}>
                                            <span></span>
                                        </div>
                                    </div>
                                    <div style={{ backgroundColor:'white', p:'5px' }}>Actionable Assessment Results</div>
                                    </div>
                                    <div>
                                    <div className="each-slide-effect">
                                        <div style={{ 'backgroundImage': `url(${images[2]})` }}>
                                            <span></span>
                                        </div>
                                    </div>
                                    <div style={{ backgroundColor:'white' }}>Create Threat Profiles for each Critical Asset</div>
                                    </div>
                                    <div>
                                    <div className="each-slide-effect">
                                        <div style={{ 'backgroundImage': `url(${images[3]})` }}>
                                            <span></span>
                                        </div>
                                        
                                    </div>
                                    <div style={{ backgroundColor:'white' }}>Quantitative Relative Risk Score</div>
                                    </div>
                                    <div>
                                    <div className="each-slide-effect">
                                        <div style={{ 'backgroundImage': `url(${images[4]})` }}>
                                            <span></span>
                                        </div>
                                        
                                    </div>
                                    <div style={{ backgroundColor:'white' }}>Cybersecurity Inventory and Assessment Tool</div>
                                    </div>
                                    <div>
                                    <div className="each-slide-effect">
                                        <div style={{ 'backgroundImage': `url(${images[5]})` }}>
                                            <span></span>
                                        </div>
                                        
                                    </div>
                                    <div style={{ backgroundColor:'white' }}>Build Cybersecurity Attack Trees</div>
                                    </div>
                                </Slide>
                                {/* <button style={buttonStyleMobile} onClick={setMobileLogin(!mobileLogin)}>Sign In</button> */}
                            </Box>
                        </Grid>
                        <Grid item xs={12} >
                        <Box
                        // width="50%"
                        p="2rem"
                        m="auto"
                        borderRadius="1.5rem"
                        backgroundColor={theme.palette.background.alt}
                        sx={{
                            boxShadow: '5px 5px 10px 10px black', 
                            width: '50%',
                            minWidth: '300px'
                        }}
                        >
                        {/* <Typography fontWeight="500" variant="h5" sx={{mb: "1.5rem"}}>
                            Welcome to WEBPAGE NAME!
                        </Typography> */}
                            <section>
                                <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">
                                    {errMsg}
                                </p>
                                <h1>Sign In</h1>
                                <form onSubmit={handleSubmit}>
                                <Box>
                                    <label htmlFor="username">Username</label>
                                    <input 
                                        type="text" 
                                        id="username"
                                        ref={userRef}
                                        autoComplete="off"
                                        onChange={(e) => setUser(e.target.value)}
                                        value={user}
                                        required 
                                        style={{ width: '99%', margin:'auto' }}
                                    />
                                    <label style={{ marginTop:"1rem" }} htmlFor="password">Password</label>
                                    <input 
                                        type="password" 
                                        id="password"
                                        onChange={(e) => setPassword(e.target.value)}
                                        value={password}
                                        required 
                                        style={{ width: '99%', margin:'auto' }}
                                    />
                                    <button style={buttonStyleMobile}>Sign In</button>
                                    <div className="persistCheck">
                                        <input 
                                            type="checkbox"
                                            id="persist"
                                            onChange={togglePersist}
                                            checked={persist}
                                        />
                                        <label htmlFor="persist">Remember this device.</label>
                                        <span className="line" style={{ marginLeft:'3rem' }} >
                                        {/*put router link here*/}
                                        <a href="/loginhelp">Need help logging in?</a>
                                    </span>
                                    </div>
                                    </Box>
                                </form>
                            
                                <p>
                                    not registered?<br />
                                    <span className="line">
                                        {/*put router link here*/}
                                        <a href="/register">Sign Up</a>
                                    </span>
                                </p>
                            </section>
                        </Box>
                        <Typography sx={{ color:'white', textAlign:'center' }}>We'll never share your information.</Typography>
                    </Grid>
                    </Grid>
            </Box>
        )}
    </Box>
  )
}

export default Login