import React, { useState, useEffect, useRef } from 'react';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { AppBar, Box, Button, Tab, Tabs, Typography, Table, TableRow, Link, Checkbox } from '@mui/material';
import { useTheme, useMediaQuery, Grid, Paper, Divider } from "@mui/material";
import Header from "../../../components/Header";
import { TabContext, TabList, TabPanel } from '@mui/lab';
import SecurityAssessment from '../SecurityAssessment';
import CyberSCADAAssessment from '../CyberSCADAAssessment';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Procedures from '../Procedures';
import SecurityPlan from '../SecurityPlan';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import WidgetWrapper from '../../../components/WidgetWrapper';
import QuestionnaireForm from '../../../components/QuestionnaireForm';


const SecurityGroup2 = () => {
  // **valueComplete should be the value of the next tab in the Step X page or the fercAssessmentPage. 
  const valueComplete = '4';
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const [assessmentdata, setAssessmentData] = useState();
  const [currentDam, setCurrentDam] = useState({});
  const { assessmentID } = useParams();
  const [damData, setDamData] = useState(null);

  const securityGroup = "securityGroup2";

  // update this variable name.
  const [data, setData] = useState();
  const [processValue, setProcessValue] = useState('1');
  const [tabValue, setTabValue] = useState('1');

  const userRef = useRef();
    const errRef = useRef();
    const [errMsg, setErrMsg] = useState('');
    const isNonMobile = useMediaQuery("(min-width:600px)");             // From mern_app project


    const [overviewScopingQuestions, setOverviewScopingQuestions] = useState(false);
    const [hasPhysicalSecurityPlan, setHasPhysicalSecurityPlan] = useState();
    const [hasSecurityOperationsProcedures, setHasSecurityOperationsProcedures] = useState();
    const [hasaPhysicalSecurityDescription, setHasPhysicalSecurityDescription] = useState();
    const [hasKeyControlProcedures, setHasKeyControlProcedures] = useState();
    const [hasThreatLevelContingencyPlanning, setHasThreatLevelContingencyPlanning] = useState();
    const [hasCommunicationsProcedures, setHasCommunicationsProcedures] = useState();
    const [hasInternalEmergencyResponseRapidRecoveryProcedures, setHasInternalEmergencyResponseRapidRecoveryProcedures] = useState();
    const [hasSiteAccessProcedures, setHasSiteAccessProcedures] = useState();
    const [hasCivilDisturbanceProcedures, setHasCivilDisturbanceProcedures] = useState();
    const [hasBombThreatProcedures, setHasBombThreatProcedures] = useState();
    const [hasTemporaryProjectClosureProcedures, setHasTemporaryProjectClosureProcedures] = useState();
    const [hasSecurityMaintenanceTesting, setHasSecurityMaintenanceTesting] = useState();

  const handleChange = (event, newValue) => {
      setTabValue(newValue);
  }

// **GET current assessment data. 
  const getAssessment = async () => {
    try {
        const response = await axiosPrivate.get(`/assessment/${assessmentID}`, {
            withCredentials: true
        });
        const data = await response.data;
        console.log(data);
        setAssessmentData(data);
        const damRecord = await axiosPrivate.get(`/dams/${data[0].damID}`, {
          withCredentials: true
      });
      const damRecordData = await damRecord.data;
      setCurrentDam(damRecord.data[0]);
    }
    catch (err) {
        // alert("Login Expired.");
        console.error(err);
        navigate('/login', { state: { from: location }, replace: true });
    }
  };

  // Execute these functions on load. Add functions as necessary. 
  useEffect(() => {
    // getDams();
    // getAssessment();
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps

  // **UPDATE Assessment Record 
  const handleUpdate = async () => {
    try {
        const response = await axiosPrivate.patch(`/assessment/${assessmentID}`,
        
        // Example of how to structure the inputs to be updated. This is from the SelectDam.jsx component.
        // { "damID": damID, "nidID": nidID },
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    // console.log(JSON.stringify(response.data._id));
    setTabValue(valueComplete);

    } catch (err) {
        if (!err?.response) {
            console.log('No Server Response');
        } else {
            console.log('Action Failed')
        }
    }
  }

//   const steps =['Security Assessment', 'Cyber/SCADA Assessment', 'Security PLan', 'step 4'];



  const handleSubmit = async (e) => {
    e.preventDefault();
    let overviewScopingQuestions = true;
    const OverviewScope = JSON.stringify({ hasPhysicalSecurityPlan, hasSecurityOperationsProcedures, hasaPhysicalSecurityDescription, hasKeyControlProcedures, hasThreatLevelContingencyPlanning, hasCommunicationsProcedures, hasInternalEmergencyResponseRapidRecoveryProcedures, hasSiteAccessProcedures, hasCivilDisturbanceProcedures, hasBombThreatProcedures, hasTemporaryProjectClosureProcedures, hasSecurityMaintenanceTesting });
    try {
        const response = await axiosPrivate.patch(`/assessment/${assessmentID}`,
    //    JSON.stringify({ scopingQuestions, hasInventory, hasFERCDesignation, hasOtherFERCProjects, hasCybersecurityPlan, cybersecurityPlanName, nERCCIPApplies, nERCCIPFindingsAddressed, hasBusinessCyberAssets, missionEssentialAssetsIdentified, userTraining }),
        JSON.stringify({ overviewScopingQuestions, OverviewScope }),
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    // setSecurityGroup(securityGroupLocal);
    getAssessment();
    } catch (err) {
       // alert("Login Expired.");
        console.error(err);
        navigate('/login', { state: { from: location }, replace: true });
    }
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }

    const GridItemQuestionXS = 6;
    const GridItemInputXS = 5;
    const GridItemInputSX = 'center';
    const inputWidth = '18px';
    const inputHeight = '18px';
    const GridItemCommentsXS = 3;
    const inputCommentWidth = '80%';
    const checkboxMarginLeft = '10%';
    const GridItemCheckboxContainerInputXS = 5;


    const SG1OverviewQuestionsList = [
        //***Example of checkbox question. */
        
        {
            //***Example of radio button question. */
        uid: 'SG1-1',
        variable: hasPhysicalSecurityPlan,
        questionNumber: '1',
        questionText: 'Do you have a site-specific Physical Security Plan or a Physical Security Plan that can be modified for this specific FERC project?',
        name: "hasPhysicalSecurityPlan",
        type: 'radio',
        answerType: 'YesNo', 
        //***Only used for question type 'checkbox'. */
        checkboxInputs: [
            {
            // answer options for checkbox
            },
            {
            // answer options for checkbox
            }
        ], 
        onChangeInput: setHasPhysicalSecurityPlan,
        //***If this question has a comments section, set 'comments: true' and complete 'idComment', 'valueComment', 'onChangeComments' */
        comments: 'false',
        // idComment: "hasPhysicalSecurityPlanComments",
        // valueComment: hasPhysicalSecurityPlanComments,
        // onChangeComments: setDoesAnnualPolicyReviewComments,
        //***Modify these settings if you want to change them from the defaults for this specific question. */
        GridItemQuestionXS: GridItemQuestionXS,
        GridItemInputXS: GridItemInputXS,
        GridItemCommentsXS: GridItemCommentsXS,
        GridItemInputSX: GridItemInputSX,
        inputStyleWidth: inputWidth,
        inputStyleHeight: inputHeight,
        inputCommentWidth: inputCommentWidth,
        checkboxMarginLeft: checkboxMarginLeft,
        GridItemCheckboxContainerInputXS: GridItemCheckboxContainerInputXS,
        }, 
        {
            uid: 'SG1-2',
            variable: hasPhysicalSecurityPlan,
            questionNumber: '2',
            questionText: 'If yes, does your Physical Security Plan include the below items?  Check all that apply.',
            name: "hasPhysicalSecurityPlan",
            type: 'checkbox',
            answerType: 'YesNo',
            //***Only used for question type 'checkbox'. */ 
            checkboxInputs: [
                {
                    variable: hasSecurityOperationsProcedures,
                    name: "hasSecurityOperationsProcedures",
                    title: "Security Operations Procedures (required)",
                    onChangeInput: setHasSecurityOperationsProcedures,
                },
                {
                    variable: hasaPhysicalSecurityDescription,
                    name: "hasaPhysicalSecurityDescription",
                    title: "Physical Security Description/Layout/Inventory (optional)",
                    onChangeInput: setHasPhysicalSecurityDescription,
                },
                {
                    variable: hasKeyControlProcedures,
                    name: "hasKeyControlProcedures",
                    title: "Key Control Procedures (required)",
                    onChangeInput: setHasKeyControlProcedures,
                },
                {
                    variable: hasThreatLevelContingencyPlanning,
                    name: "hasThreatLevelContingencyPlanning",
                    title: "Threat Level Contingency Planning (required)",
                    onChangeInput: setHasThreatLevelContingencyPlanning,
                },          
                {
                    variable: hasCommunicationsProcedures,
                    name: "hasCommunicationsProcedures",
                    title: "Communications Procedures and Redundancies (required)",
                    onChangeInput: setHasCommunicationsProcedures,
                },
                {
                    variable: hasInternalEmergencyResponseRapidRecoveryProcedures,
                    name: "hasInternalEmergencyResponseRapidRecoveryProcedures",
                    title: "Internal Emergency Response and Rapid Recovery Plan (required)",
                    onChangeInput: setHasInternalEmergencyResponseRapidRecoveryProcedures,
                },
                {
                    variable: hasSiteAccessProcedures,
                    name: "hasSiteAccessProcedures",
                    title: "Site Access Procedures (optional)",
                    onChangeInput: setHasSiteAccessProcedures,
                },          
                {
                    variable: hasCivilDisturbanceProcedures,
                    name: "hasCivilDisturbanceProcedures",
                    title: "Civil Disturbance Procedures (optional)",
                    onChangeInput: setHasCivilDisturbanceProcedures,
                },
                {
                    variable: hasBombThreatProcedures,
                    name: "hasBombThreatProcedures",
                    title: "Bomb Threat Procedures (optional)",
                    onChangeInput: setHasBombThreatProcedures,
                },
                {
                    variable: hasTemporaryProjectClosureProcedures,
                    name: "hasTemporaryProjectClosureProcedures",
                    title: "Temporary Project Closure Procedures (optional)",
                    onChangeInput: setHasTemporaryProjectClosureProcedures,
                },
                {
                    variable: hasSecurityMaintenanceTesting,
                    name: "hasSecurityMaintenanceTesting",
                    title: "Security Maintenance and Testing Procedures (optional)",
                    onChangeInput: setHasSecurityMaintenanceTesting,
                },
            ],
            //***If this question has a comments section, set 'comments: true' and complete 'idComment', 'valueComment', 'onChangeComments' */
            comments: 'false',
            // idComment: "hasPolicyComments",
            // valueComment: hasPolicyComments,
            // onChangeComments: setHasPolicyComments,
            //***Modify these settings if you want to change them from the defaults for this specific question. */
            GridItemQuestionXS: 6,  // GridItemQuestionXS,
            GridItemInputXS: 3,     // GridItemInputXS,
            GridItemCommentsXS: GridItemCommentsXS,
            GridItemInputSX: GridItemInputSX,
            inputStyleWidth: inputWidth,
            inputStyleHeight: inputHeight,
            inputCommentWidth: inputCommentWidth,
            checkboxMarginLeft: checkboxMarginLeft,
            GridItemCheckboxContainerInputXS: GridItemCheckboxContainerInputXS,
        }, 
    ]




  return (
    <Box>
      <h3>Security Group 2 Assessment</h3>
      {/* <Stepper steps={steps}/> */}
        <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 5, borderColor: 'divider'}}>
                <TabList onChange={handleChange} >
                        <Tab sx={{ border: 2 }} label='Overview' value='1' />  {/* iconPosition accepts start, bottom, top, end as values*/}
                        <Tab sx={{ border: 2 }} label='Security Assessment' value='2' />
                        <Tab sx={{ border: 2 }} label='Cyber/SCADA Assesment' value='3' />
                        {/* <Tab sx={{ border: 2 }} label='Procedures' value='4' /> */}
                        <Tab sx={{ border: 2 }} label='Security Plan' value='4' />
                        {/* <Tab sx={{ border: 2 }} label='Optional Procedures' value='5' /> */}
                </TabList>
            </Box>
            <TabPanel value='1'>
                 <WidgetWrapper sx={{ m: '1rem' }}>
                    <Typography>
                        A Security Group 2 Dam is defined by the level of its Consequence, Vulnerability, and Likelihood of Attack values as derived from the DAMSVR (v2) analysis. 
                    </Typography>
                    <Typography sx={{ mt: '1rem'}}>
                        Dams belonging to Security Group 2 will be inspected by FERC D2SI.
                    </Typography>
                    <Typography sx={{ mt: '1rem'}}>
                        The output of this tool is a Site Security Plan that meets the requirements defined in the <Link href='https://www.ferc.gov/sites/default/files/2020-04/security.pdf'><i>Federal Energy Regulatory Commission Division of Dam Safety and Inspections FERC Security Program for Hydropower Projects Revision 3A</i>, henceforth referred to as "Revision 3a". </Link>
                    </Typography>
                    <Typography sx={{ mt: '1rem'}}>
                        The Site Security Plan is a collection of reports and plans, including:
                        <li>Security Assessment, described in Chapters 6 of Revision 3A.</li>
                        <li>Cyber/SCADA Risk Assessment, based on the requirements in Chapter 9 of Revision 3A.</li>
                        <li>Cyber Security Policies, based on the requirements defined in Table 9.3a and Table 9.3b Revision 3A.</li>
                        <li>Cyber Securtiy System Security Plan (SSP), based on guidance provided the <i>National Institute of Standards and Technology (NIST) Special Publication (SP) 800-18r1 <Link href='https://nvlpubs.nist.gov/nistpubs/Legacy/SP/nistspecialpublication800-18r1.pdf'>Guide for Developing Security Plans for Federal Information Systems</Link></i></li>
                        <li>Physical Security Plan, described in Chapter 7 of Revision 3A.</li>
                    </Typography>
                 </WidgetWrapper>
                 <WidgetWrapper sx={{ m: '1rem' }}>
                    <Typography>To meet FERC's Security Group 2 Requirements you must complete the scoping questions on this page and the tabbed sections above:</Typography>
                    <Typography><li>Scoping Questions (on this page)</li></Typography>
                    <Typography><li>Security Assessment</li></Typography>
                    <Typography><li>Cyber/SCADA Assessment</li></Typography>
                    <Typography><li>Security Plan</li></Typography>
                    
                 </WidgetWrapper>
                 <WidgetWrapper sx={{ m: '1rem' }}>
                    <Typography>There may be some recommendations based on the Scoping Questions. You <u>must</u> evaluate each recommendation, and address them in the Site Security Plan.  Ignoring these recommendations may result in an audit finding by FERC.</Typography>
                    <Typography></Typography>
                </WidgetWrapper>
                <WidgetWrapper sx={{ m: '1rem' }}>
                <Grid container spacing={2} columns={12}>
            <Grid item xs={12}>
                    <section>
                        <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">
                            {errMsg}
                        </p>
                        <h1>Scoping Questions UPDATE THESE!!!!!!!!!!S</h1>
                        <form onSubmit={handleSubmit}>
                        <Box
                            display="grid"
                            alignItems="downward"
                            gridTemplateColumns="repeat(4, minmax(1fr, 1fr))"
                            sx={{
                                "& > div": { gridColumn: isNonMobile ? undefined : "span 4"},
                            }}
                        >
                            {SG1OverviewQuestionsList.map(({
                            uid,
                            variable,
                            GridItemQuestionXS,
                            questionNumber,
                            questionText,
                            GridItemInputXS,
                            GridItemInputSX,
                            inputStyleWidth,
                            inputStyleHeight,
                            checkboxMarginLeft,
                            GridItemCheckboxContainerInputXS,
                            name,
                            type,
                            answerType,
                            checkboxInputs,
                            onChangeInput,
                            comments,
                            GridItemCommentsXS,
                            inputCommentWidth,
                            idComment,
                            valueComment,
                            onChangeComments
                            }) => (
                            <QuestionnaireForm 
                                key={uid}
                                variable={variable}
                                GridItemQuestionXS={GridItemQuestionXS}
                                questionNumber={questionNumber}
                                questionText={questionText}
                                GridItemInputXS={GridItemInputXS}
                                GridItemInputSX={GridItemInputSX}
                                inputStyleWidth={inputStyleWidth}
                                inputStyleHeight={inputStyleHeight}
                                checkboxMarginLeft={checkboxMarginLeft}
                                GridItemCheckboxContainerInputXS={GridItemCheckboxContainerInputXS}
                                name={name}
                                type={type}
                                answerType={answerType}
                                checkboxInputs={checkboxInputs}
                                onChangeInput={onChangeInput}
                                comments={comments}
                                GridItemCommentsXS={GridItemCommentsXS}
                                inputCommentWidth={inputCommentWidth}
                                idComment={idComment}
                                valueComment={valueComment}
                                onChangeComments={onChangeComments}
                                />
                            )
                            )}
                              
                            <Grid item xs={12}><Divider /></Grid>
                        {/* <button disabled={( hasFERCDesignation==="" && hasInventory==="" && hasOtherFERCProjects==="" && hasCybersecurityPlan==="" && hasCybersecurityPlan!==null && nERCCIPApplies==="" && nERCCIPFindingsAddressed==="" && hasBusinessCyberAssets==="" && missionEssentialAssetsIdentified==="" )} style={buttonStyle}>Submit</button> */}
                        <Button sx={{ m: '1rem', width: '25%' }} variant="contained" onClick={handleSubmit}>Submit</Button>
                        {/* disabled={( hasFERCDesignation==="" || hasInventory==="" || hasOtherFERCProjects==="" || hasCybersecurityPlan==="" || nERCCIPApplies==="" || nERCCIPFindingsAddressed==="" || hasBusinessCyberAssets==="" || missionEssentialAssetsIdentified==="" || userTraining==="" )} */}
                        </Box>
                        </form>
                    </section>
            </Grid>
        </Grid>
                </WidgetWrapper>






















                <Paper sx={{ p: '1rem' }}>
                    <Box>
                        <Typography>
                            Security Group 2 Overview. 
                        </Typography>

                    </Box>

                </Paper>
                <Grid item xs={6}>
                        <WidgetWrapper>
                            <Paper sx={{ p: '1rem' }}>
                        <Typography sx={{ mt: '0.5rem', mr: '2rem'}}>
                            <b>1. Security Assessment</b> - A Security Assessment (SA) is a formal document and is an abbreviated evaluation of the 
security procedures and features at the dam without fully accounting for attack Consequences, 
Vulnerabilities, or Threat. Hence, although the VA fully evaluates the four factors discussed in 
section 5.0, above (C, V, T, and S), the SA - as the name implies - only addresses an evaluation 
of Security.
                        </Typography>
                        <Typography sx={{mt: '1rem' }}>
                        A Security Assessment must be completed for all Security Group 2 Dams, and is an 
integral part of the VA of a Security Group 1 Dam. The Security Assessment (SA) is an 
evaluation of the current state and appropriateness of the on-site security system/procedures and 
what needs to be done at a project or facility to address concerns regarding security, such as 
installation of fences, gates, cameras, increased guards, etc. This assessment identifies if any 
security enhancements are needed, and specifically what those enhancements consist of. The SA 
must also address the state of maintenance and readiness of the existing security 
systems/procedures. The recommendations made from the Security Assessment lead to improved 
security measures and are to be incorporated into the Security Plan
                        </Typography>
                        </Paper>
                            <TableContainer >
                                <Table size="small">
                                    <TableBody>
                                        <TableRow>
                                            {/* <TableRow>
                                                <Checkbox onChange={handleChange} />
                                            </TableRow> */}
                                            <TableCell >
                                                Document 
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>
                                                Required
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>
                                                Suggested
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell >
                                                Security Assessment 
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>
                                                X
                                            </TableCell>
                                            <TableCell></TableCell>
                                            
                                        </TableRow>
                                        <TableRow>
                                        <TableCell >
                                                Discusses Scope/Methodology/Assumptions
                                            </TableCell>
                                            <TableCell></TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>
                                                X
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                        <TableCell sx={{ mr:'0.5rem' }}>
                                            Discusses List of Critical Assets
                                            </TableCell>
                                            <TableCell>

                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>
                                                X
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                        <TableCell sx={{ mr:'0.5rem' }}>
                                                Inherent Accessibility & Visibility
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>
                                                
                                            </TableCell>
                                            <TableCell >
                                                X
                                            </TableCell>
                                        </TableRow>
                                        <TableRow sx={{ textAlign: 'center'}}>
                                        <TableCell sx={{ mr:'0.5rem' }}>
                                            Security Protection of Individual Assets
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>
                                                X
                                            </TableCell>
                                            <TableCell >
                                                
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                        <TableCell sx={{ mr:'0.5rem' }}>
                                                Discusses Unarmed Intruder Scenario
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>
                                                X
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>
                                                
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                        <TableCell sx={{ mr:'0.5rem' }}>
                                                List of Security Recommendations
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>
                                                X
                                            </TableCell>
                                            <TableCell >
                                                
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                        <TableCell sx={{ mr:'0.5rem' }}>
                                                Security Recommendations, if any
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>
                                                X
                                            </TableCell>
                                            <TableCell >
                                                
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                        <TableCell sx={{ mr:'0.5rem' }}>
                                                Planned and Recently Completed Security Upgrades
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>
                                                X
                                            </TableCell>
                                            <TableCell >
                                                
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            </WidgetWrapper>
                        
                        <WidgetWrapper sx={{ mt: '1rem' }}>
                                <Paper sx={{ mb: '0.5rem', p: '1rem' }}>
                                    <Typography sx={{ mt:'0.5rem', mr:'2rem'}}>
                                        <b>2. Cyber/SCADA Assessment</b> - THe Cyber/SCADA Assessment is required for all dams. This assessment includes identifying the criticality of cyber assets and the cybersecurity plan for the site. 
                                    </Typography>
                                </Paper>
                                <Typography>
                                    ADD TABLE OF CYBER / SCADA ASSESSMENT INFO. 
                                </Typography>
                            </WidgetWrapper>

                        </Grid>
                        <Grid item xs={6}>
                            <WidgetWrapper sx={{ mt: '1rem' }}>
                                <Paper sx={{ p:'1rem' }}>
                                <Typography sx={{ mt:'0.5rem', mr: '2rem'}}>
                            <b>3. Security Plan</b> - A Security Plan (SP) is a formal document (or set of documents) and constitutes the 
“Standard Operating Procedures” for the operation of all security concerns (physical, cyber, and 
procedural) at the dam and the project. Security Plans must be <i>site-specific</i> and avaialble to the dam operator at the site. The one exception to the on-site requirement is 
for un-staffed dams operated at a central facility.
                        </Typography>
                                </Paper>
                            <TableContainer >
                                <Table size="small">
                                    <TableBody>
                                        <TableRow>
                                            {/* <TableRow>
                                                <Checkbox onChange={handleChange} />
                                            </TableRow> */}
                                            <TableCell >
                                                Document 
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>
                                                Required
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>
                                                Suggested
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell >
                                                Security Plan
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>
                                                X
                                            </TableCell>
                                            <TableCell></TableCell>
                                            
                                        </TableRow>
                                        <TableRow>
                                        <TableCell >
                                                List of Restricted Areas
                                            </TableCell>
                                            <TableCell></TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>
                                                X
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                        <TableCell >
                                            List of Critical Dam Assets
                                            </TableCell>
                                            <TableCell>

                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>
                                                X
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                        <TableCell >
                                                Physical Security Descriptions/Inventory
                                            </TableCell>
                                            <TableCell>

                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>
                                                X
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                        <TableCell >
                                            Physical Security Layout/Drawings
                                            </TableCell>
                                            <TableCell>

                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>
                                                X
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                        <TableCell >
                                                Security Operations Procedures
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>
                                                X
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>
                                                
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                        <TableCell >
                                                Site Access Procedures
                                            </TableCell>
                                            <TableCell>

                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>
                                                X
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                        <TableCell >
                                                Key Control Procedures
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>
                                                X
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>
                                                
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                        <TableCell >
                                                Civil Disturbance Procedures
                                            </TableCell>
                                            <TableCell>

                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>
                                                X
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                        <TableCell >
                                                Bomb Threat Procedures
                                            </TableCell>
                                            <TableCell>

                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>
                                                X
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                        <TableCell >
                                                Temporary Project Closure Procedures
                                            </TableCell>
                                            <TableCell>

                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>
                                                X
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                        <TableCell >
                                                Threat Level Planning
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>
                                                X
                                            </TableCell>
                                            <TableCell >
                                                
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                        <TableCell >
                                                Communication Procedures
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>
                                                X
                                            </TableCell>
                                            <TableCell >
                                                
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                        <TableCell >
                                                Information Technology (if applicable)
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>
                                                X
                                            </TableCell>
                                            <TableCell >
                                                
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                        <TableCell >
                                                Cyber/SCADA 
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>
                                                X
                                            </TableCell>
                                            <TableCell >
                                                
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                        <TableCell >
                                            Security Equipment Testing
                                            </TableCell>
                                            <TableCell >
                                                
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>
                                                X
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                        <TableCell >
                                                Internal Emergency Response
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>
                                                X
                                            </TableCell>
                                            <TableCell >
                                                
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                        <TableCell >
                                                All upgrades to physical and operational security
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>
                                                X
                                            </TableCell>
                                            <TableCell >
                                                
                                            </TableCell>
                                        </TableRow>

                                    </TableBody>
                                </Table>
                            </TableContainer>
                            </WidgetWrapper>
                        </Grid>

            </TabPanel>





            <Box sx={{ backgroundColor: theme.palette.background }}>
            <TabPanel value='2'>
                <SecurityAssessment securityGroup={securityGroup} />
            </TabPanel>
            <TabPanel value='3'>
                <CyberSCADAAssessment setProcessValue={setProcessValue} setTabValue={setTabValue} assessmentID={assessmentID} />
            </TabPanel>
            <TabPanel value='4'>
                <SecurityPlan />
            </TabPanel>
            {/* <TabPanel value='5'>
                <Procedures />  
            </TabPanel> */}
                {/* <TabPanel value='6'>Nothing Here</TabPanel> */}
            </Box>
        </TabContext>
        </Box>
  )
}

export default SecurityGroup2
