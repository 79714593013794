import React, { useState } from 'react';
import {Box, useMediaQuery } from "@mui/material";
import { Outlet } from "react-router-dom";
import Navbar from '../layoutNewAssessmentPage/navbardashboard';
import Sidebar from "./sidebarHomePage";
import useAuth from "../../hooks/useAuth";
import { jwtDecode } from "jwt-decode";


const LayoutHomePage = () => {
  const isNonMobile = useMediaQuery("(min-width: 600px)");
  const [ isSidebarOpen, setIsSidebarOpen ] = useState(true);

  const { auth } = useAuth();
  const decoded = auth?.accessToken
  ? jwtDecode(auth.accessToken)
  : undefined;
  const userInfo = decoded?.UserInfo;

  const username = decoded?.UserInfo?.username || []
  
  return (
    <Box display={isNonMobile ? "flex" : "block"} width="100%" height="100%">
       <Box >
        <Sidebar
          // isNonMobile={isNonMobile}
          drawerWidth="300px"
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
          />
       </Box>
       
      <Box sx={{ m: '0.5rem' }} flexGrow={1}>
        <Navbar
          username={username || {}}
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
          />
          <Outlet />
      </Box>
    </Box>
  );
  
};

export default LayoutHomePage;