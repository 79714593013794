import React, { useRef, useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Box, Button, Divider, FormControlLabel , Typography, useTheme, Grid, Paper, useMediaQuery } from "@mui/material";
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import QuestionnaireForm from '../../components/QuestionnaireForm';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import ListMapFunc from './listMapFunc';
import Header from '../../components/Header';
import WidgetWrapper from '../../components/WidgetWrapper';


const ZTAAssessment = () => {

  const [questions, setQuestions] = useState();
  const axiosPrivate = useAxiosPrivate();
  const { assessmentID } = useParams();
  const [assessmentData, setAssessmentData] = useState();
  
  const navigate = useNavigate();
  const location = useLocation();
  const userRef = useRef();
  const errRef = useRef();
  const [errMsg, setErrMsg] = useState('');
  const theme = useTheme();        
  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");    // From mern_app project
  const isNonMobile = useMediaQuery("(min-width:600px)");             // From mern_app project

  const questionsIdentityList = [];
  const questionsDevicesList = [];
  const questionsApplicationsWorkloadsList = [];
  const questionsNetworksList = [];
  const questionsDataList = [];

// ZTA Identity Question variables.
  const [id1, setID1] = useState();
  const [id2, setID2] = useState();
  const [id3, setID3] = useState();
  const [id4, setID4] = useState();
  const [id5, setID5] = useState();
  const [id6, setID6] = useState();
  const [id7, setID7] = useState();
  const [id1Comments, setID1Comments] = useState();
  const [id2Comments, setID2Comments] = useState();
  const [id3Comments, setID3Comments] = useState();
  const [id4Comments, setID4Comments] = useState();
  const [id5Comments, setID5Comments] = useState();
  const [id6Comments, setID6Comments] = useState();
  const [id7Comments, setID7Comments] = useState();

  // ZTA Devices Question Variables.
  const [dv1, setDV1] = useState();
  const [dv2, setDV2] = useState();
  const [dv3, setDV3] = useState();
  const [dv4, setDV4] = useState();
  const [dv5, setDV5] = useState();
  const [dv6, setDV6] = useState();
  const [dv7, setDV7] = useState();
  const [dv1Comments, setDV1Comments] = useState();
  const [dv2Comments, setDV2Comments] = useState();
  const [dv3Comments, setDV3Comments] = useState();
  const [dv4Comments, setDV4Comments] = useState();
  const [dv5Comments, setDV5Comments] = useState();
  const [dv6Comments, setDV6Comments] = useState();
  const [dv7Comments, setDV7Comments] = useState();

  // ZTA Networks Question Variables.
  const [nt1Comments, setNT1Comments] = useState();
  const [nt2Comments, setNT2Comments] = useState();
  const [nt3Comments, setNT3Comments] = useState();
  const [nt4Comments, setNT4Comments] = useState();
  const [nt5Comments, setNT5Comments] = useState();
  const [nt6Comments, setNT6Comments] = useState();
  const [nt7Comments, setNT7Comments] = useState();
  const [nt1, setNT1] = useState();
  const [nt2, setNT2] = useState();
  const [nt3, setNT3] = useState();
  const [nt4, setNT4] = useState();
  const [nt5, setNT5] = useState();
  const [nt6, setNT6] = useState();
  const [nt7, setNT7] = useState();

  // ZTA Applications & Workloads Question Variables.
  const [aw1, setAW1] = useState();
  const [aw2, setAW2] = useState();
  const [aw3, setAW3] = useState();
  const [aw4, setAW4] = useState();
  const [aw5, setAW5] = useState();
  const [aw6, setAW6] = useState();
  const [aw7, setAW7] = useState();
  const [aw8, setAW8] = useState();
  const [aw1Comments, setAW1Comments] = useState();
  const [aw2Comments, setAW2Comments] = useState();
  const [aw3Comments, setAW3Comments] = useState();
  const [aw4Comments, setAW4Comments] = useState();
  const [aw5Comments, setAW5Comments] = useState();
  const [aw6Comments, setAW6Comments] = useState();
  const [aw7Comments, setAW7Comments] = useState();
  const [aw8Comments, setAW8Comments] = useState();

  // ZTA Applications & Workloads Question Variables.
  const [da1Comments, setDA1Comments] = useState();
  const [da2Comments, setDA2Comments] = useState();
  const [da3Comments, setDA3Comments] = useState();
  const [da4Comments, setDA4Comments] = useState();
  const [da5Comments, setDA5Comments] = useState();
  const [da6Comments, setDA6Comments] = useState();
  const [da7Comments, setDA7Comments] = useState();
  const [da8Comments, setDA8Comments] = useState();
  const [da1, setDA1] = useState();
  const [da2, setDA2] = useState();
  const [da3, setDA3] = useState();
  const [da4, setDA4] = useState();
  const [da5, setDA5] = useState();
  const [da6, setDA6] = useState();
  const [da7, setDA7] = useState();
  const [da8, setDA8] = useState();

  const variablesList = [ 
    id1,	id2,  id3,	id4,	id5,	id6,	id7,
    dv1,	dv2,	dv3,	dv4,	dv5,	dv6,	dv7,	
    nt1,	nt2,	nt3,	nt4,	nt5,	nt6,	nt7,	
    aw1,	aw2,	aw3,	aw4,	aw5,	aw6,	aw7,	aw8,	
    da1,	da2,	da3,	da4,	da5,	da6,	da7,	da8,
  ];

  const variablesCommentsList = [
    id1Comments,	id2Comments,  id3Comments,	id4Comments,	id5Comments,	id6Comments,	id7Comments,	
    dv1Comments,	dv2Comments,	dv3Comments,	dv4Comments,	dv5Comments,	dv6Comments,	dv7Comments,	
    nt1Comments,	nt2Comments,	nt3Comments,	nt4Comments,	nt5Comments,	nt6Comments,	nt7Comments,	
    aw1Comments,	aw2Comments,	aw3Comments,	aw4Comments,	aw5Comments,	aw6Comments,	aw7Comments,	aw8Comments,	
    da1Comments,	da2Comments,	da3Comments,	da4Comments,	da5Comments,	da6Comments,	da7Comments,	da8Comments,
  ];

  const setVariableList = [
    setID1,	setID2,	setID3,	setID4,	setID5,	setID6,	setID7,	
    setDV1,	setDV2,	setDV3,	setDV4,	setDV5,	setDV6,	setDV7,	
    setNT1,	setNT2,	setNT3,	setNT4,	setNT5,	setNT6,	setNT7,	
    setAW1,	setAW2,	setAW3,	setAW4,	setAW5,	setAW6,	setAW7,	setAW8,	
    setDA1,	setDA2,	setDA3,	setDA4,	setDA5,	setDA6,	setDA7,	setDA8,
  ];

  const setVariableCommentsList = [
    setID1Comments,	setID2Comments,	setID3Comments,	setID4Comments,	setID5Comments,	setID6Comments,	setID7Comments,	
    setDV1Comments,	setDV2Comments,	setDV3Comments,	setDV4Comments,	setDV5Comments,	setDV6Comments,	setDV7Comments,	
    setNT1Comments,	setNT2Comments,	setNT3Comments,	setNT4Comments,	setNT5Comments,	setNT6Comments,	setNT7Comments,	
    setAW1Comments,	setAW2Comments,	setAW3Comments,	setAW4Comments,	setAW5Comments,	setAW6Comments,	setAW7Comments,	setAW8Comments,	
    setDA1Comments,	setDA2Comments,	setDA3Comments,	setDA4Comments,	setDA5Comments,	setDA6Comments,	setDA7Comments,	setDA8Comments,
  ];


  const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState({});
    
    const steps = ['Identity', 'Devices', 'Applications & Workloads', 'Networks', 'Data' ];
  
    const totalSteps = () => {
      return steps.length;
    };
  
    const completedSteps = () => {
      return Object.keys(completed).length;
    };
  
    const isLastStep = () => {
      return activeStep === totalSteps() - 1;
    };
  
    const allStepsCompleted = () => {
      return completedSteps() === totalSteps();
    };
  
    const handleNext = () => {
      const newActiveStep =
        isLastStep() && !allStepsCompleted()
          ? // It's the last step, but not all steps have been completed,
            // find the first step that has been completed
            steps.findIndex((step, i) => !(i in completed))
          : activeStep + 1;
      setActiveStep(newActiveStep);
    };
  
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
  
    const handleStep = (step) => () => {
      setActiveStep(step);
    };
  
    const handleComplete = () => {
      const newCompleted = completed;
      newCompleted[activeStep] = true;
      setCompleted(newCompleted);
      handleNext();
    };
  
    const handleReset = () => {
      setActiveStep(0);
      setCompleted({});
    };


  // Get assessmnet data Function.  This should pull data for the assessment for the current assessment. 
  const getZTAMMQuestions = async () => {
  try {
    const response = await axiosPrivate.get(`/zta`, {
        withCredentials: true
    });
    const data = await response.data;
    console.log(data);
    setQuestions(data);

    // data.forEach((item, index) => {
    //   questionsList.push(remap(item));
    // });
      

    if (questions === null) {
      return;
    }

  } catch (err) {
    // alert("Login Expired. access control");
    console.error(err);
    // navigate('/login', { state: { from: location }, replace: true });
  }
    
};

// Get assessmnet data Function.  This should pull data for the assessment for the current assessment. 
const getAssessmentData = async () => {
  try {
      const response = await axiosPrivate.get(`/zta/${assessmentID}`, {
          withCredentials: true
      });
      const data = await response.data;
      console.log(JSON.parse(data.ztaAssessmentIdentityData));
      // setGovernanceQuestions(data.governanceQuestions);
      setAssessmentData(JSON.parse(data.ztaAssessmentIdentityData));

      setID1(JSON.parse(data.ztaAssessmentIdentityData).id1);
      setID2(JSON.parse(data.ztaAssessmentIdentityData).id2);
      setID3(JSON.parse(data.ztaAssessmentIdentityData).id3);
      setID4(JSON.parse(data.ztaAssessmentIdentityData).id4);
      setID5(JSON.parse(data.ztaAssessmentIdentityData).id5);
      setID6(JSON.parse(data.ztaAssessmentIdentityData).id6);
      setID7(JSON.parse(data.ztaAssessmentIdentityData).id7);
      setID1Comments(JSON.parse(data.ztaAssessmentIdentityData).id1Comments)
      setID2Comments(JSON.parse(data.ztaAssessmentIdentityData).id2Comments);
      setID3Comments(JSON.parse(data.ztaAssessmentIdentityData).id3Comments);
      setID4Comments(JSON.parse(data.ztaAssessmentIdentityData).id4Comments);
      setID5Comments(JSON.parse(data.ztaAssessmentIdentityData).id5Comments);
      setID6Comments(JSON.parse(data.ztaAssessmentIdentityData).id6Comments);
      setID7Comments(JSON.parse(data.ztaAssessmentIdentityData).id7Comments);

      setDV1(JSON.parse(data.ztaAssessmentIdentityData).dv1);
      setDV2(JSON.parse(data.ztaAssessmentIdentityData).dv2);
      setDV3(JSON.parse(data.ztaAssessmentIdentityData).dv3);
      setDV4(JSON.parse(data.ztaAssessmentIdentityData).dv4);
      setDV5(JSON.parse(data.ztaAssessmentIdentityData).dv5);
      setDV6(JSON.parse(data.ztaAssessmentIdentityData).dv6);
      setDV7(JSON.parse(data.ztaAssessmentIdentityData).dv7);
      setDV1Comments(JSON.parse(data.ztaAssessmentIdentityData).dv1Comments)
      setDV2Comments(JSON.parse(data.ztaAssessmentIdentityData).dv2Comments);
      setDV3Comments(JSON.parse(data.ztaAssessmentIdentityData).dv3Comments);
      setDV4Comments(JSON.parse(data.ztaAssessmentIdentityData).dv4Comments);
      setDV5Comments(JSON.parse(data.ztaAssessmentIdentityData).dv5Comments);
      setDV6Comments(JSON.parse(data.ztaAssessmentIdentityData).dv6Comments);
      setDV7Comments(JSON.parse(data.ztaAssessmentIdentityData).dv7Comments);

      setAW1(JSON.parse(data.ztaAssessmentIdentityData).aw1);
      setAW2(JSON.parse(data.ztaAssessmentIdentityData).aw2);
      setAW3(JSON.parse(data.ztaAssessmentIdentityData).aw3);
      setAW4(JSON.parse(data.ztaAssessmentIdentityData).aw4);
      setAW5(JSON.parse(data.ztaAssessmentIdentityData).aw5);
      setAW6(JSON.parse(data.ztaAssessmentIdentityData).aw6);
      setAW7(JSON.parse(data.ztaAssessmentIdentityData).aw7);
      setAW1Comments(JSON.parse(data.ztaAssessmentIdentityData).aw1Comments)
      setAW2Comments(JSON.parse(data.ztaAssessmentIdentityData).aw2Comments);
      setAW3Comments(JSON.parse(data.ztaAssessmentIdentityData).aw3Comments);
      setAW4Comments(JSON.parse(data.ztaAssessmentIdentityData).aw4Comments);
      setAW5Comments(JSON.parse(data.ztaAssessmentIdentityData).aw5Comments);
      setAW6Comments(JSON.parse(data.ztaAssessmentIdentityData).aw6Comments);
      setAW7Comments(JSON.parse(data.ztaAssessmentIdentityData).aw7Comments);

      setNT1(JSON.parse(data.ztaAssessmentIdentityData).nt1);
      setNT2(JSON.parse(data.ztaAssessmentIdentityData).nt2);
      setNT3(JSON.parse(data.ztaAssessmentIdentityData).nt3);
      setNT4(JSON.parse(data.ztaAssessmentIdentityData).nt4);
      setNT5(JSON.parse(data.ztaAssessmentIdentityData).nt5);
      setNT6(JSON.parse(data.ztaAssessmentIdentityData).nt6);
      setNT7(JSON.parse(data.ztaAssessmentIdentityData).nt7);
      setNT1Comments(JSON.parse(data.ztaAssessmentIdentityData).nt1Comments)
      setNT2Comments(JSON.parse(data.ztaAssessmentIdentityData).nt2Comments);
      setNT3Comments(JSON.parse(data.ztaAssessmentIdentityData).nt3Comments);
      setNT4Comments(JSON.parse(data.ztaAssessmentIdentityData).nt4Comments);
      setNT5Comments(JSON.parse(data.ztaAssessmentIdentityData).nt5Comments);
      setNT6Comments(JSON.parse(data.ztaAssessmentIdentityData).nt6Comments);
      setNT7Comments(JSON.parse(data.ztaAssessmentIdentityData).nt7Comments);

      setDA1(JSON.parse(data.ztaAssessmentIdentityData).da1);
      setDA2(JSON.parse(data.ztaAssessmentIdentityData).da2);
      setDA3(JSON.parse(data.ztaAssessmentIdentityData).da3);
      setDA4(JSON.parse(data.ztaAssessmentIdentityData).da4);
      setDA5(JSON.parse(data.ztaAssessmentIdentityData).da5);
      setDA6(JSON.parse(data.ztaAssessmentIdentityData).da6);
      setDA7(JSON.parse(data.ztaAssessmentIdentityData).da7);
      setDA1Comments(JSON.parse(data.ztaAssessmentIdentityData).da1Comments)
      setDA2Comments(JSON.parse(data.ztaAssessmentIdentityData).da2Comments);
      setDA3Comments(JSON.parse(data.ztaAssessmentIdentityData).da3Comments);
      setDA4Comments(JSON.parse(data.ztaAssessmentIdentityData).da4Comments);
      setDA5Comments(JSON.parse(data.ztaAssessmentIdentityData).da5Comments);
      setDA6Comments(JSON.parse(data.ztaAssessmentIdentityData).da6Comments);
      setDA7Comments(JSON.parse(data.ztaAssessmentIdentityData).da7Comments);
  } catch (err) {
      // alert("Login Expirsed.");
      console.error(err);
      // navigate('/login', { state: { from: location }, replace: true });
  }
}
  
  // if (assessmentData === null) {
  //     return;
  // }

useEffect(() => {
  getZTAMMQuestions();
  getAssessmentData();
}, []);  // eslint-disable-line react-hooks/exhaustive-deps
if (!questions) {
  return null;
}

const GridItemQuestionXS = 12;
const GridItemInputXS = 11;
const GridItemInputSX = 'center';
const inputWidth = '18px';
const inputHeight = '18px';
const GridItemCommentsXS = 12;
const inputCommentWidth = '80%';
const checkboxMarginLeft = '90%';
const GridItemCheckboxContainerInputXS = 12;


for (let i=0; i<Object.keys(questions).length; i++) {
  let tempArray = {};
  tempArray.uid = questions[i].ID;
  tempArray.variable = variablesList[i];
  tempArray.questionNumber = questions[i].ID;
  tempArray.questionText = questions[i].Function;
  tempArray.name = questions[i].ID;
  tempArray.type = 'radiogroup';
  tempArray.radiogroupInputs = [ 
    {
      variable: variablesList[i],
      name: questions[i].ID,
      value: "Traditional",
      title: questions[i].Traditional,
      onChangeInput: setVariableList[i]
    },
    {
      variable: variablesList[i],
      name: questions[i].ID,
      value: "Initial",
      title: questions[i].Initial,
      onChangeInput: setVariableList[i]
    },
    {
      variable: variablesList[i],
      name: questions[i].ID,
      value: "Advanced",
      title: questions[i].Advanced,
      onChangeInput: setVariableList[i]
    },
    {
      variable: variablesList[i],
      name: questions[i].ID,
      value: "Optimal",
      title: questions[i].Optimal,
      onChangeInput: setVariableList[i]
    },
    {
      variable: variablesList[i],
      name: questions[i].ID,
      value: "None",
      title: "None",
      onChangeInput: setVariableList[i]
    }
    
  ];
  tempArray.onChangeInput = setVariableList[i];
  tempArray.comments = "true";
  tempArray.idComment = "setVariableCommentsList";
  tempArray.valueComment = variablesCommentsList[i];
  tempArray.onChangeComments = setVariableCommentsList[i];
  //***Modify these settings if you want to change them from the defaults for this specific question. */
  tempArray.GridItemQuestionXS = GridItemQuestionXS;
  tempArray.GridItemInputXS = GridItemInputXS;
  tempArray.GridItemCommentsXS = GridItemCommentsXS;
  tempArray.GridItemInputSX = GridItemInputSX;
  tempArray.inputStyleWidth = inputWidth;
  tempArray.inputStyleHeight = inputHeight;
  tempArray.inputCommentWidth = inputCommentWidth;
  tempArray.checkboxMarginLeft = checkboxMarginLeft;
  tempArray.GridItemCheckboxContainerInputXS = GridItemCheckboxContainerInputXS;

  if (questions[i].Pillar==="Identity") {
    questionsIdentityList.push(tempArray);
  };

  if (questions[i].Pillar==="Devices") {
    questionsDevicesList.push(tempArray);
  };

  if (questions[i].Pillar==="Applications and Workloads") {
    questionsApplicationsWorkloadsList.push(tempArray);
  };

  if (questions[i].Pillar==="Networks ") {
    questionsNetworksList.push(tempArray);
  };

  if (questions[i].Pillar==="Data ") {
    questionsDataList.push(tempArray);
  };
}


// *************** Update to use Questionnaire specific Variables***************//
const handleSubmit = async (e) => {
  e.preventDefault();
  const ztaAssessmentIdentityData = JSON.stringify({ 
    id1,	id2,  id3,	id4,	id5,	id6,	id7, id1Comments,	id2Comments,  id3Comments,	id4Comments,	id5Comments,	id6Comments,	id7Comments, 
    dv1,	dv2,	dv3,	dv4,	dv5,	dv6,	dv7, dv1Comments,	dv2Comments,	dv3Comments,	dv4Comments,	dv5Comments,	dv6Comments,	dv7Comments,
    nt1,	nt2,	nt3,	nt4,	nt5,	nt6,	nt7, nt1Comments,	nt2Comments,	nt3Comments,	nt4Comments,	nt5Comments,	nt6Comments,	nt7Comments,
    aw1,	aw2,	aw3,	aw4,	aw5,	aw6,	aw7,	aw8, aw1Comments,	aw2Comments,	aw3Comments,	aw4Comments,	aw5Comments,	aw6Comments,	aw7Comments,	aw8Comments,	
    da1,	da2,	da3,	da4,	da5,	da6,	da7,	da8, da1Comments,	da2Comments,	da3Comments,	da4Comments,	da5Comments,	da6Comments,	da7Comments,	da8Comments,
  });


  try {
      const response = await axiosPrivate.patch(`/zta/${assessmentID}`,
      JSON.stringify({ ztaAssessmentIdentityData }),
      {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
      }
  );
  // questionFamily(1) activates the Access Control questions. 
  handleNext();
  } catch (err) {
      if (!err?.response) {
          console.log('No Server Response');
      } else {
          console.log('Action Failed')
      }
  }
}


console.log(id1);

  return (
    // <div>ZTMM Assessment Page {assessmentID}</div>
    <Box
      display="grid"
      alignItems="downward"
      gridTemplateColumns="repeat(4, minmax(1fr, 1fr))"
      sx={{
        p: '1em',
          "& > div": { gridColumn: isNonMobile ? undefined : "span 4"},
      }}
    >
      <Box sx={{ width: '100%' }}>
        <Stepper nonLinear activeStep={activeStep}>
            {steps.map((label, index) => (
            <Step key={label} completed={completed[index]}>
                <StepButton color="inherit" onClick={handleStep(index)}>
                {label}
                </StepButton>
            </Step>
            ))}
        </Stepper>
       
        <div>
            {allStepsCompleted() ? (
            <React.Fragment>
                <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you&apos;re finished
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button onClick={handleReset}>Reset</Button>
                </Box>
            </React.Fragment>
            ) : (
            <React.Fragment>
                {/* <Typography sx={{ mt: 0, mb: 0, py: 1 }}>
                Step {activeStep + 1} */}
                {/* </Typography> */}
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, mb: 1 }}>
                <Button
                    variant='contained'
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                >
                    Back
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button variant='contained' onClick={handleNext} sx={{ mr: 1 }}>
                    Next
                </Button>
                {activeStep !== steps.length &&
                    (completed[activeStep] ? (
                    <Typography variant="caption" sx={{ display: 'inline-block' }}>
                        Step {activeStep + 1} already completed
                    </Typography>
                    ) : (
                      <></>
                    // <Button onClick={handleComplete}>
                    //     {completedSteps() === totalSteps() - 1
                    //     ? 'Finish'
                    //     : 'Complete Step'}
                    // </Button>
                    ))}
                </Box>
            </React.Fragment>
            )}
            <Divider />
        </div>
        <Box>
          <WidgetWrapper sx={{ m:'2rem'}}>
            {activeStep===0 && (
              <>
              {/* {questionsIdentityList.map(({
                uid,
                variable,
                GridItemQuestionXS,
                questionNumber,
                questionText,
                GridItemInputXS,
                GridItemInputSX,
                inputStyleWidth,
                inputStyleHeight,
                checkboxMarginLeft,
                GridItemCheckboxContainerInputXS,
                name,
                type,
                answerType,
                checkboxInputs,
                radiogroupInputs,
                onChangeInput,
                comments,
                GridItemCommentsXS,
                inputCommentWidth,
                idComment,
                valueComment,
                onChangeComments
              }) => (
                <QuestionnaireForm 
                  key={uid}
                  variable={variable}
                  GridItemQuestionXS={GridItemQuestionXS}
                  questionNumber={questionNumber}
                  questionText={questionText}
                  GridItemInputXS={GridItemInputXS}
                  GridItemInputSX={GridItemInputSX}
                  inputStyleWidth={inputStyleWidth}
                  inputStyleHeight={inputStyleHeight}
                  checkboxMarginLeft={checkboxMarginLeft}
                  GridItemCheckboxContainerInputXS={GridItemCheckboxContainerInputXS}
                  name={name}
                  type={type}
                  answerType={answerType}
                  checkboxInputs={checkboxInputs}
                  radiogroupInputs={radiogroupInputs}
                  onChangeInput={onChangeInput}
                  comments={comments}
                  GridItemCommentsXS={GridItemCommentsXS}
                  inputCommentWidth={inputCommentWidth}
                  idComment={idComment}
                  valueComment={valueComment}
                  onChangeComments={onChangeComments}
                  />
                )
                )} */}
                 {/* <button disabled={( knowsAssetCriticality==="" || doesRemoveConfigs==="" )} style={buttonStyle}>Submit</button> */}
                 <Header title="Zero Trust Pillar: IDENTITY" subtitle={questions[0].Description} />
                 {/* <Divider sx={{ marginTop: '1rem' }} /> */}
                 <Box sx={{ border: 'solid 1px', p: '1em' }}>
                 <ListMapFunc questionsList={questionsIdentityList} />
                 <Button sx={{ m: '1rem', width: '25%' }} variant="contained" onClick={handleSubmit}>Submit</Button>
                 </Box>
                 </>
             )}
            {activeStep===1 && (
                <>
                {/* {questionsDevicesList.map(({
                  uid,
                  variable,
                  GridItemQuestionXS,
                  questionNumber,
                  questionText,
                  GridItemInputXS,
                  GridItemInputSX,
                  inputStyleWidth,
                  inputStyleHeight,
                  checkboxMarginLeft,
                  GridItemCheckboxContainerInputXS,
                  name,
                  type,
                  answerType,
                  checkboxInputs,
                  radiogroupInputs,
                  onChangeInput,
                  comments,
                  GridItemCommentsXS,
                  inputCommentWidth,
                  idComment,
                  valueComment,
                  onChangeComments
                }) => (
                  <QuestionnaireForm 
                    key={uid}
                    variable={variable}
                    GridItemQuestionXS={GridItemQuestionXS}
                    questionNumber={questionNumber}
                    questionText={questionText}
                    GridItemInputXS={GridItemInputXS}
                    GridItemInputSX={GridItemInputSX}
                    inputStyleWidth={inputStyleWidth}
                    inputStyleHeight={inputStyleHeight}
                    checkboxMarginLeft={checkboxMarginLeft}
                    GridItemCheckboxContainerInputXS={GridItemCheckboxContainerInputXS}
                    name={name}
                    type={type}
                    answerType={answerType}
                    checkboxInputs={checkboxInputs}
                    radiogroupInputs={radiogroupInputs}
                    onChangeInput={onChangeInput}
                    comments={comments}
                    GridItemCommentsXS={GridItemCommentsXS}
                    inputCommentWidth={inputCommentWidth}
                    idComment={idComment}
                    valueComment={valueComment}
                    onChangeComments={onChangeComments}
                    />
                  )
                  )} */}
                  <Header title="Zero Trust Pillar: DEVICES" subtitle={questions[8].Description} />
                 {/* <Divider sx={{ marginTop: '1rem' }} /> */}
                 <Box sx={{ border: 'solid 1px', p: '1em' }}>
                  <ListMapFunc questionsList={questionsDevicesList} />
                   {/* <button disabled={( knowsAssetCriticality==="" || doesRemoveConfigs==="" )} style={buttonStyle}>Submit</button> */}
                 <Button sx={{ m: '1rem', width: '25%' }} variant="contained" onClick={handleSubmit}>Submit</Button>
                 </Box>
                 </>
            )}
            {activeStep===2 && (
                <>
                {/* {questionsApplicationsWorkloadsList.map(({
                  uid,
                variable,
                GridItemQuestionXS,
                questionNumber,
                questionText,
                GridItemInputXS,
                GridItemInputSX,
                inputStyleWidth,
                inputStyleHeight,
                checkboxMarginLeft,
                GridItemCheckboxContainerInputXS,
                name,
                type,
                answerType,
                checkboxInputs,
                radiogroupInputs,
                onChangeInput,
                comments,
                GridItemCommentsXS,
                inputCommentWidth,
                idComment,
                valueComment,
                onChangeComments
              }) => (
                <QuestionnaireForm 
                  key={uid}
                  variable={variable}
                  GridItemQuestionXS={GridItemQuestionXS}
                  questionNumber={questionNumber}
                  questionText={questionText}
                  GridItemInputXS={GridItemInputXS}
                  GridItemInputSX={GridItemInputSX}
                  inputStyleWidth={inputStyleWidth}
                  inputStyleHeight={inputStyleHeight}
                  checkboxMarginLeft={checkboxMarginLeft}
                  GridItemCheckboxContainerInputXS={GridItemCheckboxContainerInputXS}
                  name={name}
                  type={type}
                  answerType={answerType}
                  checkboxInputs={checkboxInputs}
                  radiogroupInputs={radiogroupInputs}
                  onChangeInput={onChangeInput}
                  comments={comments}
                  GridItemCommentsXS={GridItemCommentsXS}
                  inputCommentWidth={inputCommentWidth}
                  idComment={idComment}
                  valueComment={valueComment}
                  onChangeComments={onChangeComments}
                  />
                  )
                  )} */}
                   {/* <button disabled={( knowsAssetCriticality==="" || doesRemoveConfigs==="" )} style={buttonStyle}>Submit</button> */}
                   <Header title="Zero Trust Pillar: APPLICATIONS & WORKLOADS" subtitle={questions[22].Description} />
                 <Box sx={{ border: 'solid 1px', p: '1em' }}>
                   <ListMapFunc questionsList={questionsApplicationsWorkloadsList} />
                 <Button sx={{ m: '1rem', width: '25%' }} variant="contained" onClick={handleSubmit}>Submit</Button>
                 </Box>
                 </>
            )}
            {activeStep===3 && (
                <>
                {/* {questionsNetworksList.map(({
                  uid,
                variable,
                GridItemQuestionXS,
                questionNumber,
                questionText,
                GridItemInputXS,
                GridItemInputSX,
                inputStyleWidth,
                inputStyleHeight,
                checkboxMarginLeft,
                GridItemCheckboxContainerInputXS,
                name,
                type,
                answerType,
                checkboxInputs,
                radiogroupInputs,
                onChangeInput,
                comments,
                GridItemCommentsXS,
                inputCommentWidth,
                idComment,
                valueComment,
                onChangeComments
              }) => (
                <QuestionnaireForm 
                  key={uid}
                  variable={variable}
                  GridItemQuestionXS={GridItemQuestionXS}
                  questionNumber={questionNumber}
                  questionText={questionText}
                  GridItemInputXS={GridItemInputXS}
                  GridItemInputSX={GridItemInputSX}
                  inputStyleWidth={inputStyleWidth}
                  inputStyleHeight={inputStyleHeight}
                  checkboxMarginLeft={checkboxMarginLeft}
                  GridItemCheckboxContainerInputXS={GridItemCheckboxContainerInputXS}
                  name={name}
                  type={type}
                  answerType={answerType}
                  checkboxInputs={checkboxInputs}
                  radiogroupInputs={radiogroupInputs}
                  onChangeInput={onChangeInput}
                  comments={comments}
                  GridItemCommentsXS={GridItemCommentsXS}
                  inputCommentWidth={inputCommentWidth}
                  idComment={idComment}
                  valueComment={valueComment}
                  onChangeComments={onChangeComments}
                  />
                  )
                  )} */}
                   {/* <button disabled={( knowsAssetCriticality==="" || doesRemoveConfigs==="" )} style={buttonStyle}>Submit</button> */}
                   <Header title="Zero Trust Pillar: NETWORKS" subtitle={questions[15].Description} />
                   <Box sx={{ border: 'solid 1px', p: '1em' }}>
                   <ListMapFunc questionsList={questionsNetworksList} />
                 <Button sx={{ m: '1rem', width: '25%' }} variant="contained" onClick={handleSubmit}>Submit</Button>
                 </Box>
                 </>
            )}
            {activeStep===4 && (
              <>
              {/* {questionsDataList.map(({
                uid,
                variable,
                GridItemQuestionXS,
                questionNumber,
                questionText,
                GridItemInputXS,
                GridItemInputSX,
                inputStyleWidth,
                inputStyleHeight,
                checkboxMarginLeft,
                GridItemCheckboxContainerInputXS,
                name,
                type,
                answerType,
                checkboxInputs,
                radiogroupInputs,
                onChangeInput,
                comments,
                GridItemCommentsXS,
                inputCommentWidth,
                idComment,
                valueComment,
                onChangeComments
              }) => (
                <QuestionnaireForm 
                  key={uid}
                  variable={variable}
                  GridItemQuestionXS={GridItemQuestionXS}
                  questionNumber={questionNumber}
                  questionText={questionText}
                  GridItemInputXS={GridItemInputXS}
                  GridItemInputSX={GridItemInputSX}
                  inputStyleWidth={inputStyleWidth}
                  inputStyleHeight={inputStyleHeight}
                  checkboxMarginLeft={checkboxMarginLeft}
                  GridItemCheckboxContainerInputXS={GridItemCheckboxContainerInputXS}
                  name={name}
                  type={type}
                  answerType={answerType}
                  checkboxInputs={checkboxInputs}
                  radiogroupInputs={radiogroupInputs}
                  onChangeInput={onChangeInput}
                  comments={comments}
                  GridItemCommentsXS={GridItemCommentsXS}
                  inputCommentWidth={inputCommentWidth}
                  idComment={idComment}
                  valueComment={valueComment}
                  onChangeComments={onChangeComments}
                  />
                )
                )} */}
                 {/* <button disabled={( knowsAssetCriticality==="" || doesRemoveConfigs==="" )} style={buttonStyle}>Submit</button> */}
                 <Header title="Zero Trust Pillar: DATA" subtitle={questions[30].Description} />
                 <Box sx={{ border: 'solid 1px', p: '1em' }}>
                <ListMapFunc questionsList={questionsDataList} />
               <Button sx={{ m: '1rem', width: '25%' }} variant="contained" onClick={handleSubmit}>Submit</Button>
               </Box>
                 </>
            )}
            {activeStep===5 && (
              "Step 6"
            )}
          </WidgetWrapper>
        </Box>
    </Box>
  
   </Box>
  )
}

export default ZTAAssessment