import React, { useState, useEffect, useRef } from 'react';
import { Box, Button, Divider, Grid, Tab, Typography, useTheme, Link, List, ListItem, ListItemText, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper
 } from '@mui/material';
 import { useParams, useNavigate, useLocation } from 'react-router-dom';
 import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import Header from '../../../components/Header';
import SecurityAssessmentResults from '../SecurityAssessment/SecurityAssessmentResults';
import ThreatProfiles from './ThreatProfiles';
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridEditInputCell,
} from '@mui/x-data-grid';
import { useReactToPrint } from 'react-to-print';

import { saveAs } from 'file-saver';
import RiskRegister from '../../../components/RiskRegister';




const VulnAssessmentReport = () => {
// 
  // const [rows, setRows] = React.useState();
  const [criticalDamAssets, setCriticalDamAssets] = useState();
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const [assessmentData, setAssessmentData] = useState();
  const axiosPrivate = useAxiosPrivate();
  const {assessmentID} = useParams();
  const [refresh, setRefresh] = useState(false);
  const criticalAssetItems = [];

  const [lowestScore, setLowestScore] = useState();
  const [highestScore, setHighestScore] = useState();
  const scoreListTemp = [];
  const [footScore, setFootScore] = useState(0);
  const [vehicleScore, setVehicleScore]= useState(0);
  const [boatScore, setBoatScore] = useState(0);
  const [correctiveActions , setCorrectiveActions] = useState();

  const [damManned, setDamManned] = useState("");
  const [powerhouseManned, setPowerhouseManned] = useState("");
  const [damSurveillanceCameras, setDamSurveillanceCameras] = useState("");
  const [powerhouseSurveillanceCameras, setPowerhouseSurveillanceCameras] = useState("");
  const [howCamerasViewed, setHowCamerasViewed] = useState("");
  const [walkingInspections, setWalkingInspections] = useState("");
  const [lockedFence, setLockedFence] = useState("");
  const [accessRestrictionsFoot, setAccessRestrictionsFoot] = useState();
  const [accessRestrictionsVehicle, setAccessRestrictionsVehicle] = useState();
  const [accessRestrictionBoat, setAccessRestrictionBoat] = useState();
  const [spillwayGatesUnauthorizeAccess, setSpillwayGatesUnauthorizeAccess] = useState();
  const [powerhouseDoorsWindowsLocked, setPowerhouseDoorsWindowsLocked] = useState();
  const [powerhouseAlarms, setPowerhouseAlarms] = useState();
  const [powerhouseMotionDetectors, setPowerhouseMotionDetectors] = useState();
  const [delaySystemsBypass, setDelaySystemsBypass] = useState();
  const [restrictedAccessWaterConveyance, setRestrictedAccessWaterConveyance] = useState();
  const [surveillanceWaterConveyance, setSurveillanceWaterConveyance] = useState();
  const [cpmeTamperResistant, setCpmeTamperResistant] = useState();
  const [phoneNumbersLEPosted, setPhoneNumbersLEPosted] = useState();
  const [redundantComms, setRedundantComms] = useState();
  const [operatorResponseTime, setOperatorResponseTime] = useState();
  const [operatorResponseDescription, setOperatorResponseDescription] = useState();
  const [quicklyNotifyLE, setQuicklyNotifyLE] = useState();
  const [etaLE, setEtaLE] = useState();
  const [threatAssessment, setThreatAssessment] = useState();
  const [vulernableFeatures, setVulernableFeatures] = useState();
  const [potentailImpacts, setPotentailImpacts] = useState();
  const [dateLastLEConsultation, setDateLastLEConsultation] = useState();
  const [securityImprovementLastYear, setSecurityImprovementLastYear] = useState();
  const [securityImprovementLongTerm, setSecurityImprovementLongTerm] = useState();
  const [hasSecurityPlan, setHasSecurityPlan] = useState();
  const [overallSecurityPlanQuality, setOverallSecurityPlanQuality] = useState();
  const [responseRecoveryPlan, setResponseRecoveryPlan] = useState();
  const [siteSpecificResponseLevels, setSiteSpecificResponseLevels] = useState();
  const [threatMeasuresRelevance, setThreatMeasuresRelevance] = useState();
  const [securityPlanRevision, setSecurityPlanRevision] = useState();
  const [exerciseLastDate, setExerciseLastDate] = useState();
  const [hasVulnerabilityAssessment, setHasVulnerabilityAssessment] = useState();
  const [hasSecurityAssessment, setHasSecurityAssessment] = useState();
  const [planActionIntegration, setPlanActionIntegration] = useState();
  const [conflictingSecurityMeasures, setConflictingSecurityMeasures] = useState();
  const [hasHazmat, setHasHazmat] = useState();
  const [hazmatSecureAccess, setHazmatSecureAccess] = useState();
  const [cdprSecured, setCdprSecured] = useState();
  const [pastYearSecurityIncidents, setPastYearSecurityIncidents] = useState();
  const [specificSecurityConcerns, setSpecificSecurityConcerns] = useState();
  const [otherAssessments, setOtherAssessments] = useState();
  const [changesSinceLastInspection, setChangesSinceLastInspection] = useState();
  const [physicalSiteSecurityChanges, setPhysicalSiteSecurityChanges] = useState();
  const [proceduralChanges, setProceduralChanges] = useState();
  const [cybersecurityChanges, setCybersecurityChanges] = useState();
  const [overallRiskReduction, setOverallRiskReduction] = useState();
  const [damMannedComments, setDamMannedComments] = useState("");
  const [powerhouseMannedComments, setPowerhouseMannedComments] = useState("");
  const [damSurveillanceCamerasComments, setDamSurveillanceCamerasComments] = useState("");
  const [powerhouseSurveillanceCamerasComments, setPowerhouseSurveillanceCamerasComments] = useState("");
  const [howCamerasViewedComments, setHowCamerasViewedComments] = useState("");
  const [walkingInspectionsComments, setWalkingInspectionsComments] = useState("");
  const [lockedFenceComments, setLockedFenceComments] = useState();
  const [accessRestrictionsFootComments, setAccessRestrictionsFootComments] = useState();
  const [accessRestrictionsVehicleComments, setAccessRestrictionsVehicleComments] = useState();
  const [accessRestrictionBoatComments, setAccessRestrictionBoatComments] = useState();
  const [spillwayGatesUnauthorizeAccessComments, setSpillwayGatesUnauthorizeAccessComments] = useState();
  const [powerhouseDoorsWindowsLockedComments, setPowerhouseDoorsWindowsLockedComments] = useState();
  const [powerhouseAlarmsComments, setPowerhouseAlarmsComments] = useState();
  const [powerhouseMotionDetectorsComments, setPowerhouseMotionDetectorsComments] = useState();
  const [delaySystemsBypassComments, setDelaySystemsBypassComments] = useState();
  const [restrictedAccessWaterConveyanceComments, setRestrictedAccessWaterConveyanceComments] = useState();
  const [surveillanceWaterConveyanceComments, setSurveillanceWaterConveyanceComments] = useState();
  const [cpmeTamperResistantComments, setCpmeTamperResistantComments] = useState();
  const [phoneNumbersLEPostedComments, setPhoneNumbersLEPostedComments] = useState();
  const [redundantCommsComments, setRedundantCommsComments] = useState();
  const [operatorResponseTimeComments, setOperatorResponseTimeComments] = useState();
  const [operatorResponseDescriptionComments, setOperatorResponseDescriptionComments] = useState();
  const [quicklyNotifyLEComments, setQuicklyNotifyLEComments] = useState();
  const [etaLEComments, setEtaLEComments] = useState();
  const [threatAssessmentComments, setThreatAssessmentComments] = useState();
  const [vulernableFeaturesComments, setVulernableFeaturesComments] = useState();
  const [potentailImpactsComments, setPotentailImpactsComments] = useState();
  const [dateLastLEConsultationComments, setDateLastLEConsultationComments] = useState();
  const [securityImprovementLastYearComments, setSecurityImprovementLastYearComments] = useState();
  const [securityImprovementLongTermComments, setSecurityImprovementLongTermComments] = useState();
  const [hasSecurityPlanComments, setHasSecurityPlanComments] = useState();
  const [overallSecurityPlanQualityComments, setOverallSecurityPlanQualityComments] = useState();
  const [responseRecoveryPlanComments, setResponseRecoveryPlanComments] = useState();
  const [siteSpecificResponseLevelsComments, setSiteSpecificResponseLevelsComments] = useState();
  const [threatMeasuresRelevanceComments, setThreatMeasuresRelevanceComments] = useState();
  const [securityPlanRevisionComments, setSecurityPlanRevisionComments] = useState();
  const [exerciseLastDateComments, setExerciseLastDateComments] = useState();
  const [hasVulnerabilityAssessmentComments, setHasVulnerabilityAssessmentComments] = useState();
  const [hasSecurityAssessmentComments, setHasSecurityAssessmentComments] = useState();
  const [planActionIntegrationComments, setPlanActionIntegrationComments] = useState();
  const [conflictingSecurityMeasuresComments, setConflictingSecurityMeasuresComments] = useState();
  const [hasHazmatComments, setHasHazmatComments] = useState();
  const [hazmatSecureAccessComments, setHasmatSecureAccessComments] = useState();
  const [cdprSecuredComments, setCdprSecuredComments] = useState();
  const [pastYearSecurityIncidentsComments, setPastYearSecurityIncidentsComments] = useState();
  const [specificSecurityConcernsComments, setSpecificSecurityConcernsComments] = useState();
  const [otherAssessmentsComments, setOtherAssessmentsComments] = useState();
  const [changesSinceLastInspectionComments, setChangesSinceLastInspectionComments] = useState();
  const [physicalSiteSecurityChangesComments, setPhysicalSiteSecurityChangesComments] = useState();
  const [proceduralChangesComments, setProceduralChangesComments] = useState();
  const [cybersecurityChangesComments, setCybersecurityChangesComments] = useState();
  const [overallRiskReductionComments, setOverallRiskReductionComments] = useState();
  const [damMannedCommentsHoursDay, setDamMannedCommentsHoursDay] = useState("");
  const [damMannedCommentsDaysWeek, setDamMannedCommentsDaysWeek] = useState("");
  const [powerhouseMannedCommentsDaysWeek, setPowerhouseMannedCommentsDaysWeek] = useState("");
  const [powerhouseMannedCommentsHoursDay, setPowerhouseMannedCommentsHoursDay] = useState("");

  const [rows, setRows] = useState();

  const damName = "Some Dam";
  const utility = "Some Utility";
  const fercProject = "P909090";
  const todayDate = "August 8, 2024";
  const securityGroup = 'Security Group 2';
  const river = "Green River";
  const milesFromTown = "32";
  const nearestCity = "Townsville";
  const usState = "US State";
  const megaWattsProduce = "1500";
  const peopleServed = "3";
  const damDescription = "concrete arch";
  const criticalAssetList = [
    {
      assetName: 'Powerhouse',
      description: 'missing'
    },
    {
      assetName: 'Dam Structure',
      description: 'missing'
    },
    {
      assetName: 'Valve House',
      description: 'missing'
    }
  ];

  const componentRef = useRef();
  const marginTop = '2em';
  const marginBottom = '4em';
  const marginLeft = '1em';
  const marginRight = '1em';

  const actionsList = ['action 1', 'action 2', 'action 3', 'action 4', 'action 5', 'action 6' ];

  const GridItemQuestionXS = 4;
  const GridItemInputXS = 2;
  const GridItemInputSX = 'center';
  const inputStyleWidth = '22px';
  const inputStyleHeight = '22px';
  const GridItemCommentsXS = 6;
  const inputCommentWidth = '80%';
  const checkboxMarginLeft = '10%';
  const GridItemCheckboxContainerInputXS = 3;


  // Get assessmnet data Function.  This should pull data for the assessment for the current assessment. 
  const getAssessmentData = async () => {
    try {
      const response = await axiosPrivate.get(`/assessment/${assessmentID}`, {
        withCredentials: true
      });
      const data = await response.data;
      setAssessmentData(data);
      console.log(data);
      
      setDamManned(data?.damManned?.toString());
      setPowerhouseManned(data?.powerhouseManned?.toString());
      setDamSurveillanceCameras(data?.damSurveillanceCameras?.toString());
      setPowerhouseSurveillanceCameras(data?.powerhouseSurveillanceCameras?.toString());
      setHowCamerasViewed(data?.howCamerasViewed?.toString());
      setWalkingInspections(data?.walkingInspections?.toString());
      setLockedFence(data?.lockedFence?.toString());
      setAccessRestrictionsFoot(data?.accessRestrictionsFoot?.toString());
      setAccessRestrictionsVehicle(data?.accessRestrictionsVehicle?.toString());
      setAccessRestrictionBoat(data?.accessRestrictionBoat?.toString());
      setSpillwayGatesUnauthorizeAccess(data?.spillwayGatesUnauthorizeAccess?.toString());
      setPowerhouseDoorsWindowsLocked(data?.powerhouseDoorsWindowsLocked?.toString());
      setPowerhouseAlarms(data?.powerhouseAlarms?.toString());
      setPowerhouseMotionDetectors(data?.powerhouseMotionDetectors?.toString());
      setDelaySystemsBypass(data?.delaySystemsBypass?.toString());
      setRestrictedAccessWaterConveyance(data?.restrictedAccessWaterConveyance?.toString());
      setSurveillanceWaterConveyance(data?.surveillanceWaterConveyance?.toString());
      setCpmeTamperResistant(data?.cpmeTamperResistant?.toString());
      setPhoneNumbersLEPosted(data?.phoneNumbersLEPosted?.toString());
      setRedundantComms(data?.redundantComms?.toString());
      setOperatorResponseTime(data?.operatorResponseTime?.toString());
      setOperatorResponseDescription(data?.operatorResponseDescription?.toString());
      setQuicklyNotifyLE(data?.quicklyNotifyLE?.toString());
      setEtaLE(data?.etaLE?.toString());
      setThreatAssessment(data?.threatAssessment?.toString());
      setVulernableFeatures(data?.vulernableFeatures?.toString());
      setPotentailImpacts(data?.potentailImpacts?.toString());
      setDateLastLEConsultation(data?.dateLastLEConsultation?.toString());
      setSecurityImprovementLastYear(data?.securityImprovementLastYear?.toString());
      setSecurityImprovementLongTerm(data?.securityImprovementLongTerm?.toString());
      setHasSecurityPlan(data?.hasSecurityPlan?.toString());
      setOverallSecurityPlanQuality(data?.overallSecurityPlanQuality?.toString());
      setResponseRecoveryPlan(data?.responseRecoveryPlan?.toString());
      setSiteSpecificResponseLevels(data?.siteSpecificResponseLevels?.toString());
      setThreatMeasuresRelevance(data?.threatMeasuresRelevance?.toString());
      setSecurityPlanRevision(data?.securityPlanRevision?.toString());
      setExerciseLastDate(data?.exerciseLastDate?.toString());
      setHasVulnerabilityAssessment(data?.hasVulnerabilityAssessment?.toString());
      setHasSecurityAssessment(data?.hasSecurityAssessment?.toString());
      setPlanActionIntegration(data?.planActionIntegration?.toString());
      setConflictingSecurityMeasures(data?.conflictingSecurityMeasures?.toString());
      setHasHazmat(data?.hasHazmat?.toString());
      setHazmatSecureAccess(data?.hazmatSecureAccess?.toString());
      setCdprSecured(data?.cdprSecured?.toString());
      setPastYearSecurityIncidents(data?.pastYearSecurityIncidents?.toString());
      setSpecificSecurityConcerns(data?.specificSecurityConcerns?.toString());
      setOtherAssessments(data?.otherAssessments?.toString());
      setChangesSinceLastInspection(data?.changesSinceLastInspection?.toString());
      setPhysicalSiteSecurityChanges(data?.physicalSiteSecurityChanges?.toString());
      setProceduralChanges(data?.proceduralChanges?.toString());
      setCybersecurityChanges(data?.cybersecurityChanges?.toString());
      setOverallRiskReduction(data?.overallRiskReduction?.toString());
      setDamMannedComments(data?.damMannedComments?.toString());
      setDamMannedCommentsDaysWeek(data?.damMannedCommentsDaysWeek?.toString());
      setDamMannedCommentsHoursDay(data?.damMannedCommentsHoursDay?.toString());
      setPowerhouseMannedComments(data?.powerhouseMannedComments?.toString());
      setPowerhouseMannedCommentsDaysWeek(data?.powerhouseMannedCommentsDaysWeek?.toString());
      setPowerhouseMannedCommentsHoursDay(data?.powerhouseMannedCommentsHoursDay?.toString());
      setDamSurveillanceCamerasComments(data?.damSurveillanceCamerasComments?.toString());
      setPowerhouseSurveillanceCamerasComments(data?.powerhouseSurveillanceCamerasComments?.toString());
      setHowCamerasViewedComments(data?.howCamerasViewedComments?.toString());
      setWalkingInspectionsComments(data?.walkingInspectionsComments?.toString());
      setLockedFenceComments(data?.lockedFenceComments?.toString());
      setAccessRestrictionsFootComments(data?.accessRestrictionsFootComments?.toString());
      setAccessRestrictionsVehicleComments(data?.accessRestrictionsVehicleComments?.toString());
      setAccessRestrictionBoatComments(data?.accessRestrictionBoatComments?.toString());
      setSpillwayGatesUnauthorizeAccessComments(data?.spillwayGatesUnauthorizeAccessComments?.toString());
      setPowerhouseDoorsWindowsLockedComments(data?.powerhouseDoorsWindowsLockedComments?.toString());
      setPowerhouseAlarmsComments(data?.powerhouseAlarmsComments?.toString());
      setPowerhouseMotionDetectorsComments(data?.powerhouseMotionDetectorsComments?.toString());
      setDelaySystemsBypassComments(data?.delaySystemsBypassComments?.toString());
      setRestrictedAccessWaterConveyanceComments(data?.restrictedAccessWaterConveyanceComments?.toString());
      setSurveillanceWaterConveyanceComments(data?.surveillanceWaterConveyanceComments?.toString());
      setCpmeTamperResistantComments(data?.cpmeTamperResistantComments?.toString());
      setPhoneNumbersLEPostedComments(data?.phoneNumbersLEPostedComments?.toString());
      setRedundantCommsComments(data?.redundantCommsComments?.toString());
      setOperatorResponseTimeComments(data?.operatorResponseTimeComments?.toString());
      setOperatorResponseDescriptionComments(data?.operatorResponseDescriptionComments?.toString());
      setQuicklyNotifyLEComments(data?.quicklyNotifyLEComments?.toString());
      setEtaLEComments(data?.etaLEComments?.toString());
      setThreatAssessmentComments(data?.threatAssessmentComments?.toString());
      setVulernableFeaturesComments(data?.vulernableFeaturesComments?.toString());
      setPotentailImpactsComments(data?.potentailImpactsComments?.toString());
      setDateLastLEConsultationComments(data?.dateLastLEConsultationComments?.toString());
      setSecurityImprovementLastYearComments(data?.securityImprovementLastYearComments?.toString());
      setSecurityImprovementLongTermComments(data?.securityImprovementLongTermComments?.toString());
      setHasSecurityPlanComments(data?.hasSecurityPlanComments?.toString());
      setOverallSecurityPlanQualityComments(data?.overallSecurityPlanQualityComments?.toString());
      setResponseRecoveryPlanComments(data?.responseRecoveryPlanComments?.toString());
      setSiteSpecificResponseLevelsComments(data?.siteSpecificResponseLevelsComments?.toString());
      setThreatMeasuresRelevanceComments(data?.threatMeasuresRelevanceComments?.toString());
      setSecurityPlanRevisionComments(data?.securityPlanRevisionComments?.toString());
      setExerciseLastDateComments(data?.exerciseLastDateComments?.toString());
      setHasVulnerabilityAssessmentComments(data?.hasVulnerabilityAssessmentComments?.toString());
      setHasSecurityAssessmentComments(data?.hasSecurityAssessmentComments?.toString());
      setPlanActionIntegrationComments(data?.planActionIntegrationComments?.toString());
      setConflictingSecurityMeasuresComments(data?.conflictingSecurityMeasuresComments?.toString());
      setHasHazmatComments(data?.hasHazmatComments?.toString());
      setHasmatSecureAccessComments(data?.hasmatSecureAccessComments?.toString());
      setCdprSecuredComments(data?.cdprSecuredComments?.toString());
      setPastYearSecurityIncidentsComments(data?.pastYearSecurityIncidentsComments?.toString());
      setSpecificSecurityConcernsComments(data?.specificSecurityConcernsComments?.toString());
      setOtherAssessmentsComments(data?.otherAssessmentsComments?.toString());
      setChangesSinceLastInspectionComments(data?.changesSinceLastInspectionComments?.toString());
      setPhysicalSiteSecurityChangesComments(data?.physicalSiteSecurityChangesComments?.toString());
      setProceduralChangesComments(data?.proceduralChangesComments?.toString());
      setCybersecurityChangesComments(data?.cybersecurityChangesComments?.toString());
      setOverallRiskReductionComments(data?.overallRiskReductionComments?.toString());
  
      } catch (err) {
        alert("Login Expirsed.");
        console.error(err);
        navigate('/login', { state: { from: location }, replace: true });
      }
      
      if (assessmentData === null) {
        return;
      }
    };


     // Get Critical Assets Function.  This should pull cyberAssets for the current assessment. 
const getCriticalAssets = async () => {
  try {
    const response = await axiosPrivate.get(`/criticalassets/criticalassets/${assessmentID}`, {
        withCredentials: true
    });
    const data = await response.data;
    setCriticalDamAssets(data);

    let footScore1 = 0;
    let vehicleScore1 = 0;
    let boatScore1 = 0;

    for (let index in data) {
        let tempCriticalAsset = data[index];
        let tempScoreFoot = tempCriticalAsset.externalAccess + tempCriticalAsset.physicalSecurity + tempCriticalAsset.detection + tempCriticalAsset.delay + tempCriticalAsset.response + tempCriticalAsset.responseTime + tempCriticalAsset.assessmentExternal + tempCriticalAsset.assessmentInternal + tempCriticalAsset.securityAssessment + tempCriticalAsset.securityPlan + tempCriticalAsset.cybersecurity + tempCriticalAsset.cpme + tempCriticalAsset.cdpr;
        footScore1 = footScore1 + tempScoreFoot;
        

        let tempScoreVehicle = tempCriticalAsset.externalAccessVehicle + tempCriticalAsset.physicalSecurityVehicle + tempCriticalAsset.detectionVehicle + tempCriticalAsset.delayVehicle + tempCriticalAsset.responseVehicle + tempCriticalAsset.responseTimeVehicle + tempCriticalAsset.assessmentExternalVehicle + tempCriticalAsset.assessmentInternalVehicle + tempCriticalAsset.securityAssessmentVehicle + tempCriticalAsset.securityPlanVehicle + tempCriticalAsset.cybersecurityVehicle + tempCriticalAsset.cpme + tempCriticalAsset.cdpr;
        vehicleScore1 = vehicleScore1 + tempScoreVehicle;
        
        let tempScoreBoat = tempCriticalAsset.externalAccessBoat + tempCriticalAsset.physicalSecurityBoat + tempCriticalAsset.detectionBoat + tempCriticalAsset.delayBoat + tempCriticalAsset.responseBoat + tempCriticalAsset.responseTimeBoat + tempCriticalAsset.assessmentExternalBoat + tempCriticalAsset.assessmentInternalBoat + tempCriticalAsset.securityAssessmentBoat + tempCriticalAsset.securityPlanBoat + tempCriticalAsset.cybersecurityBoat + tempCriticalAsset.cpme + tempCriticalAsset.cdpr;
        boatScore1 = boatScore1 + tempScoreBoat;
    }
    setFootScore((footScore1 / (data.length+2)).toFixed(0));
    setVehicleScore((vehicleScore1 / (data.length+2)).toFixed(0));
    setBoatScore((boatScore1 / (data.length+2)).toFixed(0));
  } catch (err) {
    // alert("Login Expired.");
    console.error(err);
    // navigate('/login', { state: { from: location }, replace: true });
  }
};

 // Get Corrective Actions Function.  This should pull cyberAssets for the current assessment. 
 const getCorrectiveActions = async () => {
  try {
    const response = await axiosPrivate.get(`/correctiveactionplan/${assessmentID}`, {
      withCredentials: true
    });
    const data = await response.data;
    setCorrectiveActions(data);
  } catch (err) {
    // alert("Login Expired.");
    console.error(err);
    // navigate('/login', { state: { from: location }, replace: true });
  }
};


 


const getSecurityAssessmentScoresFoot = (index) => {
  const securityAssessmentScoreFoot = index.externalAccess + index.physicalSecurity + index.detection + index.delay + index.response + index.responseTime + index.assessmentExternal + index.assessmentInternal + index.securityAssessment + index.securityPlan + index.cybersecurity; // + index.cpme + index.cdpr;
  return (
      securityAssessmentScoreFoot
  )
};

const getSecurityAssessmentScoresVehicle = (index) => {
  try{
      const securityAssessmentScoreVehicle = index.externalAccessVehicle + index.physicalSecurityVehicle + index.detectionVehicle + index.delayVehicle + index.responseVehicle + index.responseTimeVehicle + index.assessmentExternalVehicle + index.assessmentInternalVehicle + index.securityAssessmentVehicle + index.securityPlanVehicle + index.cybersecurityVehicle; // + index.cpme + index.cdpr;
      return (
          // index.physicalSecurityVehicle
          securityAssessmentScoreVehicle
      )       
  } catch (err) {
      console.log(err);
  }       
};

const getSecurityAssessmentScoresBoat = (index) => {
  const securityAssessmentScoreBoat = index.externalAccessBoat + index.physicalSecurityBoat + index.detectionBoat + index.delayBoat + index.responseBoat + index.responseTimeBoat + index.assessmentExternalBoat + index.assessmentInternalBoat + index.securityAssessmentBoat + index.securityPlanBoat + index.cybersecurityBoat; // + index.cpme + index.cdpr;

  return (
      securityAssessmentScoreBoat
  )
};




useEffect(() => {
  getAssessmentData();
  getCriticalAssets();
  getCorrectiveActions();
}, [refresh]);  // eslint-disable-line react-hooks/exhaustive-deps
// if (!rows) {
//   return null;
// }



// *********************** START TABLES FOR FERC HYDRO SECURITY CHECKLIST V5 
const backgroundColorDetectionAssessment = "#f4f765";
const backgroundColorDelay = "#66f2a9";
const backgroundColorResponse = "#9cd8f4";
const backgroundColorIntegrationRiskManagement = "#e2eae9";


const handlePrint = useReactToPrint({
  content: () => componentRef.current,
  documentTitle: 'emp-data',
  // onAfterPrint: () => alert('Print Success!')
});

  const handleGenerateReport = async () => {
  //   const mimeType = "application/vnd.openxmlformats-officedocument.wordprocessingml.document"; 
  //     Packer.toBlob(doc).then((str) => {
  //       str.slice(0, str.size, mimeType);
  //       saveAs(str, "MyDocumentHopefully.docx");
  // });
    console.log("Handle Generate Report Called.")
  };


  const columns = [
    {
      field: "taskNumber",
      headerName: "Task#",
      flex: 0.25,
      editable: true
    },
    {
        field: "taskName",
        headerName: "Task Name",
        flex: 0.25,
        editable: true
    },
    {
        field: "taskDescription",
        headerName: "Task Description",
        flex: 1,
        editable: true
    },
    {
      field: "taskLead",
      headerName: "Task Lead",
      flex: 0.25,
      editable: true
    },
    {
        field: "dueDate",
        headerName: "Completion Date",
        flex: 0.25,
        editable: true
    },
  
  ];

  // ***************** FOR DOWNLOADING A SIMPLE TEXT FILE ******************
    // const a = document.querySelector('a');
    // const test = "testing the blob thing."
    // const file = new Blob([test], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
    // // const file = new Blob([test], { type: 'plain/text' });
    // const url = URL.createObjectURL(file);
    // console.log(url);
    // a.href = url;
    // a.download = "myFileNEW.txt";
  // }


  const createDoc = () => {
    let tempList = [];
    tempList.push(
      <Paper sx={{ width: '66%', backgroundImage: "none", borderRadius: "0.55rem", mr:'0.55rem', p:'3rem'}}>
      <Typography sx={{ display:'flex', justifyContent:'center' }}>
      <Header title="SECURITY ASSESSMENT" subtitle='' />
      </Typography>

      <TableContainer sx={{ mt:'1rem' }}>
          <Table aria-label='table for rapid recovery procedures'>
            <TableBody>
              <TableRow >
                <TableCell sx={{ border:'solid 1px' }}><b>Dam:</b></TableCell>
                <TableCell sx={{ border:'solid 1px' }}>{assessmentData?.damName}</TableCell>
                <TableCell sx={{ border:'solid 1px' }}><b>Project:</b></TableCell>
                <TableCell sx={{ border:'solid 1px' }}>{fercProject}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ border:'solid 1px'}}><b>Assessment Type:</b></TableCell>
                <TableCell sx={{ border:'solid 1px'}} >{assessmentData?.assessmentType}</TableCell>
                <TableCell sx={{ border:'solid 1px'}}><b>Prepared for Fiscal Year:</b></TableCell>
                <TableCell sx={{ border:'solid 1px'}}>{assessmentData?.assessmentYear}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ border:'solid 1px'}}><b>Date:</b></TableCell>
                <TableCell sx={{ border:'solid 1px'}} >{todayDate}</TableCell>
                <TableCell sx={{ border:'solid 1px'}}><b>Utility:</b></TableCell>
                <TableCell sx={{ border:'solid 1px'}}>{utility}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

    <br /><br />
      <Typography variant='h3'><u>1. INTRODUCTION</u></Typography>
      <br />
      <Typography>
      The {assessmentData?.damName} Dam (FERC Project No. {fercProject} ) has been classified by the Federal Energy Regulatory Commission (FERC) as a {securityGroup} dam. 
      The dam is located on the {river} River, approximately {milesFromTown} miles upstream from the city of {nearestCity}, {usState} state. 
      The Project produces {megaWattsProduce} megawatts of electricity, serving more than {peopleServed} million people. Other benefits of the facility include:       .
      </Typography>
      <br />
      <Typography>
        Description of dam type(s):  {damDescription}. (i.e. thin arch, slab and buttress, embankment, masonry gravity, concrete gravity, massive concrete gravity, thick arch)
      </Typography>
      <br />
      <Typography>
        The Security Assessment (SA) for this dam was prepared by the {utility} Utility in accordance with the Division of Dam Safety and Inspections, FERC Security Program for Hydropower Projects - Revision 3 (August 31, 2015).
      </Typography>

        <br />
        <Typography variant='h3'><u>2. METHODOLOGY & SCOPE</u></Typography>
        <br />
        <Typography>
          The SA was completed using the FERC Hydro Security Inspection Form (version 5 – August 31, 2015), a critical asset spreadsheet (attached) and careful examination of the following:
        </Typography>
        <Typography>
          <List>
            <ListItem>
              <ListItemText primary="•	Asset evaluation" />
            </ListItem>
            <ListItem>
              <ListItemText primary="•	Geographic, demographic, and political environment" />
            </ListItem>
            <ListItem>
              <ListItemText primary="•	Visibility, notability, and accessibility" />
            </ListItem>
            <ListItem>
              <ListItemText primary="•	Existing security systems and measures" />
            </ListItem>
          </List>
        </Typography>

        <Typography>
        The SA was conducted of the dam’s structure, assets, appurtenant structures, and SCADA systems.  
        The SA includes review of detection, assessment, delay, response, integration, risk management, and protective measures. 
        The list of assets considered to be of importance to the {assessmentData.damName} Dam is shown below.
        </Typography>

    <br />
    <Typography>
    The following personnel participated in conducting the assessment:
          <List>
            <ListItem>
              <ListItemText primary="•	Plant Managers" />
            </ListItem>
            <ListItem>
              <ListItemText primary="•	Plant System Operators" />
            </ListItem>
            <ListItem>
              <ListItemText primary="•	Plant Maintenance" />
            </ListItem>
            <ListItem>
              <ListItemText primary="•	Project Safety Engineers" />
            </ListItem>
            <ListItem>
              <ListItemText primary="•	Security Division" />
            </ListItem>
          </List>
        </Typography>

    
        <br />
        <Typography variant='h3'><u>3. FACTS & CONSIDERATIONS</u></Typography>
        <br />
        <Typography>
        The Dam is visible and accessible from State Route __________ on the ___________ County side of the ____________ River, and from State Route ____________ on the __________ County side. 
        It is visible and accessible from both upstream and downstream sides of the _________ River. (Alter visibility/accessibility as necessary.)
        </Typography>
        <br />
        <Typography>
        A “Visitor Center”, located on site and shown on the accompanying drawing, hosts approximately ___________ visitors per year.  
        </Typography>
        <br />
          <Typography>
          The ____________ Utility Security Division manages a program of systems and measures that ensure protection of the Dam and Project. 
          The Physical layout of site security features for the Dam is contained on drawings incorporated in the _____________ Security Plan, which also includes potential routes of intrusion. 
          These systems and measures include (alter as needed):
          </Typography>
          <br />
          <Typography>
          •	Guard posts (with barriers and “Tiger Teeth”) -- located at each access point 
          </Typography>
          <Typography>•	Placement of fencing, barricades, and signage
          </Typography>
          <Typography>•	Placement of signage and buoy lines -- upstream and downstream of dam
          </Typography>
          <Typography>•	Credentials/electronic access badges for employees and approved contractors 
          </Typography>
          <Typography>•	Screening process for contractors, vendors, and visitors
          </Typography>
          <Typography>•	Electronic barrier arms/doors
          </Typography>
          <Typography>•	Padlock and key control
          </Typography>
          <Typography>•	Security cameras with 24-hour, live monitoring
          </Typography>
          <Typography>•	Intrusion alarms with 24-hour monitoring 
          </Typography>
          <Typography>•	Contracted security guard services
          </Typography>
          <Typography>•	Training & exercises
          </Typography>
          <Typography>•	Security policies and procedures
          </Typography>
          <Typography>•	Suspicious Activity Incident Reporting
          </Typography>
          <br />
          <Typography>
          There is no history of attempted sabotage at this Project. There is no history of unauthorized intrusions. 
          On rare occasions, there have been individuals who have attempted to trespass -- but they were immediately observed and intercepted. 
          In each of these cases, there was no malice or criminal intent. There is no intelligence to suggest the ________ Hydro Project has been targeted for any type of attack. 
          (Alter above as necessary. Discuss if trespass or theft has occurred; if so, does this point to any weaknesses in site security?) 
          </Typography>
          <br />

          <Typography>
          The _______ Utility has developed strong partnerships with local Law Enforcement Agencies (LEA), Fire, and EMS agencies. 
          These agencies support the ______________ Utility through collaborative training & exercises, observation patrols, response to incidents, and proactive meetings. 
          Coordination meetings with LEA generally occur at the following intervals: ___________.
          </Typography>
          <br />
          <Typography>
          Procedures are in place to alter site security as threat conditions change as is shown in the Security Plan for the {assessmentData?.damName} Dam.
          </Typography>



        <br />
        <Typography variant='h3'><u>4. RESULTS OF ASSESSMENT</u></Typography>
        <br />
        <Typography variant='h4'>

          <b>4.1 Results from Hydro Security Inspection Form:</b>
        </Typography>
        <br />
        <Typography>
        A review of the Hydro Security Inspection Form indicates that ____________. (Describe general observations from the form.)
        </Typography>
        <br />
          <Typography variant='h4'>
          <b>4.2 Results from Project “Asset List” Assessment Spreadsheet:</b>
          </Typography>
          <br />
        <Typography>
          For each asset evaluated, a subjective ranking of each security element used to protect that asset was assigned as: 
          1 (Requires attention); 2 (Limited acceptability); 3 (Acceptable-under modification); 4 (Acceptable). 
          Three general routes of intrusion were considered in the assessment (foot, land vehicle, and boat). 
          A review of the Assessment Spreadsheet indicates that _____________. (Describe general observations.) 
          From this assessment, some assets have been shown to be in need of further consideration. 
          It should be noted that a full risk analysis has not been completed for this project, and the level of protection necessary for identified assets may more accurately be dependent upon resultant consequences and overall risk. 
          Table 2 shows the assets identified as not fully acceptable according to the criteria defined in Attachment 2.
          </Typography>
          <br />

          <Typography>
          TABLE OF ASSETS FROM ASSESSMENT RESULTS.
          </Typography>
          <br />
          <Typography>
          Based on values assigned, each asset will receive a score of 11 (poor) to 44 (excellent). 
          Asset security coverage values ranged from ______ to _______, 
          with an average foot entrance project score of _______, 
          an average (land) vehicle entrance project score of ______, 
          and an average boat entrance project score of ________.
          </Typography>


        <br />
        <Typography variant='h3'><u>5. RECOMMENDATIONS</u></Typography>
        <br />
        <Typography>
        From this Security Assessment, the following recommendations for site security are proposed. 
        An indication of the priority assigned to each recommendation (Low, Medium, or High) and a plan and schedule to complete each recommendation is also provided.
        </Typography>
        <br />
          <Typography>
            LIST OF CORRECTIVE ACTIONS.
          </Typography>

        <br />
        <Typography variant='h3'><u>6. CONCLUSIONS</u></Typography>
        <br />
        <Typography>
        The {utility} Utility’s existing Security Program is sufficiently effective in reacting to potential adversaries – particularly, 
        generic intruders who could attempt to gain access to the identified assets at the {assessmentData?.damName} Hydro Project. 
        The {utility} Utility will continue to monitor, assess, and respond to its security needs as they change or evolve. (Revise Conclusions as necessary.)
        </Typography>
        <br /> <br />
        <br /> <br />
          <Typography variant='h4'>
            <b>Attachments:</b>
          </Typography>
          <Typography>
            1.) FERC Hydro Security Checklist (v5) 
          </Typography>
          <Typography>
            2.) FERC Assessment Form Definitions
          </Typography>
          <Typography>
            3.) Drawing of Security Features Layout and Potential Routes of Intrusion can be found in the Physical Security Plan. 
          </Typography>


        <br />
        <Typography variant='h3'><u>ATTACHMENT 1. FERC HYDRO SECURITY CHECKLIST (v5)</u></Typography>
        <br />
        <Typography>
        </Typography>
        <Typography>
        </Typography>

        <Typography variant='h3'><u>ATTACHMENT 2. FERC ASSESSMENT FORM DEFINITIONS</u></Typography>
        <Typography>
        This is a list of the data-entry values used in the Group 2 Security Assessment Template spreadsheet. 
        Definitions are also shown within the form itself by placing the computer cursor over the small red “note triangles.” 
        The values assigned should consider the overall response to each question as it pertains to the site being assessed.
        </Typography>
        <Typography>
        DETECTION, ASSESSMENT, DELAY AND RESPONSE
        </Typography>
        <Typography>
        External Access (foot, vehicle, boat):
        </Typography>
        <Typography>
        Visual access and asset protections based on structure/equipment, resiliency and facility security:
        </Typography>
        <Typography>
        1.  High visibility/highly accessible.
        </Typography>
        <Typography>
        2.  High visibility/good access control (barrier/electronic/human).
        </Typography>
        <Typography>
        3.  Low visibility/good access control (barrier/electronic/human).
        </Typography>
        <Typography>
        4.  Low visibility/inaccessible.
        </Typography>
        <Typography>
        Mark as: 1, 2, 3, or 4.
        </Typography>
        <Typography>
        Physical Security, site specific (foot, vehicle, boat):
        </Typography>
        <Typography>
        •	Key & lock protection on doors windows.
        </Typography>
        <Typography>
        •	Perimeter protection (gates/fencing).
        </Typography>
        <Typography>
        •	Photo ID/badge program.
        </Typography>
        <Typography>
        •	Electronic access control, CCTV monitoring. 
        </Typography>
        <Typography>
          •	Guard patrol, days/week_____/______, hrs/days_____/_____.
        </Typography>
        <Typography>
        Mark overall physical security as: 
        </Typography>
        <Typography>
          1. Requires attention (provide plan and schedule); 
          </Typography>
          <Typography>
        2. Limited acceptability (requires additions, upgrades-provide plan and schedule); 
        </Typography>
        <Typography>
        3. Acceptable-under modification (plan and schedule available); 
        </Typography>
        <Typography>
        4. Acceptable.
        </Typography>

        <Typography>

        Detection and Assessment (foot, vehicle, boat): 
        </Typography>
        <Typography>
          Based on physical location, structure/equipment resiliency and protections currently in-place:
          </Typography>
        <Typography>
        </Typography>
        <Typography>
          •	Site manned...days/week_____/_____hours/days_____/______.
          </Typography>
          <Typography>
            •	Surveillance cameras in use/monitored by employees, guards.
            </Typography>
            <Typography>
        •	Personnel controlled/ID, badges required.
        </Typography>
        <Typography>
          •	Walking inspections/patrols - employees/guards.
          </Typography>
          <Typography>
            •	Frequency of patrols/walking tours_______________________.
        </Typography>
        <Typography>
          Mark overall detection as: 1. Requires attention (provide plan and schedule); 2. Limited acceptability (requires additions, upgrades-provide plan and schedule); 3. Acceptable-under modification (plan and schedule available); 4. Acceptable.
        </Typography>

        <Typography>
        Delay (foot, vehicle, boat):
        </Typography>
        <Typography>
        </Typography>
        <Typography>•	Dam/assets fenced with gates/doors access controlled.
        </Typography>
        <Typography>•	Access restrictions in-place (foot/vehicle/boat) and appropriate.
        </Typography>
        <Typography>•	Spillway, spillway gate controls secured from unauthorized access.
        </Typography>
        <Typography>•	Powerhouse-accessed controlled/alarmed/CCTV/motion detection.
        </Typography>
        <Typography>•	Physical protection equipment secured against tampering.
        </Typography>
        <Typography>
          Mark overall delay as: 1. Requires attention (provide plan and schedule); 2. Limited acceptability (requires additions, upgrades-provide plan and schedule); 3. Acceptable-under modification (plan and schedule available); 4. Acceptable.
        </Typography>

        <Typography>

        Response:
        </Typography>
        <Typography>
        </Typography>
        <Typography>•	Law enforcement numbers posted.
        </Typography>
        <Typography>•	Law enforcement notified quickly.
        </Typography>
        <Typography>•	Are there redundant communications.
        </Typography>
        <Typography>•	Quick detection and response on unauthorized access attempts.   
        </Typography>
        <Typography>•	Law enforcement responders available: Local______State_______Fed_______
        </Typography>
        <Typography>
          Mark overall response actions as: 1. Requires attention (provide plan and schedule); 2. Limited acceptability (requires additions, upgrades-provide plan and schedule); 3. Acceptable-under modification (plan and schedule available); 4. Acceptable.
        </Typography>

        <Typography>
        Response time for law enforcement, site specific:
        </Typography>
        <Typography>•	Remote area, no law enforcement patrol, no intrusion alert.
        </Typography>
        <Typography> •	Remote area, warning signage, law enforcement numbers posted
        </Typography>
        <Typography> •	Intrusion alert with 30 minute plus law enforcement response.
        </Typography>
        <Typography>•	Intrusion alert with under 15 minute law enforcement response.
        </Typography>
        <Typography>•	Guard service/law enforcement on duty at location. 
        </Typography>
        <Typography>
          Mark overall response time as: 1. Requires attention (provide plan and schedule); 2. Limited acceptability (requires additions, upgrades-provide plan and schedule); 3. Acceptable-under modification (plan and schedule available); 4. Acceptable.
        </Typography>

        <Typography>

        INTEGRATION AND RISK MANAGEMENT:
        </Typography>
        <Typography>
          Assessment External:
          </Typography>
          <Typography>
        Relative measure of threats/actions based on Utility experience and law enforcement experience/intelligence:
        </Typography>
        <Typography> 1.  Current active threats/vandalism/theft/trespass.
        </Typography>
        <Typography> 2.  Current oral threats/vandalism/theft/trespass.
        </Typography>
        <Typography> 3.  Past active threats/vandalism/theft/trespass.
        </Typography>
        <Typography> 4.  No known threats or past activity.
        </Typography>
        <Typography>
          Mark overall external assessment as either: 1, 2, 3, or 4.   
        </Typography>

        <Typography>
        Assessment Internal:
        </Typography>
        <Typography>
        Relative measure of threats/actions based on Utility experience and law enforcement experience/intelligence:
        </Typography>
        <Typography>1.  Current active threats.
        </Typography>
        <Typography> 2.  Current oral threats.
        </Typography>
        <Typography>  3.  Past active threats.
        </Typography>
        <Typography>4.  No known threats.
        </Typography>
        <Typography>
        Mark overall internal assessment as either: 1, 2, 3, or 4.   
        </Typography>

        <Typography>
        Security Assessment Includes:
        </Typography>
        <Typography>
        </Typography>
        <Typography> •	Introduction/Scope/Methodology.
        </Typography>
        <Typography>•	List of critical physical dam assets, structures, appurtenant structures.  
        </Typography>
        <Typography>•	Inherent accessibility and visibility of each identified asset.
        </Typography>
        <Typography>•	Security system procedures/responses to potential adversaries.
        </Typography>
        <Typography> •	Security improvement recommendations with plans and schedules.
        </Typography>
        <Typography>
          Mark overall security assessment as: 1. Requires attention (provide plan and schedule); 2. Limited acceptability (requires additions, upgrades-provide plan and schedule); 3. Acceptable-under modification (plan and schedule available); 4. Acceptable.
        </Typography>

        <Typography>
        Security Plan Includes:
        </Typography>
        <Typography>
        </Typography>
        <Typography> •	Documentation of restricted areas, physical dam related assets, physical security protections, descriptions, layout and inventory.
        </Typography>
        <Typography>•	Security Operational Procedures (employee duties, education, training, document controls) w/Emergency Responses (Bomb Threats, Civil Disturbance, Temporary Project Closure, etc...),
        </Typography>
        <Typography>•	Procedures for Site Access Control and Security equipment maintenance and testing (NERC.CIP 6, R6). 
        </Typography>
        <Typography>•	Emergency notification, communication and redundancies (EAP coordination).
        </Typography>
        <Typography>•	DHS Threat Level Contingency Planning. 
        </Typography>
        <Typography>
          Mark overall security plan as: 1. Requires attention (provide plan and schedule); 2. Limited acceptability (requires additions, upgrades-provide plan and schedule); 3. Acceptable-under modification (plan and schedule available); 4. Acceptable.
        </Typography>

        <Typography>
        Cyber/SCADA Security:
        </Typography>
        <Typography>
        </Typography>
        <Typography>1.  Systems not protected from Internet intrusion. 
        </Typography>
        <Typography>2.  System is stand alone or has firewall protection from Internet intrusion.
        </Typography>
        <Typography>3.  Firewalls and internal systems in place and updated w/patches.  
        </Typography>
        <Typography>4.  NERC/CIP standards in place.
        </Typography>
        <Typography>Mark as: 1, 2, 3, or 4. 
        </Typography>

        <Typography>
        (CPME) = Critical Performance Monitoring Equipment
        </Typography>
        <Typography>For the project as a whole, choose a value best representing the protection of CPME between values of 11 (poorly protected) to 44 (excellent protection).
          </Typography>

        <Typography>
        (CDP&R) = Critical Drawings, Plans & Records
        </Typography>
        <Typography>For the project as a whole, choose a value best representing the protection of CDP&R between values of 11 (poorly protected) to 44 (excellent protection).
          </Typography>

        <Typography>
        </Typography>

        <Typography>
        OVERALL SCORE:
        </Typography>
        <Typography>
        At the end of the analysis, you will see a ranking score for each asset of concern ranging from11 (poorly protected) to 44 (excellent protection). In general, a score of 44 indicates that security coverage of that asset is fully acceptable, whereas a score of 11 indicates that attention to that asset may be required. A discussion of these findings should be included in the Security Assessment narrative.
        </Typography>
        <Typography>
        The average of all the individual asset security-protection scores is also presented to provide an indication of the overall security findings at the site. 

        </Typography>
      </Paper>
    )
    return (
      tempList
    )
  };

  const getPageMargins = () => {
    return `@page {margin: ${marginTop} ${marginRight} ${marginBottom} ${marginLeft} !important; }`;
  }

  


  return (
    <Box>
      
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ display:'flex', justifyContent:'center', mt:'2rem' }}>
          <Button sx={{ m: '0.5rem', fontSize: '30', p: '1rem', width: '10%' }} variant='contained' onClick={handlePrint}>Print Security Assessment Report</Button>
        </Grid>
        <Paper sx={{ pt:'2em', pr:'5em', pl:'5em', pb:'2rem' }} ref={componentRef}>
        <Typography variant='h2'>Security Assessment Results</Typography>
        <Divider />
          <Grid item xs={12} sx={{ display:'flex', justifyContent:'center', mt:'1rem' }}>
          {/* {createDoc()} */}
            <SecurityAssessmentResults />
          </Grid>

          {/**Threat Profiles */}
          <Grid item xs={12} sx={{ mt:'2rem' }}>
            <ThreatProfiles />
          </Grid>


          {/**Risk Register */}
          <Grid item xs={12} sx={{ mt:'2rem' }} >
            <RiskRegister />
          </Grid>

          
          {/**FERC Security Checlist v5 */}
          <Grid item xs={12} sx={{ mt:'2rem' }}>
            {/**FERC Security Checklist v5 */}
            <Typography variant='h2'>FERC Security Checklist v5</Typography>
            <Divider />
            <Box sx={{ mt:'2rem' }}>

              {/** HEADERS */}
              <Grid sx={{ display: 'flex', alignItems: 'center', backgroundImage: "none", backgroundColor: '#CCCCCC',  mt: '0.5rem', mr:'0.55rem', p:'0.5rem' }} container spacing={1} direction="columns">
                <Grid item xs={GridItemQuestionXS}>
                  <Typography color="black" variant= "h3"><b>Field Observations: (Provide detailed supplemental information to the right)</b></Typography>
                </Grid>
                <Grid item xs={GridItemInputXS} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Typography color="black" variant= "h3"><b>Answer</b></Typography>
                </Grid>
                <Grid item xs={GridItemCommentsXS} sx={{ mb:'1rem' }}>
                  <Typography color="black" variant= "h3"><b>Comments (Provide additional details – especially any “No” answers – here and separate sheets, if necessary. Indicate NA if not appropriate to site.)</b></Typography>
                </Grid>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Divider sx={{ width: '95%', m:'1rem'}} />
                </Grid>
              </Grid>

              {/* ************DETECTION & ASSESSMENT DETECTION****************/}
                <Grid sx={{ display: 'flex', backgroundImage: "none", backgroundColor: '#ffff99', mr:'0.55rem', p:'1rem' }} container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant='h2'>DETECTION & ASSESSEMENT</Typography>
                  <Divider />
                </Grid>
                  {/* Question 1a */}
                    <Grid item xs={GridItemQuestionXS}>
                      <Typography sx={{ ml:'0.5rem' }}>1a. Is the dam manned?</Typography>
                    </Grid>
                    <Grid item xs={GridItemInputXS} sx={{ display: 'flex', justifyContent:'center', alignSelf:GridItemInputSX }}>
                      <Typography>{damManned}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                    <Grid container spacing={1}>
                      <Grid item xs={7}>
                        <label htmlFor="damMannedCommentsDaysWeek">How many days per week:</label>
                        </Grid>
                        <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'start'}}>
                          <Typography>{damMannedCommentsDaysWeek}</Typography>
                        </Grid> 
                        
                        <Grid item xs={7} sx={{ justifyContent: 'start'}}>
                          <label htmlFor="damMannedCommentsHoursDay" >How many hours per day:</label>
                        </Grid>
                        <Grid item xs={4} sx={{ justifyContent: 'left'}}>
                          <Typography>{damMannedCommentsHoursDay}</Typography>
                        </Grid> 
                    </Grid>
                    </Grid>

                    <Grid item xs={3} sx={{ justifyContent: 'center'}}>
                      <label htmlFor="damMannedComments">Additional Comments:</label>
                      <Typography>{damMannedComments}</Typography>
                    </Grid>
                    <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Divider sx={{ width: '95%', m:'1rem'}} />
                    </Grid>

                  {/* Question 1b */}
                    <Grid item xs={GridItemQuestionXS}>
                      <Typography sx={{ ml:'0.5rem' }}>1b. Is the powerhouse manned?</Typography>
                    </Grid>
                    <Grid item xs={GridItemInputXS} sx={{ display: 'flex', justifyContent:'center', alignSelf:GridItemInputSX }}>
                      <Typography>{powerhouseManned}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Grid container spacing={1}>
                        <Grid item xs={7}>
                          <label htmlFor="powerhouseMannedCommentsDaysWeek">How many days per week:</label>
                        </Grid>
                        <Grid item xs={4} sx={{ justifyContent: 'left'}}>
                          <Typography>{powerhouseMannedCommentsDaysWeek}</Typography>
                        </Grid>
                
                        <Grid item xs={7} sx={{ justifyContent: 'start'}}>
                          <label htmlFor="powerhouseMannedCommentsHoursDay" >How many hours per day:</label>
                        </Grid>
                        <Grid item xs={4} sx={{ justifyContent: 'left'}}>
                          <Typography>{powerhouseMannedCommentsHoursDay}</Typography>
                        </Grid> 
                      </Grid>
                    </Grid>
              
                    <Grid item xs={3} sx={{ justifyContent: 'center'}}>
                      <label htmlFor="powerhouseMannedComments">Additional Comments:</label>
                      <Typography>{powerhouseMannedComments}</Typography>
                    </Grid> 

                    <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Divider sx={{ width: '95%', m:'1rem'}} />
                    </Grid>

                  {/* Question 2a */}
                    <Grid item xs={GridItemQuestionXS}>
                      <Typography sx={{ ml:'0.5rem' }}>2a. Are there surveillance cameras in use at the Dam?</Typography>
                    </Grid>
                    <Grid item xs={GridItemInputXS} sx={{ display: 'flex', justifyContent:'center', alignSelf:GridItemInputSX }}>
                      <Typography>{damSurveillanceCameras}</Typography>
                    </Grid>
                      
                    <Grid item xs={6} sx={{ justifyContent: 'center'}}>
                      <label htmlFor="damSurveillanceCamerasComments">How are these cameras viewed and checked?</label>
                      <Typography>{damSurveillanceCamerasComments}</Typography>
                    </Grid> 
                    <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Divider sx={{ width: '95%', m:'1rem'}} />
                    </Grid>

                  {/* Question 2b */}
                    <Grid item xs={GridItemQuestionXS}>
                      <Typography sx={{ ml:'0.5rem' }}>2b. Are there surveillance cameras in use at the powerhouse?</Typography>
                    </Grid>
                    <Grid item xs={GridItemInputXS} sx={{ display: 'flex', justifyContent:'center', alignSelf:GridItemInputSX }}>
                      <Typography>{powerhouseSurveillanceCameras}</Typography>
                    </Grid>
                        
                    <Grid item xs={6} sx={{ justifyContent: 'center'}}>
                      <label htmlFor="powerhouseSurveillanceCamerasComments">How are these cameras viewed and checked?</label>
                      <Typography>{powerhouseSurveillanceCamerasComments}</Typography>
                    </Grid> 
                    <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Divider sx={{ width: '95%', m:'1rem'}} />
                    </Grid>

                  {/* Question 3 */}
                    <Grid item xs={GridItemQuestionXS}>
                      <Typography sx={{ ml:'0.5rem' }}>3. Is the frequency of walking inspections appropriate (safety and / or security)?</Typography>
                    </Grid>
                    <Grid item xs={GridItemInputXS} sx={{ display: 'flex', justifyContent:'center', alignSelf:GridItemInputSX }}>
                      <Typography>{walkingInspections}</Typography>
                    </Grid>
                        
                    <Grid item xs={6} sx={{ justifyContent: 'center'}}>
                      <label htmlFor="walkingInspectionsComments">Additional Comments:</label>
                      <Typography>{walkingInspectionsComments}</Typography>
                    </Grid> 
                    
                    <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Divider sx={{ width: '95%', m:'1rem'}} />
                    </Grid>

                </Grid>


              {/* ************DELAY SECTION****************/}
                <Grid sx={{ display:'flex', backgroundImage: "none", backgroundColor: '#CCFFCC', borderRadius: "0.55rem", mr:'0.55rem', p:'4px' }} container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant='h2'>DELAY</Typography>
                  <Divider />
                </Grid>
                  {/* Question 4 */}
                  <Grid item xs={GridItemQuestionXS}>
                    <Typography sx={{ ml:'0.5rem' }}>4. Is the dam site fenced with gates/doors locked (if appropriate to the site)?</Typography>
                  </Grid>
                  <Grid item xs={GridItemInputXS} sx={{ display: 'flex', justifyContent:'center', alignSelf:GridItemInputSX }}>
                    <Typography>{lockedFence}</Typography>
                  </Grid>
            
                  <Grid item xs={GridItemCommentsXS}>
                    <label htmlFor="lockedFenceComments">Additional Comments:</label>
                    <Typography>{lockedFenceComments}</Typography>
                  </Grid> 

                  <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Divider sx={{ width: '95%', m:'1rem'}} />
                  </Grid>


                  {/* Question 5 */}
                  <Grid item xs={12}>
                    <Typography sx={{ ml:'0.5rem', mb:'1rem'}}>5. Are access restrictions to the dam and powerhouse sufficient to prevent the following types of attackers?</Typography>
                  </Grid>
                  <Grid item xs={GridItemQuestionXS} sx={{ display: 'flex', justifyContent:'flex-end', alignItems:'center' }}>
                    <Typography sx={{ m:'1rem' }}>Attacker on Foot:</Typography>
                  </Grid>
                  <Grid item xs={GridItemInputXS} sx={{ display: 'flex', justifyContent:'center', alignSelf:GridItemInputSX }}>
                    <Typography>{accessRestrictionsFoot}</Typography>
                  </Grid>
            
                  <Grid item xs={GridItemCommentsXS}>
                    <label htmlFor="accessRestrictionsFootComments">Additional Comments:</label>
                    <Typography>{accessRestrictionsFootComments}</Typography>
                  </Grid>

                  <Grid item xs={GridItemQuestionXS} sx={{ display: 'flex', justifyContent:'flex-end', alignItems:'center' }}>
                    <Typography sx={{ m:'1rem' }}>Attacker in a Vehicle:</Typography>
                  </Grid>
                  <Grid item xs={GridItemInputXS} sx={{ display: 'flex', justifyContent:'center', alignSelf:GridItemInputSX }}>
                    <Typography>{accessRestrictionsVehicle}</Typography>
                  </Grid>
            
                  <Grid item xs={GridItemCommentsXS}>
                    <label htmlFor="accessRestrictionsVehicleComments">Additional Comments:</label>
                    <Typography>{accessRestrictionsVehicleComments}</Typography>
                  </Grid> 

                  <Grid item xs={GridItemQuestionXS} sx={{ display: 'flex', justifyContent:'flex-end', alignItems:'center' }}>
                    <Typography sx={{ m:'1rem' }}>Attacker in a Boat:</Typography>
                  </Grid>
                  <Grid item xs={GridItemInputXS} sx={{ display: 'flex', justifyContent:'center', alignSelf:GridItemInputSX }}>
                    <Typography>{accessRestrictionBoat}</Typography>
                  </Grid>
            
                  <Grid item xs={GridItemCommentsXS}>
                    <label htmlFor="accessRestrictionBoatComments">Additional Comments:</label>
                    <Typography>{accessRestrictionBoatComments}</Typography>
                  </Grid> 

                  <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Divider sx={{ width: '95%', m:'1rem'}} />
                  </Grid>


                  {/* Question 6 */}
                  <Grid item xs={GridItemQuestionXS}>
                    <Typography sx={{ ml:'0.5rem' }}>6. Are spillway gate controls secured against unauthorized access?</Typography>
                  </Grid>
                  <Grid item xs={GridItemInputXS} sx={{ display: 'flex', justifyContent:'center', alignSelf:GridItemInputSX }}>
                    <Typography>{spillwayGatesUnauthorizeAccess}</Typography>
                  </Grid>
                      
                  <Grid item xs={GridItemCommentsXS}>
                    <label htmlFor="spillwayGatesUnauthorizeAccessComments">Additional Comments:</label>
                    <Typography>{spillwayGatesUnauthorizeAccessComments}</Typography>
                  </Grid> 
                  <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Divider sx={{ width: '95%', m:'1rem'}} />
                  </Grid>


                  {/* Question 7a */}
                  <Grid item xs={12}>
                    <Typography sx={{ ml:'0.5rem', mb:'1rem'}}>7a. Which of the following systems protect powerhouse doors and windows?</Typography>
                  </Grid>
                  <Grid item xs={GridItemQuestionXS} sx={{ display: 'flex', justifyContent:'flex-end', alignItems:'center' }}>
                    <Typography sx={{ m:'1rem' }}>Locked:</Typography>
                  </Grid>
                  <Grid item xs={GridItemInputXS} sx={{ display: 'flex', justifyContent:'center', alignSelf:GridItemInputSX }}>
                    <Typography>{powerhouseDoorsWindowsLocked}</Typography>
                  </Grid>
            
                  <Grid item xs={GridItemCommentsXS}>
                    <label htmlFor="powerhouseDoorsWindowsLockedComments">Additional Comments:</label>
                    <Typography>{powerhouseDoorsWindowsLockedComments}</Typography>
                  </Grid>

                  <Grid item xs={GridItemQuestionXS} sx={{ display: 'flex', justifyContent:'flex-end', alignItems:'center' }}>
                    <Typography sx={{ m:'1rem' }}>Alarms:</Typography>
                  </Grid>
                  <Grid item xs={GridItemInputXS} sx={{ display: 'flex', justifyContent:'center', alignSelf:GridItemInputSX }}>
                    <Typography>{powerhouseAlarms}</Typography>
                  </Grid>
            
                  <Grid item xs={GridItemCommentsXS}>
                    <label htmlFor="powerhouseAlarmsComments">Additional Comments:</label>
                    <Typography>{powerhouseAlarmsComments}</Typography>
                  </Grid> 

                  <Grid item xs={GridItemQuestionXS} sx={{ display: 'flex', justifyContent:'flex-end', alignItems:'center' }}>
                    <Typography sx={{ m:'1rem' }}>Motion Detectors:</Typography>
                  </Grid>
                  <Grid item xs={GridItemInputXS} sx={{ display: 'flex', justifyContent:'center', alignSelf:GridItemInputSX }}>
                    <Typography>{powerhouseMotionDetectors}</Typography>
                  </Grid>
            
                  <Grid item xs={GridItemCommentsXS}>
                    <label htmlFor="powerhouseMotionDetectorsComments">Additional Comments:</label>
                    <Typography>{powerhouseMotionDetectorsComments}</Typography>
                  </Grid> 
          
                  <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Divider sx={{ width: '95%', m:'1rem'}} />
                  </Grid>

        
                  {/* Question 7b */}
                  <Grid item xs={GridItemQuestionXS}>
                    <Typography sx={{ ml:'0.5rem' }}>7b. Can these systems be easily bypassed?</Typography>
                  </Grid>
                  <Grid item xs={GridItemInputXS} sx={{ display: 'flex', justifyContent:'center', alignSelf:GridItemInputSX }}>
                    <Typography>{delaySystemsBypass}</Typography>
                  </Grid>
            
                  <Grid item xs={GridItemCommentsXS}>
                    <label htmlFor="delaySystemsBypassComments">Additional Comments:</label>
                    <Typography>{delaySystemsBypassComments}</Typography>
                  </Grid> 
                  <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Divider sx={{ width: '95%', m:'1rem'}} />
                  </Grid>


                  {/* Question 8 */}
                  <Grid item xs={12}>
                    <Typography sx={{ ml:'0.5rem' }}>8. Which of the following physical security measures apply to the water conveyance system?</Typography>
                  </Grid>
                  <Grid item xs={GridItemQuestionXS} sx={{ display: 'flex', justifyContent:'flex-end', alignItems:'center' }}>
                    <Typography sx={{ m:'1rem' }}>Access Restricted:</Typography>
                  </Grid>
                  <Grid item xs={GridItemInputXS} sx={{ display: 'flex', justifyContent:'center', alignSelf:GridItemInputSX }}>
                    <Typography>{restrictedAccessWaterConveyance}</Typography>
                  </Grid>
            
                  <Grid item xs={GridItemCommentsXS}>
                    <label htmlFor="restrictedAccessWaterConveyanceComments">Additional Comments:</label>
                    <Typography>{restrictedAccessWaterConveyanceComments}</Typography>
                  </Grid> 

                  <Grid item xs={GridItemQuestionXS} sx={{ display: 'flex', justifyContent:'flex-end', alignItems:'center' }}>
                    <Typography sx={{ m:'1rem' }}>Surveillance:</Typography>
                  </Grid>
                  <Grid item xs={GridItemInputXS} sx={{ display: 'flex', justifyContent:'center', alignSelf:GridItemInputSX }}>
                    <Typography>{surveillanceWaterConveyance}</Typography>
                  </Grid>
            
                  <Grid item xs={GridItemCommentsXS}>
                    <label htmlFor="surveillanceWaterConveyanceComments">Additional Comments:</label>
                    <Typography>{surveillanceWaterConveyanceComments}</Typography>
                  </Grid> 
                  <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Divider sx={{ width: '95%', m:'1rem'}} />
                  </Grid>

                  {/* Question 9 */}
                  <Grid item xs={GridItemQuestionXS}>
                    <Typography sx={{ ml:'0.5rem' }}>9. Is Critical Performance Monitoring Equipment (CPME) secured against tampering?</Typography>
                  </Grid>
                  <Grid item xs={GridItemInputXS} sx={{ display: 'flex', justifyContent:'center', alignSelf:GridItemInputSX }}>
                    <Typography>{cpmeTamperResistant}</Typography>
                  </Grid>
            
                  <Grid item xs={GridItemCommentsXS}>
                    <label htmlFor="cpmeTamperResistantComments">Additional Comments:</label>
                    <Typography>{cpmeTamperResistantComments}</Typography>
                  </Grid> 
                  <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Divider sx={{ width: '95%', m:'1rem'}} />
                  </Grid>

                </Grid>


              {/* ************RESPONSE SECTION****************/}
              <Grid sx={{ display:'flex', backgroundImage: "none", backgroundColor: '#CCFFFF', borderRadius: "0.55rem", mr:'0.55rem', p:'4px' }} container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant='h2'>RESPONSE</Typography>
                  <Divider />
                </Grid>
                {/* Question 10 */}
                <Grid item xs={GridItemQuestionXS}>
                  <Typography sx={{ ml:'0.5rem' }}>10. Is Critical Performance Monitoring Equipment (CPME) secured against tampering?</Typography>
                </Grid>
                <Grid item xs={GridItemInputXS} sx={{ display: 'flex', justifyContent:'center', alignSelf:GridItemInputSX }}>
                  <Typography>{phoneNumbersLEPosted}</Typography>
                </Grid>
            
                <Grid item xs={GridItemCommentsXS}>
                  <label htmlFor="phoneNumbersLEPostedComments">Additional Comments:</label>
                  <Typography>{phoneNumbersLEPostedComments}</Typography>
                </Grid> 
                <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Divider sx={{ width: '95%', m:'1rem'}} />
                </Grid>


                {/* Question 11 */}
                <Grid item xs={GridItemQuestionXS}>
                  <Typography sx={{ ml:'0.5rem' }}>11. Are there redundant communications?</Typography>
                </Grid>
                <Grid item xs={GridItemInputXS} sx={{ display: 'flex', justifyContent:'center', alignSelf:GridItemInputSX }}>
                  <Typography>{redundantComms}</Typography>
                </Grid>
              
                <Grid item xs={GridItemCommentsXS}>
                  <label htmlFor="redundantCommsComments">Additional Comments:</label>
                  <Typography>{redundantCommsComments}</Typography>
                </Grid> 
                <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Divider sx={{ width: '95%', m:'1rem'}} />
                </Grid>

                {/* Question 12 */}
                <Grid item xs={GridItemQuestionXS}>
                  <Typography sx={{ ml:'0.5rem' }}>12. How long it takes the operator if detected to respond to unauthorized access?</Typography>
                </Grid>
                <Grid item xs={GridItemInputXS} sx={{ display: 'flex', justifyContent:'center', alignSelf:GridItemInputSX }}>
                  <Typography>{operatorResponseTime}</Typography>
                </Grid>
              
                <Grid item xs={GridItemCommentsXS}>
                  <label htmlFor="operatorResponseTimeComments">How is detection made?</label>
                  <Typography>{operatorResponseTimeComments}</Typography>
                </Grid> 
                <Grid item xs={10} sx={{ display: 'flex', ml:'3rem', alignItems:'center', mt:'1rem' }}>
                  <label htmlFor="operatorResponseDescription">What is that response?</label>
                  <Typography>{operatorResponseDescription}</Typography>
                </Grid> 
                <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Divider sx={{ width: '95%', m:'1rem'}} />
                </Grid>

                {/* Question 13 */}
                <Grid item xs={GridItemQuestionXS}>
                  <Typography sx={{ ml:'0.5rem' }}>13. Can law enforcement be quickly notified?</Typography>
                </Grid>
                <Grid item xs={GridItemInputXS} sx={{ display: 'flex', justifyContent:'center', alignSelf:GridItemInputSX }}>
                  <Typography>{quicklyNotifyLE}</Typography>
                </Grid>
                
                <Grid item xs={GridItemCommentsXS}>
                  <label htmlFor="quicklyNotifyLEComments">Identify law enforcement agencies and thier capabilities:</label>
                  <Typography>{quicklyNotifyLE}</Typography>
                  <br /><br />
                  <label htmlFor="etaLE">Estimated time for arrival?</label>
                  <Typography>{quicklyNotifyLE}</Typography>
                </Grid> 

                <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Divider sx={{ width: '95%', m:'1rem'}} />
                </Grid>

              </Grid>


              {/* ************INTEGRATION & RISK MANAGEMENT SECTION****************/}
              <Grid sx={{ display:'flex', backgroundImage: "none", backgroundColor: '#F3F3F3', borderRadius: "0.55rem", mr:'0.55rem', p:'4px' }} container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant='h2'>INTEGRATION & RISK MANAGEMENT</Typography>
                    <Divider />
                </Grid>
                {/* Question 14a */}
                <Grid item xs={GridItemQuestionXS}>
                  <Typography sx={{ ml:'0.5rem' }}>14a. Describe vulnerable features, including include switchyards & transmission lines, etc., and potential impacts for each, include cyberattacks. </Typography>
                </Grid>
            
                <Grid item xs={GridItemCommentsXS}>
                  <label htmlFor="vulernableFeatures"></label>
                  <Typography>{vulernableFeatures}</Typography>
                </Grid> 
                <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Divider sx={{ width: '95%', m:'1rem'}} />
                </Grid>

                {/* Question 14b */}
                <Grid item xs={GridItemQuestionXS}>
                  <Typography sx={{ ml:'0.5rem' }}>14b. Describe threats your organization has idenfied. </Typography>
                </Grid>
                <Grid item xs={GridItemCommentsXS}>
                  <label htmlFor="threatAssessmentComments"></label>
                  <Typography>{threatAssessmentComments}</Typography>
                </Grid> 
                <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Divider sx={{ width: '95%', m:'1rem'}} />
                </Grid>

                {/* Question 14c */}
                <Grid item xs={GridItemQuestionXS}>
                  <Typography sx={{ ml:'0.5rem' }}>14c. Last time a consultation with law enforcement was made to determine threat:</Typography>
                </Grid>
                <Grid item xs={GridItemCommentsXS}>
                  <label htmlFor="dateLastLEConsultation"></label>
                  <Typography>{dateLastLEConsultation}</Typography>
                </Grid> 
                <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Divider sx={{ width: '95%', m:'1rem'}} />
                </Grid>


                {/* Question 15a */}
                <Grid item xs={GridItemQuestionXS}>
                  <Typography sx={{ ml:'0.5rem' }}>15a. Describe the steps or projects you have taken to improve secuirty in the <u>last year</u>.</Typography>
                </Grid>
            
                <Grid item xs={GridItemCommentsXS}>
                  <label htmlFor="securityImprovementLastYear"></label>
                  <Typography>{securityImprovementLastYear}</Typography>
                </Grid> 
                <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Divider sx={{ width: '95%', m:'1rem'}} />
                </Grid>

                {/* Question 15b */}
                <Grid item xs={GridItemQuestionXS}>
                  <Typography sx={{ ml:'0.5rem' }}>15b. If applicable, describe your <u>long-term</u> plans to improve secuirty.</Typography>
                </Grid>
                <Grid item xs={GridItemCommentsXS}>
                  <label htmlFor="securityImprovementLongTerm"></label>
                  <Typography>{securityImprovementLongTerm}</Typography>
                </Grid> 
                <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Divider sx={{ width: '95%', m:'1rem'}} />
                </Grid>

                {/* Question 16a */}
                <Grid item xs={GridItemQuestionXS}>
                  <Typography sx={{ ml:'0.5rem' }}>16a. Is there a Security Plan as described in Chapter 7 of <Link href="https://www.ferc.gov/sites/default/files/2020-04/security.pdf">FERC's FERC Security Program for Hydropower Projects
                  Revision 3 </Link> for this FERC project?</Typography>
                </Grid>
                <Grid item xs={GridItemInputXS} sx={{ display: 'flex', justifyContent:'center', alignSelf:GridItemInputSX }}>
                  <Typography>{hasSecurityPlan}</Typography>
                </Grid>
                <Grid item xs={4}>
                </Grid>

                <Grid item xs={GridItemQuestionXS} sx={{ display: 'flex', alignItems:'center', mt:'1rem', justifyContent:'flex-end' }}>
                  <Typography sx={{ ml: '3rem' }}>Is the plan acceptable?</Typography>
                </Grid>
                <Grid item xs={GridItemInputXS} sx={{ display: 'flex', justifyContent:'center', alignSelf:GridItemInputSX }}>
                  <Typography>{overallSecurityPlanQuality}</Typography>
                </Grid>
                <Grid item xs={4}>
                </Grid>
                <Grid item xs={GridItemQuestionXS} sx={{ display: 'flex', alignItems:'center', mt:'1rem', justifyContent:'flex-end' }}>
                  <Typography sx={{ ml: '3rem' }}>Is there a Response/Recovery component?</Typography>
                </Grid>
                <Grid item xs={GridItemInputXS} sx={{ display: 'flex', justifyContent:'center', alignSelf:GridItemInputSX }}>
                  <Typography>{responseRecoveryPlan}</Typography>
                </Grid>
                <Grid item xs={4}>
                </Grid>
                <Grid item xs={GridItemQuestionXS} sx={{ display: 'flex', alignItems:'center', mt:'1rem', justifyContent:'flex-end' }}>
                  <Typography sx={{ ml: '3rem' }}>Are there different site-specific response levels covered in the Security Plan for varying threat?</Typography>
                </Grid>
                <Grid item xs={GridItemInputXS} sx={{ display: 'flex', justifyContent:'center', alignSelf:GridItemInputSX }}>
                  <Typography>{siteSpecificResponseLevels}</Typography>
                </Grid>
                <Grid item xs={GridItemCommentsXS}>
                  <label htmlFor="siteSpecificResponseLevelsComments"></label>
                  <Typography>{siteSpecificResponseLevelsComments}</Typography>
                </Grid> 

                <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Divider sx={{ width: '95%', m:'1rem'}} />
                </Grid>


                {/* Question 16b */}
                <Grid item xs={GridItemQuestionXS}>
                  <Typography sx={{ ml:'0.5rem' }}>16b. Has the Security Plan been revised since the last field change?</Typography>
                </Grid>
                <Grid item xs={GridItemInputXS} sx={{ display: 'flex', justifyContent:'center', alignSelf:GridItemInputSX }}>
                  <Typography>{securityPlanRevision}</Typography>
                </Grid>
            
                <Grid item xs={GridItemCommentsXS} >
                  <label htmlFor="securityPlanRevisionComments">When it was last exercised & what type?</label>
                  <Typography>{securityPlanRevisionComments}</Typography>
                </Grid> 

                <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Divider sx={{ width: '95%', m:'1rem'}} />
                </Grid>


                {/* Question 17 */}
                {assessmentData?.securityGroupLocal==="Security Group 1" ? (
                  <>
                    <Grid item xs={GridItemQuestionXS}>
                      <Typography sx={{ ml:'0.5rem' }}>17. Is there a Vulnerability Assessment?</Typography>
                    </Grid>
                  <Grid item xs={GridItemInputXS} sx={{ display: 'flex', justifyContent:'center', alignSelf:GridItemInputSX }}>
                    <Typography>{hasVulnerabilityAssessment}</Typography>
                  </Grid>
                  <Grid item xs={GridItemCommentsXS} >
                    <label htmlFor="hasVulnerabilityAssessmentComments">Is it compliant with Chapter 5 of <Link target="_blank" rel="noopener noreferrer"  href="https://www.ferc.gov/sites/default/files/2020-04/security.pdf">FERC's FERC Security Program for Hydropower Projects Revision 3</Link>?</label>
                    <Typography>{hasVulnerabilityAssessmentComments}</Typography>
                  </Grid> 
                  </>
                  ) : (
                    <>
                      <Grid item xs={GridItemQuestionXS}>
                        <Typography sx={{ ml:'0.5rem' }}>17. Is there a Vulnerability Assessment?</Typography>
                      </Grid>
                      <Grid item xs={GridItemInputXS} sx={{ display: 'flex', justifyContent:'center', alignSelf:GridItemInputSX }}>
                        <Typography>Vulnerability Assessments are only applicable for Security Group 1 FERC Projects.</Typography>
                      </Grid>
                    </>
                  )}
                    <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Divider sx={{ width: '95%', m:'1rem'}} />
                    </Grid>

                {/* Question 18 */}
                  <Grid item xs={GridItemQuestionXS}>
                    <Typography sx={{ ml:'0.5rem' }}>18. Is there a Security Assessment?</Typography>
                  </Grid>
                  <Grid item xs={GridItemInputXS} sx={{ display: 'flex', justifyContent:'center', alignSelf:GridItemInputSX }}>
                    <Typography>{hasSecurityAssessment}</Typography>
                  </Grid>
                  <Grid item xs={GridItemCommentsXS} >
                    <label htmlFor="hasSecurityAssessmentComments">Is it compliant with Chapter 6 of <Link target="_blank" rel="noopener noreferrer"  href="https://www.ferc.gov/sites/default/files/2020-04/security.pdf">FERC's FERC Security Program for Hydropower Projects Revision 3</Link>?</label>
                    <Typography>{hasSecurityAssessmentComments}</Typography>
                  </Grid> 
        
                  <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Divider sx={{ width: '95%', m:'1rem'}} />
                  </Grid>


                  {/* Question 19 */}
                  <Grid item xs={GridItemQuestionXS}>
                    <Typography sx={{ ml:'0.5rem' }}>19. Are all actions and plans fully integrated?</Typography>
                  </Grid>
                  <Grid item xs={GridItemInputXS} sx={{ display: 'flex', justifyContent:'center', alignSelf:GridItemInputSX }}>
                    <Typography>{planActionIntegration}</Typography>
                  </Grid>
                  <Grid item xs={GridItemCommentsXS} >
                    <label htmlFor="planActionIntegrationComments">Additional Comments:</label>
                    <Typography>{planActionIntegrationComments}</Typography>
                  </Grid> 
        
                  <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Divider sx={{ width: '95%', m:'1rem'}} />
                  </Grid>


                  {/* Question 20 */}
                  <Grid item xs={GridItemQuestionXS}>
                    <Typography sx={{ ml:'0.5rem' }}>20. Do any security measures conflict with any license requirements?</Typography>
                  </Grid>
                  <Grid item xs={GridItemInputXS} sx={{ display: 'flex', justifyContent:'center', alignSelf:GridItemInputSX }}>
                    <Typography>{conflictingSecurityMeasures}</Typography>
                  </Grid>
                  <Grid item xs={GridItemCommentsXS} >
                    <label htmlFor="conflictingSecurityMeasuresComments">Additional Comments:</label>
                    <Typography>{conflictingSecurityMeasuresComments}</Typography>
                  </Grid> 
                
                  <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Divider sx={{ width: '95%', m:'1rem'}} />
                  </Grid>


                  {/* Question 21 */}
                  <Grid item xs={GridItemQuestionXS}>
                    <Typography sx={{ ml:'0.5rem' }}>21. Is there fuel storage or hazardous material on-site?</Typography>
                  </Grid>
                  <Grid item xs={GridItemInputXS} sx={{ display: 'flex', justifyContent:'center', alignSelf:GridItemInputSX }}>
                    <Typography>{hasHazmat}</Typography>
                  </Grid>
                  <Grid item xs={GridItemCommentsXS} >
                    <label htmlFor="hasHazmatComments">Describe the hazardous material:</label>
                    <Typography>{hasHazmatComments}</Typography>
                  </Grid> 

                  <Grid item xs={GridItemCommentsXS} sx={{ ml:"3rem" }}>
                    <label htmlFor="hazmatSecureAccess">If so, how is it secure?</label>
                    <Typography>{hazmatSecureAccess}</Typography>
                  </Grid> 
      
                  <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Divider sx={{ width: '95%', m:'1rem'}} />
                  </Grid>


                  {/* Question 22 */}
                  <Grid item xs={GridItemQuestionXS}>
                    <Typography sx={{ ml:'0.5rem' }}>22. Are critical drawings/plans/records (CDP&R) secured from unauthorized access?</Typography>
                  </Grid>
                  <Grid item xs={GridItemInputXS} sx={{ display: 'flex', justifyContent:'center', alignSelf:GridItemInputSX }}>
                    <Typography>{cdprSecured}</Typography>
                  </Grid>
                  <Grid item xs={GridItemCommentsXS} >
                    <label htmlFor="cdprSecuredComments">Additional Comments:</label>
                    <Typography>{cdprSecuredComments}</Typography>
                  </Grid> 
                
                  <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Divider sx={{ width: '95%', m:'1rem'}} />
                  </Grid>






              </Grid>


              {/* ************Project Security Summary Information Form 2****************/}
              <Grid sx={{ display:'flex', backgroundImage: "none", backgroundColor: '#fff', borderRadius: "0.55rem", mr:'0.55rem', p:'4px' }} container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant='h2'>PROJECT SECURITY SUMMARY INFORMATION FORM 2</Typography>
                  <Divider />
                </Grid>

                {/* Question A */}
                <Grid item xs={GridItemQuestionXS}>
                  <Typography sx={{ ml:'0.5rem' }}>A. Number of security/surveillance incidents in the past year?</Typography>
                </Grid>
                <Grid item xs={GridItemInputXS} sx={{ display: 'flex', justifyContent:'center', alignSelf:'start' }}>
                  <Typography>{pastYearSecurityIncidents}</Typography>
                </Grid>
                <Grid item xs={GridItemCommentsXS} >
                  <label htmlFor="pastYearSecurityIncidentsComments">Describe Incidents (include if it was reported to FERC): </label>
                  <Typography>{pastYearSecurityIncidentsComments}</Typography>
                </Grid> 
              
                <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Divider sx={{ width: '95%', m:'1rem'}} />
                </Grid>


                {/* Question B */}
                <Grid item xs={GridItemQuestionXS}>
                  <Typography sx={{ ml:'0.5rem' }}>B. Owner expressed specific security concerns or questions.</Typography>
                </Grid>
                <Grid item xs={GridItemInputXS} sx={{ display: 'flex', justifyContent:'center', alignSelf:GridItemInputSX }}>
                  <Typography>{specificSecurityConcerns}</Typography>
                </Grid>
                <Grid item xs={GridItemCommentsXS} >
                  <label htmlFor="specificSecurityConcernsComments">Additional Comments:</label>
                  <Typography>{specificSecurityConcernsComments}</Typography>
                </Grid> 
              
                <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Divider sx={{ width: '95%', m:'1rem'}} />
                </Grid>

                {/* Question C */}
                <Grid item xs={GridItemQuestionXS}>
                  <Typography sx={{ ml:'0.5rem' }}>C. Number of data requests or site visits by DHS Protective Security Advisors or other assessment groups?</Typography>
                </Grid>
                <Grid item xs={GridItemInputXS} sx={{ display: 'flex', justifyContent:'center', alignSelf:'start' }}>
                  <Typography>{otherAssessments}</Typography>
                </Grid>
                <Grid item xs={GridItemCommentsXS} >
                  <label htmlFor="otherAssessmentsComments">Describe Incidents (include if it was reported to FERC): </label>
                  <Typography>{otherAssessmentsComments}</Typography>
                </Grid> 
              
                <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Divider sx={{ width: '95%', m:'1rem'}} />
                </Grid>

                {/* Question D.1 */}
                <Grid item xs={GridItemQuestionXS}>
                  <Typography sx={{ ml:'0.5rem' }}>D.1. Have changes been made to the security program since your last inspection?</Typography>
                </Grid>
                <Grid item xs={GridItemInputXS} sx={{ display: 'flex', justifyContent:'center', alignSelf:GridItemInputSX }}>
                  <Typography>{changesSinceLastInspection}</Typography>
                </Grid>
                <Grid item xs={GridItemCommentsXS} >
                  <label htmlFor="changesSinceLastInspectionComments">If 'No', do previous studies show your posture was adequate? Explain.</label>
                  <Typography>{changesSinceLastInspectionComments}</Typography>
                </Grid> 
              
                <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Divider sx={{ width: '95%', m:'1rem'}} />
                </Grid>


                {/* Question D.2 */}
                <Grid item xs={GridItemQuestionXS}>
                  <Typography sx={{ ml:'0.5rem' }}>D.2. Were changes made to physical site security?</Typography>
                </Grid>
                <Grid item xs={GridItemInputXS} sx={{ display: 'flex', justifyContent:'center', alignSelf:GridItemInputSX }}>
                  <Typography>{physicalSiteSecurityChanges}</Typography>
                </Grid>
                <Grid item xs={GridItemCommentsXS} >
                  <label htmlFor="physicalSiteSecurityChangesComments">Describe Changes:</label>
                  <Typography>{physicalSiteSecurityChangesComments}</Typography>
                </Grid> 
              
                <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Divider sx={{ width: '95%', m:'1rem'}} />
                </Grid>


                {/* Question D.3 */}
                <Grid item xs={GridItemQuestionXS}>
                  <Typography sx={{ ml:'0.5rem' }}>D.3. Were changes made to procedural operations (incl. threat level increase additions, employee actions, etc.)?</Typography>
                </Grid>
                <Grid item xs={GridItemInputXS} sx={{ display: 'flex', justifyContent:'center', alignSelf:GridItemInputSX }}>
                  <Typography>{proceduralChanges}</Typography>
                </Grid>
                <Grid item xs={GridItemCommentsXS} >
                  <label htmlFor="proceduralChangesComments">Describe Changes:</label>
                  <Typography>{proceduralChangesComments}</Typography>
                </Grid> 
              
                <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Divider sx={{ width: '95%', m:'1rem'}} />
                </Grid>


                {/* Question D.4 */}
                <Grid item xs={GridItemQuestionXS}>
                  <Typography sx={{ ml:'0.5rem' }}>D.4. Were changes made to cyber/SCADA operations?</Typography>
                </Grid>
                <Grid item xs={GridItemInputXS} sx={{ display: 'flex', justifyContent:'center', alignSelf:GridItemInputSX }}>
                  <Typography>{cybersecurityChanges}</Typography>
                </Grid>
                <Grid item xs={GridItemCommentsXS} >
                  <label htmlFor="cybersecurityChangesComments">Describe Changes:</label>
                  <Typography>{cybersecurityChangesComments}</Typography>
                </Grid> 
              
                <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Divider sx={{ width: '95%', m:'1rem'}} />
                </Grid>


                {/* Question D.5 */}
                <Grid item xs={GridItemQuestionXS}>
                  <Typography sx={{ ml:'0.5rem' }}>D.5. Describe how the overall security risks were reduced because of the modifications described in D1 through D4.</Typography>
                </Grid>
                <Grid item xs={GridItemInputXS} sx={{ display: 'flex', justifyContent:'center', alignSelf:GridItemInputSX }}>
                  
                </Grid>
                <Grid item xs={GridItemCommentsXS} >
                  <label htmlFor="overallRiskReductionComments">Describe Changes:</label>
                  <Typography>{overallRiskReductionComments}</Typography>
                </Grid> 
              
                <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Divider sx={{ width: '95%', m:'1rem'}} />
                </Grid>



              </Grid>





            </Box>
          </Grid>

          {/**Corrective Actions */}
          <Grid item xs={12} sx={{ mt:'2rem' }}>
            {/**Corrective Actions */}
            <Typography variant='h2'>Corrective Actions</Typography>
            <Divider />
          <Box
            sx={{
              height: 400,
              width: '99%',
              fontSize: '2em',
              "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: theme.palette.secondary.alt,
                  color: theme.palette.secondary[100],
                  borderBottom: "solid 2px black",
                  borderTop: "solid 2px black",
              },
              "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: theme.palette.primary.light,
              },
              "& .MuiDataGrid-footerContainer": {
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.primary[100],
                  borderTop: "none"
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                  color: `${theme.palette.secondary[200]} !important`,
              },
              "& .MuiDataGrid-main": {
                fontSize: "1.5em"
              }
            }}
            >
            <DataGrid
              rows={correctiveActions || []} 
              getRowId={(row) => row._id}
              columns={columns}
              editMode="row"
              // rowModesModel={rowModesModel}
              // onRowModesModelChange={handleRowModesModelChange}
              // onRowEditStop={handleRowEditStop}
              // processRowUpdate={processRowUpdate}
              // slots={{
              //   toolbar: DataGridCustomToolbar,
              // }}
              // slotProps={{
              //   toolbar: { setRows, setRowModesModel },
              // }}
            />
          </Box>
          </Grid>


        </Paper>
      </Grid>
    </Box>
  )
}

export default VulnAssessmentReport