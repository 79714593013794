import React, { useState, useEffect } from 'react';
import { Box, Grid, Paper, useTheme } from "@mui/material";
import { useParams } from 'react-router-dom';
import Header from '../../../components/Header';
import FERCRiskEquationLong from './images/FERCRiskEquationLong.png';
import { styled } from '@mui/material/styles';
import MuiInput from '@mui/material/Input';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import ThreatProfileBuilder from './ThreatProfileBuilder';



const ThreatProfiles = () => {

    const [userAddedThreatTemp, setUserAddedThreatTemp] = useState();
    const [userAddedVulnTemp, setUserAddedVulnTemp] = useState();
    const [userAddedLikelihoodTemp, setUserAddedLikelihoodTemp] = useState();
    const [threatValue, setThreatValue] = React.useState(0);
    const [vulnValue, setVulnValue] = React.useState(0);
    const [likelihoodValue, setLikelihoodValue] = React.useState(0);
    const Input = styled(MuiInput)` width: 42px;`;
    const [allCriticalAssetData, setAllCriticalAssetData] = useState();
    const { assessmentID } = useParams();
    const axiosPrivate = useAxiosPrivate();
    const newThreatProfileID = 1;
    const threatProfiles = [];
    const theme = useTheme();


        // Get Critical Assets Function.  This should pull cyberAssets for the current assessment. 
    const getCriticalAssets = async () => {
        try {
        const response = await axiosPrivate.get(`/criticalassets/criticalassets/${assessmentID}`, {
            withCredentials: true
        });
        const data = await response.data;
        setAllCriticalAssetData(data);
        } catch (err) {
        // alert("Login Expired.");
        console.error(err);
        // navigate('/login', { state: { from: location }, replace: true });
        }
    };

  
    useEffect(() => {
        getCriticalAssets();
      }, []);  // eslint-disable-line react-hooks/exhaustive-deps
      if (!allCriticalAssetData) {
        return null;
      }


  return (
    <Box>
        <Grid container spacing={2}>
            {/* <Grid item xs={5}>
                <Header title='Threat Profiles' subtitles='Add Threat Profiles for each Critical Asset.' />
            </Grid>
            <Grid item xs={5}>
                <Box
                sx={{ pt:'0.5rem', minWidth:'350px' }}
                component="img"
                width="80%"
                alt="FERC Risk equation from FERC's HydroSecurity Program Revision 3a."
                src={FERCRiskEquationLong}
                />
            </Grid> */}
            {allCriticalAssetData.map(
                ({
                    _id,
                    assetName,
                    assetDescription,
                }) => (
                    <Grid item xs={12}>
                        <Paper sx={{ border:'solid 1px', backgroundImage: "none", backgroundColor: theme.palette.background.alt, borderRadius: "0.55rem", mr:'0.55rem', mt:'1rem', p:'4px', minHeight:'250px'}} >
                            <ThreatProfileBuilder 
                                key={_id}
                                _id={_id}
                                assetName={assetName}
                                assetDescription={assetDescription}
                            />
                        </Paper>

                    </Grid>
                )
            )}
        </Grid>
    </Box>
  )
}

export default ThreatProfiles